import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";
import {
  __publicField
} from "./chunk-G2CEMTG6.js";

// src/pages/Members/MemberViewModel/MemberDetailViewModel.ts
import { ORGANISATION_MEMBER_FIELD } from "aesirx-lib";
import { makeAutoObservable, runInAction } from "mobx";
var MemberDetailViewModel = class {
  constructor(memberStore) {
    __publicField(this, "memberStore");
    __publicField(this, "formStatus", PAGE_STATUS.READY);
    __publicField(this, "memberDetailViewModel", { formPropsData: [{}] });
    __publicField(this, "aliasChange", "");
    __publicField(this, "roleList", []);
    __publicField(this, "successResponse", {
      state: true,
      content_id: "",
      filters: {}
    });
    __publicField(this, "setForm", (memberDetailViewModel) => {
      this.memberDetailViewModel = memberDetailViewModel;
    });
    __publicField(this, "initializeData", async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.memberStore.getDetail(
        this.memberDetailViewModel.formPropsData[ORGANISATION_MEMBER_FIELD.ID]
      );
      runInAction(() => {
        if (!data?.error) {
          this.onGetMemberSuccessHandler(data?.response);
        } else {
          this.onErrorHandler(data?.response);
        }
      });
    });
    __publicField(this, "getRoleList", async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.memberStore.getRoleList(this.successResponse.filters);
      runInAction(() => {
        if (!data?.error) {
          this.onGetRoleListSuccessHandler(data?.response);
        } else {
          this.onErrorHandler(data?.response);
        }
      });
    });
    __publicField(this, "create", async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.memberStore.create(this.memberDetailViewModel.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "Created successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
      return data;
    });
    __publicField(this, "update", async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.memberStore.update(this.memberDetailViewModel.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "Updated successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
      return data;
    });
    __publicField(this, "onErrorHandler", (error) => {
      error._messages[0]?.message ? notify(error._messages[0]?.message, "error") : error.message && notify(error.message, "error");
      this.successResponse.state = false;
      this.successResponse.content_id = error.result;
      this.formStatus = PAGE_STATUS.READY;
    });
    __publicField(this, "onSuccessHandler", (result, message) => {
      if (result && message) {
        notify(message, "success");
      }
      this.formStatus = PAGE_STATUS.READY;
    });
    __publicField(this, "onGetMemberSuccessHandler", (result) => {
      if (result && result[ORGANISATION_MEMBER_FIELD.ID]) {
        this.memberDetailViewModel.formPropsData = {
          ...this.memberDetailViewModel.formPropsData,
          ...Object.keys(ORGANISATION_MEMBER_FIELD).map((index) => {
            return {
              [ORGANISATION_MEMBER_FIELD[index]]: result[ORGANISATION_MEMBER_FIELD[index]]
            };
          }).reduce((prev, cur) => ({ ...prev, ...cur }))
        };
        this.formStatus = PAGE_STATUS.READY;
      }
    });
    __publicField(this, "onGetRoleListSuccessHandler", (result) => {
      if (result) {
        this.roleList = result;
      }
      this.formStatus = PAGE_STATUS.READY;
    });
    __publicField(this, "handleFormPropsData", (key, value) => {
      if (key && value !== null) {
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          Object.assign(this.memberDetailViewModel.formPropsData[key], value);
        } else {
          this.memberDetailViewModel.formPropsData[key] = value;
        }
      }
    });
    __publicField(this, "handleAliasChange", (value) => {
      this.aliasChange = value;
    });
    makeAutoObservable(this);
    this.memberStore = memberStore;
  }
};
var MemberDetailViewModel_default = MemberDetailViewModel;

export {
  MemberDetailViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
