// src/store/BiStore/BiViewModelContextProvider.js
import React from "react";
var BiViewModelContext = React.createContext();
var BiStoreProvider = ({ children, viewModel }) => {
  return /* @__PURE__ */ React.createElement(BiViewModelContext.Provider, { value: viewModel }, children);
};
var useBiViewModel = () => React.useContext(BiViewModelContext);
var withBiViewModel = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, viewModel: useBiViewModel() });
};

export {
  BiViewModelContext,
  BiStoreProvider,
  useBiViewModel,
  withBiViewModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
