import {
  DashboardModel
} from "./chunk-IHRXW2CZ.js";
import {
  PimDashboardRoute_default
} from "./chunk-DWNUXIR2.js";

// src/Pim/PimDashboard/PimDashboard.ts
import axios from "axios";
var AesirxPimDashboardApiService = class {
  constructor() {
    this.route = null;
    this.getStatisticalData = async (filter) => {
      try {
        const data = await this.route.getStatisticalData(filter);
        let results = null;
        if (data?.result) {
          results = new DashboardModel(data.result);
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new PimDashboardRoute_default();
  }
};

export {
  AesirxPimDashboardApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
