import {
  RoleViewModelContext
} from "./chunk-H5T4XHF5.js";
import {
  renderingGroupFieldHandler
} from "./chunk-CCC3TLEH.js";
import {
  FORM_FIELD_TYPE
} from "./chunk-IIJXEE2D.js";
import {
  __publicField
} from "./chunk-G2CEMTG6.js";

// src/pages/Roles/RoleEdit/Component/RoleInformation.tsx
import { ORGANISATION_ROLE_FIELD } from "aesirx-lib";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
var _a;
var RoleInformation = observer(
  (_a = class extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "viewModel", null);
    }
    render() {
      this.viewModel = this.context.model.roleDetailViewModel;
      const { t, validator } = this.props;
      const generateFormSetting = [
        {
          fields: [
            {
              label: t("txt_description"),
              key: ORGANISATION_ROLE_FIELD.DESCRIPTION,
              type: FORM_FIELD_TYPE.EDITOR,
              getValueSelected: this.viewModel.roleDetailViewModel.formPropsData[ORGANISATION_ROLE_FIELD.DESCRIPTION] ?? null,
              handleChange: (data) => {
                this.viewModel.handleFormPropsData(ORGANISATION_ROLE_FIELD.DESCRIPTION, data);
              },
              className: "col-lg-12"
            }
          ]
        }
      ];
      return /* @__PURE__ */ React.createElement("div", { className: "p-24 bg-white rounded-1 shadow-sm h-100 mt-24" }, Object.keys(generateFormSetting).map((groupIndex) => {
        return [...Array(generateFormSetting[groupIndex])].map((group) => {
          return renderingGroupFieldHandler(group, validator);
        });
      }).reduce((arr, el) => {
        return arr.concat(el);
      }, []));
    }
  }, __publicField(_a, "contextType", RoleViewModelContext), _a)
);
var RoleInformation_default = withTranslation()(RoleInformation);

export {
  RoleInformation_default
};
