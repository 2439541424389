import {
  PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY
} from "./chunk-V7IZ3LDX.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Pim/PimShippingZone/Model.ts
var ShippingZoneModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new ShippingZoneItemModel(element);
      });
    }
  }
};
var ShippingZoneItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.country = null;
    this.country_id = null;
    this.state = null;
    this.state_id = null;
    this.city = null;
    this.city_id = null;
    this.zip_start = null;
    this.zip_end = null;
    this.published = null;
    this.created_user_name = null;
    this.modified_user_name = null;
    this.created_time = null;
    this.publish_up = null;
    this.modified_time = null;
    this.custom_fields = null;
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ID]: this.id,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.COUNTRY]: this.country,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.COUNTRY_ID]: this.country_id,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.STATE]: this.state,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.STATE_ID]: this.state_id,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CITY]: this.city,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CITY_ID]: this.city_id,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ZIP_START]: this.zip_start,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ZIP_END]: this.zip_end,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.TITLE]: this.title,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.PUBLISHED]: this.published,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CREATED_USER_NAME]: this.created_user_name,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.MODIFIED_USER_NAME]: this.modified_user_name,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CREATED_TIME]: this.created_time,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.PUBLISH_UP]: this.publish_up,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.MODIFIED_TIME]: this.modified_time,
        [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]: this.custom_fields
      };
    };
    if (entity) {
      this.id = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ID] ?? "";
      this.country = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.COUNTRY] ?? "";
      this.country_id = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.COUNTRY_ID] ?? "";
      this.state = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.STATE] ?? "";
      this.state_id = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.STATE_ID] ?? "";
      this.city = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CITY] ?? "";
      this.city_id = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CITY_ID] ?? "";
      this.zip_start = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ZIP_START] ?? "";
      this.zip_end = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ZIP_END] ?? "";
      this.published = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.PUBLISHED] ?? "";
      this.created_user_name = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CREATED_USER_NAME] ?? "";
      this.modified_user_name = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.MODIFIED_USER_NAME] ?? "";
      this.created_time = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.publish_up = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.PUBLISH_UP] ?? "";
      this.modified_time = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.MODIFIED_TIME] ?? "";
      this.custom_fields = entity[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] ?? "";
    }
  }
};
ShippingZoneItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = {};
  const excluded = [
    PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.ID,
    PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS
  ];
  Object.keys(PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]) && data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]]) {
      formData[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]] = data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] && Object.keys(data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
    formData[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] = {};
    Object.keys(data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).forEach(function(key) {
      formData[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key] = data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key];
    });
  }
  return { items: [formData] };
};
ShippingZoneItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS];
  Object.keys(PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]) && data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]]) {
      formData[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]] = data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] && Object.keys(data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
    formData[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] = {};
    Object.keys(data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).forEach(function(key) {
      formData[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key] = data[PIM_SHIPPING_ZONE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key];
    });
  }
  return { items: [formData] };
};

export {
  ShippingZoneModel,
  ShippingZoneItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
