import {
  integrationRoutes
} from "./chunk-62HKQH4D.js";
import "./chunk-2XIDCQKH.js";

// src/routes/menu.js
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
var mainMenu = [
  {
    name: "home",
    text: "txt_menu_calendar",
    link: "/",
    icons: "/assets/images/icon_calendar.svg",
    icons_color: "/assets/images/icon_calendar_white.svg"
  },
  {
    name: "projects",
    text: "txt_menu_projects",
    link: "/projects",
    icons: "/assets/images/icon_projects.svg",
    icons_color: "/assets/images/icon_projects_white.svg"
  },
  {
    name: "campaigns",
    text: "txt_menu_campaigns",
    link: "/campaigns",
    icons: "/assets/images/icon_campaigns.svg",
    icons_color: "/assets/images/icon_campaigns_white.svg"
  },
  {
    name: "content",
    text: "txt_menu_posts",
    link: "/content",
    icons: "/assets/images/icon_content.svg",
    icons_color: "/assets/images/icon_content_white.svg"
    // submenu: contentSubMenu,
  },
  {
    name: "channels",
    text: "txt_menu_channels",
    link: "/channels",
    icons: "/assets/images/icon_channles.svg",
    icons_color: "/assets/images/icon_channles_white.svg"
  },
  {
    name: "digital",
    text: "txt_menu_digital_assets",
    link: "/digital-assets",
    icons: "/assets/images/icon_digital_assets.svg",
    icons_color: "/assets/images/icon_digital_assets_white.svg"
  }
];
var integrationMenu = () => mainMenu.filter((item) => item.name !== "digital").map((item) => {
  item.link = "/dma" + item.link;
  return item;
});

// src/translations/dk/common.json
var common_default = {
  txt_welcome_to: "Velkommen",
  txt_sign_in_to_see_latest_updates: "Log ind for at se seneste opdateringer.",
  txt_or_continue_with: "Eller forts\xE6t med",
  txt_email: "Email",
  txt_password: "Password",
  txt_remember_me: "Husk mig",
  txt_forgot_password: "Glemt password?",
  txt_sign_in: "Log ind",
  txt_sign_in_with_sso: "Log ind med AesirX SSO",
  txt_sign_up: "Tilmeld",
  txt_do_not_have_an_account: "Har du ingen konto? Opret en gratis konto",
  txt_welcome_to_our_aesirx: "Velkommen til vores AesirX - DMA.",
  txt_sign_up_to_getting_started: "Tilmeld dig for at komme igang.",
  txt_or_register_with: "Eller registrer med",
  txt_username: "Brugernavn",
  txt_what_is_the_main_thing_you_want_to_manage: "Hvad er det vigtigste, du vil have styr p\xE5?",
  txt_you_agree_to_our: "Med registering, accepterer jeg ",
  txt_terms_of_service: "service betingelser",
  txt_privacy_policy: "privatlivs politik",
  txt_and: "og",
  txt_already_have_an_account: "Har du allerede en konto? Log in",
  txt_email_already_exists: "Email eksisterer allerede",
  txt_already_exists: "Eksisterer allerede",
  txt_log_in: "Log in",
  txt_loading: "Loading",
  txt_forgot_your_password: "Har du glemt dit password?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "Vi sender en mail med instruktioner p\xE5 hvordan du resetter dit password",
  txt_reset_password: "Nulstil Password",
  txt_verify_your_email_address: "Verificer din mail addresse!",
  txt_a_verification_email_has_been_sent_to_your_email: "En verifikations mail er blevet sendt til din mail",
  txt_please_check_your_email: "Venligst check din mail og verificer kontoen ved at trykke p\xE5 knappen {{characters}} Bekr\xE6ft din  mail{{characters}} for at f\xE6rdigg\xF8re din konto registrering.",
  txt_if_you_do_not_receive_the_email: "Modtog du ikke vores mail?, s\xE5 klik p\xE5 knappen herunder for at f\xE5 gensendt verifikations mailen.",
  txt_resend_email: "Gensend mailen",
  txt_search_for_something: "S\xF8g efter noget ...",
  txt_create_project: "Opret projekt",
  txt_recent: "For nylig",
  txt_view_all_projects: "Se alle projekter",
  txt_search_projects: "S\xF8g projekter ...",
  txt_create_a_new_project: "Opret et nyt projekt",
  txt_all_projects: "Alle projekter",
  txt_help_center: "Hj\xE6lpe center",
  txt_sign_out: "Log ud",
  txt_notifications: "Notifikationer",
  txt_mark_all_as_read: "Marker alle som l\xE6st",
  txt_internal: "Interne",
  txt_client: "Kunder",
  txt_task_complete: "Opgave f\xE6rdig",
  txt_more: "Mere",
  txt_projects: "Projekter",
  txt_assigned_to_me: "Tildelt til mig",
  txt_campaigns: "Kampagner",
  txt_marketing_automation_made_easy: "Marketing Automation, gjort nemt!",
  txt_we_have_created_a_step_by_step_process: "Vi har lavet en trin-for-trin gennemgang, der vil guide dig til, hvordan du kommer i gang.",
  txt_social: "Social",
  txt_cms: "CMS",
  txt_mail: "Mail",
  txt_title_calendar: "Planl\xE6g",
  txt_title_projects: "Projekter",
  txt_title_campaigns: "List kampagner",
  txt_title_content: "Liste Projekt",
  txt_title_channels: "Tilslut en kanal",
  txt_title_digital_assets: "Digitale aktiver",
  txt_title_new_calendar: "Ny tidsplan",
  txt_menu_calendar: "Kalender",
  txt_menu_projects: "Projekter",
  txt_menu_campaigns: "Kampagner",
  txt_menu_content: "Indhold",
  txt_menu_channels: "Kanaler",
  txt_menu_digital_assets: "Digitale aktiver",
  txt_today: "Idag",
  txt_general_information: "Generel Information",
  txt_projectpage_password: "Password",
  txt_profile_picture: "Profil foto",
  txt_list_post: "List Posts",
  txt_list_campaigns: "List kampagner",
  txt_connect_a_channel: "Tilslut en kanal",
  txt_back_to_dashboard: "Tilbage til Dashboard",
  txt_menu_profile: "Profil",
  txt_Username: "Brugernavn",
  txt_Email: "mail",
  txt_Status: "Status",
  txt_Birthday: "F\xF8dselsdag",
  txt_Phone: "Telefon",
  txt_Address_1: "Addresse 1",
  txt_Address_2: "Addresse 2",
  txt_State: "Stat",
  txt_Country: "Land",
  txt_Zipcode: "Postnr",
  txt_Fullname: "Fuldt navn",
  txt_City: "By",
  txt_current_password: "Nuv\xE6rende Password",
  txt_confirm_password: "Bekrl\xE6ft nyt Password",
  txt_new_password: "Nyt Password",
  txt_update: "Opdater",
  delete: "Slet",
  choose_an_action: "V\xE6lg en funktion",
  txt_create_post: "Opret enpost",
  txt_create_campaigns: "Opret en kampagne",
  create_a_new_persona: "Opret en ny persona",
  txt_coming_soon: "Kommer snart",
  txt_filter: "Filter",
  txt_clear_all: "Slet alt",
  txt_select_all: "V\xE6lg alt",
  txt_deselect_all: "Frav\xE6lg alle",
  txt_columns: "Kolonner",
  txt_list: "Liste",
  txt_thumb: "Tommelfinger",
  search_your_project: "S\xF8g dit projekt",
  search_your_post: "S\xF8g dit enpost",
  search_your_campaign: "S\xF8g din kampagne",
  search_your_personas: "S\xF8g dine personas",
  txt_project_name: "Projekt Navn",
  txt_lead: "Lead",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "Vis mere",
  start_date: "Start Dato",
  end_date: "Slut Dato",
  created_date: "Oprettet Dato",
  txt_short_description: "Kort beskrivelse",
  txt_short_description_about_project: "Kort beskrivelse af projekt",
  txt_project_logo: "Projekt Logo",
  txt_edit_project: "\xC6ndre projekt",
  txt_save_project: "Gem projekt",
  txt_budget: "Budget",
  txt_campaign_name: "Kampagne navn",
  create_campaign: "Opret kampagne",
  edit_campaign: "\xC6ndre kampagne",
  save_campaign: "Gem kampagne",
  txt_status: "Status",
  create_your_1st_campaigns: "Opret din f\xF8rste kampagne",
  create_your_1st_project: "Opret dit f\xF8rste projekt",
  create_your_1st_persona: "Opret din f\xF8rste persona",
  txt_name_personas: "Navn",
  updated_date: "Opdateret Dato",
  connected_channels: "Tilsluttede kanaler",
  txt_title: "Titel",
  txt_channels: "Kanaler",
  txt_date: "Dato",
  txt_edit: "Redigere",
  txt_general: "Generel",
  txt_choose_the_project: "V\xE6lg projektet",
  txt_choose_the_campaign: "V\xE6lg kampagnen",
  txt_headline: "Overskrift",
  txt_add_channels: "Tilf\xF8j kanaler",
  txt_save: "Gemme",
  txt_connected_channels: "Forbundne kanaler",
  content_description: "Indholdsbeskrivelse",
  txt_advance_mode: "Avanceret tilstand",
  txt_back: "Tilbage",
  txt_next: "N\xE6ste",
  txt_connecting: "Tilslutning",
  txt_connect: "Forbinde",
  txt_enable: "Aktiver",
  txt_remove: "Fjerne",
  txt_type: "Type",
  txt_action: "Handling",
  txt_profile: "Profil",
  txt_days: " dage",
  txt_apply: "Anvende",
  txt_0_days: "0 dage",
  txt_week: "uge",
  txt_month: "m\xE5ned",
  txt_agenda: "dagsorden",
  txt_nopost_agenda: "Der er ingen begivenheder i dette interval.",
  txt_video: "Video",
  txt_digital_asset_management: "Digital Asset Management",
  txt_digital_assets: "Digitalt aktiv",
  txt_publish: "Offentligg\xF8re",
  "txt_when_to_publish_this?": "Hvorn\xE5r skal dette offentligg\xF8res?",
  txt_save_as_draft: "Gem som kladde",
  txt_choose_file: "V\xE6lg fil",
  "txt_select...": "V\xE6lg...",
  txt_project: "Projekt",
  txt_post_now: "Send nu",
  txt_schedule: "Tidsplan",
  "txt_publish_date/time": "Udgiv dato/tid",
  txt_update_successfully: "Opdatering lykkedes",
  txt_update_unsuccessfully: "Opdatering mislykkedes",
  txt_please_upgrade_account: "Opgrader venligst kontoen",
  txt_please_connect_a_channel: "Tilslut venligst en kanal",
  txt_the_video_field_is_required: "Videofeltet er p\xE5kr\xE6vet",
  txt_logo_picture: "Logo",
  txt_canva_api_key_warning: "Erstat venligst REACT_APP_CANVA_API_KEY eller Canva-knappen virker kun p\xE5 localhost",
  txt_running: "L\xF8b",
  txt_stop: "Hold op",
  txt_sign_in_to_getting_started: "Log ind for at komme i gang.",
  txt_warning: "Advarsel",
  txt_successful: "Vellykket",
  txt_error: "Fejl",
  txt_title_no_matching_results: "Ingen matchende resultater",
  txt_text_no_matching_results: "Kan ikke finde noget projekt med dette s\xF8geord. Pr\xF8v venligst et andet s\xF8geord.",
  "txt_ maintenance": "vedligeholdelse",
  txt_all_dates: " Alle datoer",
  txt_api_error: "Der opstod en fejl. Pr\xF8v venligst igen senere.",
  txt_intro_chatgpt: "Hej! Hvordan kan jeg hj\xE6lpe dig i dag?",
  txt_open_api_key_warning: "Indtast venligst API-n\xF8gler fra https://platform.openai.com/account/api-keys",
  txt_connect_channel: "Tilslut kanal",
  txt_create_content: "Opret indhold",
  txt_delete_folder: "Slet mappe",
  txt_are_you_sure: "Er du sikker?",
  txt_delete_assets_popup_desc: "Er du sikker p\xE5, at du vil slette dette aktiv?",
  txt_Cancel: "Fortryd",
  txt_yes_delete: "Ja, slet",
  txt_description_limmit: " Beskrivelsen overskrider de tilladte tegn ",
  txt_headline_limmit: " Overskriften overskrider de tilladte tegn ",
  txt_the_media_field_is_required: " Mediefeltet er p\xE5kr\xE6vet ",
  txt_the_image_field_is_required: " Billedfeltet er p\xE5kr\xE6vet ",
  txt_create_new_post: "Opret nyt indl\xE6g",
  txt_group_type: "Gruppetype",
  txt_content: "Indhold",
  txt_disconnect: "Afbryd forbindelsen",
  txt_failed: "Mislykkedes",
  txt_bulk_disconnect: "Massefrakobling"
};

// src/translations/en/common.json
var common_default2 = {
  txt_welcome_to: "Welcome to ",
  txt_sign_in_to_see_latest_updates: "Sign In to see latest updates.",
  txt_or_continue_with: "Or Continue with",
  txt_email: "Email",
  txt_password: "Password",
  txt_remember_me: "Remember me",
  txt_forgot_password: "Forgot password?",
  txt_sign_in: "Sign In",
  txt_sign_in_with_sso: "Sign in with AesirX SSO",
  txt_sign_up: "Sign Up",
  txt_do_not_have_an_account: "Don't have an account? Create Free Account",
  txt_welcome_to_our_aesirx: "Welcome to our AesirX - DMA.",
  txt_sign_up_to_getting_started: "Sign Up to getting started.",
  txt_or_register_with: "Or register with",
  txt_username: "Username",
  txt_what_is_the_main_thing_you_want_to_manage: "What is the main thing you want to manage?",
  txt_you_agree_to_our: "By registering, I accept the",
  txt_terms_of_service: "terms of service",
  txt_privacy_policy: "privacy policy",
  txt_and: "and",
  txt_already_have_an_account: "Already have an account? Log in",
  txt_email_already_exists: "Email already exists",
  txt_already_exists: "Already exists",
  txt_log_in: "Log in",
  txt_loading: "Loading",
  txt_forgot_your_password: "Forgot your password?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "We'll email you instructions on how to reset your password",
  txt_reset_password: "Reset Password",
  txt_verify_your_email_address: "Verify your email address!",
  txt_a_verification_email_has_been_sent_to_your_email: "A verification email has been sent to your email",
  txt_please_check_your_email: "Please check your email and verify the account by clicking the button {{characters}}Confirm your email{{characters}} to complete your account registration.",
  txt_if_you_do_not_receive_the_email: "If you do not receive the email, click the button below to resend the verification email.",
  txt_resend_email: "Resend email",
  txt_search_for_something: "Search for something ...",
  txt_recent: "Recent",
  txt_view_all_projects: "View all projects",
  txt_search_projects: "Search projects ...",
  txt_create_a_new_project: "Create a new project",
  txt_all_projects: "All projects",
  txt_help_center: "help center",
  txt_sign_out: "Sign Out",
  txt_notifications: "Notifications",
  txt_mark_all_as_read: "Mark all as read",
  txt_internal: "Internal",
  txt_client: "Client",
  txt_task_complete: "Task Complete",
  txt_more: "More",
  txt_projects: "Projects",
  txt_assigned_to_me: "Assigned to Me",
  txt_campaigns: "Campaigns",
  txt_marketing_automation_made_easy: "Marketing Automation, made easy!",
  txt_we_have_created_a_step_by_step_process: "We have created a step-by-step process that will guide you on how to get started.",
  txt_social: "Social Media",
  txt_cms: "CMS",
  txt_mail: "Mail",
  txt_title_calendar: "Schedule",
  txt_title_projects: "Projects",
  txt_title_campaigns: "List Campaigns",
  txt_title_content: "List Project",
  txt_title_channels: "Connect a Channel",
  txt_title_digital_assets: "Digital Assets",
  txt_title_new_calendar: "New Schedule",
  txt_menu_calendar: "Calendar",
  txt_menu_projects: "Projects",
  txt_menu_campaigns: "Campaigns",
  txt_menu_content: "Posts",
  txt_menu_channels: "Channels",
  txt_menu_digital_assets: "Digital Assets",
  txt_today: "Today",
  txt_general_information: "General Information",
  txt_projectpage_password: "Password",
  txt_profile_picture: "Profile picture",
  txt_list_post: "List Posts",
  txt_list_campaigns: "List Campaigns",
  txt_connect_a_channel: "Connect a Channel",
  txt_back_to_dashboard: "Back to Dashboard",
  txt_menu_profile: "Profile",
  txt_Username: "Username",
  txt_Email: "Email",
  txt_Status: "Status",
  txt_Birthday: "Birthday",
  txt_Phone: "Phone",
  txt_Address_1: "Address 1",
  txt_Address_2: "Address 2",
  txt_State: "State",
  txt_Country: "Country",
  txt_Zipcode: "Zipcode",
  txt_Fullname: "Fullname",
  txt_City: "City",
  txt_current_password: "Current Password",
  txt_confirm_password: "Confirm New Password",
  txt_new_password: "New Password",
  txt_update: "Update",
  delete: "Delete",
  choose_an_action: "Choose an action",
  txt_create_project: "Create Project",
  txt_create_post: "Create post",
  txt_create_campaigns: "Create Campaigns",
  create_a_new_persona: "Create a new persona",
  txt_coming_soon: "Coming soon",
  txt_filter: "Filter",
  txt_clear_all: "Clear all",
  txt_select_all: "Select all",
  txt_deselect_all: "Deselect all",
  txt_columns: "Columns",
  txt_list: "List",
  txt_thumb: "Thumb",
  search_your_project: "Search your project",
  search_your_post: "Search your post",
  search_your_campaign: "Search your campaign",
  search_your_personas: "Search your personas",
  txt_project_name: "Project Name",
  txt_lead: "Lead",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "Show more",
  start_date: "Start Date",
  end_date: "End Date",
  created_date: "Created Date",
  txt_short_description: "Short Description",
  txt_short_description_about_project: "Short description about project",
  txt_project_logo: "Project Logo",
  txt_edit_project: "Edit project",
  txt_save_project: "Save project",
  txt_budget: "Budget",
  txt_campaign_name: "Campaign name",
  create_campaign: "Create campaign",
  edit_campaign: "Edit campaign",
  save_campaign: "Save campaign",
  txt_status: "Status",
  create_your_1st_campaigns: "Create your 1st campaigns",
  create_your_1st_project: "Create your 1st project",
  create_your_1st_persona: "create your 1st persona",
  txt_name_personas: "Page/Profile",
  updated_date: "Updated Date",
  connected_channels: "Connected Channels",
  txt_title: "Title",
  txt_channels: "Channels",
  txt_date: "Date",
  txt_edit: "Edit",
  txt_general: "General",
  txt_choose_the_project: "Choose the Project",
  txt_choose_the_campaign: "Choose the Campaign",
  txt_headline: "Headline",
  txt_add_channels: "Add Channels",
  txt_save: "Save",
  txt_connected_channels: "Channels",
  content_description: "Content Description",
  txt_advance_mode: "Advance mode",
  txt_back: "Back",
  txt_next: "Next",
  txt_connecting: "Connecting",
  txt_connect: "Connect",
  txt_enable: "Enable",
  txt_remove: "Remove",
  txt_type: "Type",
  txt_action: "Connections",
  txt_profile: "Profile",
  txt_days: " days",
  txt_apply: "Apply",
  txt_0_days: "0 days",
  txt_week: "week",
  txt_month: "month",
  txt_agenda: "agenda",
  txt_nopost_agenda: "There are no events in this range.",
  txt_video: "Video",
  txt_digital_asset_management: "Digital Asset Management",
  txt_digital_assets: "Digital Asset",
  txt_publish: "Publish",
  "txt_when_to_publish_this?": "When to publish this ?",
  txt_save_as_draft: "Save As Draft",
  txt_choose_file: "Choose File",
  "txt_select...": "Select...",
  txt_project: "Project",
  txt_post_now: "Post Now",
  txt_schedule: "Schedule",
  "txt_publish_date/time": "Publish Date/time",
  txt_update_successfully: "Update Successfully",
  txt_update_unsuccessfully: "Update Unsuccessfully",
  txt_please_upgrade_account: "Please upgrade account",
  txt_please_connect_a_channel: "Please connect a channel",
  txt_the_video_field_is_required: "The video field is required",
  txt_logo_picture: "Logo",
  txt_canva_api_key_warning: "Please replace REACT_APP_CANVA_API_KEY or Canva button is only working on localhost",
  txt_running: "Running",
  txt_stop: "Stop",
  txt_sign_in_to_getting_started: "Sign In to getting started.",
  txt_warning: "Warning",
  txt_successful: "Successful",
  txt_error: "Error",
  txt_title_no_matching_results: "No Matching Results",
  txt_text_no_matching_results: "Can not found any project with that keyword. Please try another keyword.",
  "txt_ maintenance": "Maintenance",
  txt_all_dates: " All Dates",
  txt_api_error: "An error occurred. Please try again later.",
  txt_intro_chatgpt: "Hi there! How can I assist you today?",
  txt_open_api_key_warning: "Please enter API keys from https://platform.openai.com/account/api-keys",
  txt_connect_channel: "Connect channel",
  txt_create_content: "Create content",
  txt_delete_folder: "Delete Folder",
  txt_are_you_sure: "Are you sure?",
  txt_delete_assets_popup_desc: "Are you sure you want to delete this asset ?",
  txt_Cancel: "Cancel",
  txt_yes_delete: "Yes, Delete",
  txt_description_limmit: " The description exceeds the allowed characters ",
  txt_headline_limmit: " The headline exceeds the allowed characters ",
  txt_the_media_field_is_required: " The media field is required",
  txt_the_image_field_is_required: " The image field is required",
  txt_left_menu_dma: "Marketing",
  txt_create_new_post: "Create new Post",
  txt_group_type: "Group Type",
  txt_submenu_group_management: "Group Management",
  txt_content: "Content",
  txt_disconnect: "Disconnect",
  txt_failed: "Failed",
  txt_bulk_disconnect: "Bulk Disconnect",
  txt_menu_posts: "Posts"
};

// src/translations/es/common.json
var common_default3 = {
  txt_welcome_to: "Bienvenido a",
  txt_sign_in_to_see_latest_updates: "Inicie sesi\xF3n para ver las \xFAltimas actualizaciones.",
  txt_or_continue_with: "O continue con",
  txt_email: "Correo el\xE9ctronico",
  txt_password: "Contrase\xF1a",
  txt_remember_me: "Recu\xE9rdeme",
  txt_sign_in: "Iniciar sesi\xF3n",
  txt_sign_in_with_sso: "Iniciar sesi\xF3n con AesirX SSO",
  txt_sign_up: "Registrarse",
  txt_do_not_have_an_account: "\xBFNo tiene una cuenta? Crear una cuenta nueva",
  txt_welcome_to_our_aesirx: "Bienvenido a nuestro AesirX - DMA.",
  txt_sign_up_to_getting_started: "Reg\xEDstrese para comenzar.",
  txt_or_register_with: "O reg\xEDstrese con",
  txt_username: "Nombre de usuario",
  txt_what_is_the_main_thing_you_want_to_manage: "\xBFQu\xE9 es lo principal que desea gestionar?",
  txt_you_agree_to_our: "Al registrarme, acepto los",
  txt_terms_of_service: "t\xE9rminos de servicio",
  txt_privacy_policy: "pol\xEDticas de privacidad",
  txt_and: "y",
  txt_already_have_an_account: "\xBFYa tiene una cuenta? Iniciar sesi\xF3n",
  txt_email_already_exists: "El correo electr\xF3nico ya existe",
  txt_already_exists: "Ya existe",
  txt_log_in: "Iniciar sesi\xF3n",
  txt_loading: "Cargando",
  txt_forgot_your_password: "\xBFOlvid\xF3 su contrase\xF1a?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "Le enviaremos instrucciones por correo electr\xF3nico sobre c\xF3mo restablecer su contrase\xF1a",
  txt_reset_password: "Restablecer la contrase\xF1a",
  txt_verify_your_email_address: "\xA1Verifique su direcci\xF3n de correo electr\xF3nico!",
  txt_a_verification_email_has_been_sent_to_your_email: "Se ha enviado un correo de verificaci\xF3n a su correo electr\xF3nico.",
  txt_please_check_your_email: "Por favor revise su correo electr\xF3nico y verifique la cuenta haciendo clic en el bot\xF3n {{characters}}confirme su email{{characters}} para completar el registro de su cuenta.",
  txt_if_you_do_not_receive_the_email: "Si no recibe el correo electr\xF3nico, haga clic en el bot\xF3n a continuaci\xF3n para volver a enviar el correo electr\xF3nico de verificaci\xF3n.",
  txt_resend_email: "Reenviar email",
  txt_search_for_something: "buscar algo ...",
  txt_recent: "Reciente",
  txt_view_all_projects: "Ver todos los proyectos",
  txt_search_projects: "Buscar proyectos ...",
  txt_create_a_new_project: "Crear un nuevo proyecto",
  txt_all_projects: "Todos los proyectos",
  txt_help_center: "centro de ayuda",
  txt_sign_out: "Desconectar",
  txt_notifications: "Notificaciones",
  txt_mark_all_as_read: "Marcar todo como leido",
  txt_internal: "Interno",
  txt_client: "Cliente",
  txt_task_complete: "Tarea completa",
  txt_more: "M\xE1s",
  txt_projects: "Proyectos",
  txt_assigned_to_me: "Asignado a m\xED",
  txt_campaigns: "Campa\xF1as",
  txt_marketing_automation_made_easy: "Automatizaci\xF3n de marketing, \xA1f\xE1cil!",
  txt_we_have_created_a_step_by_step_process: "Hemos creado un proceso paso a paso que lo guiar\xE1 sobre c\xF3mo comenzar.",
  txt_social: "Redes sociales",
  txt_cms: "CMS",
  txt_mail: "Correo",
  txt_title_calendar: "Calendario",
  txt_title_projects: "Proyectos",
  txt_title_campaigns: "Listar campa\xF1as",
  txt_title_content: "Listar Proyectos",
  txt_title_channels: "Conectar un canal",
  txt_title_digital_assets: "Recursos digitales",
  txt_title_new_calendar: "Nuevo horario",
  txt_menu_calendar: "Canlendario",
  txt_menu_projects: "Proyectos",
  txt_menu_campaigns: "Campa\xF1as",
  txt_menu_content: "Contenido",
  txt_menu_channels: "Canales",
  txt_menu_digital_assets: "Recursos digitales",
  txt_today: "Hoy",
  txt_general_information: "Informaci\xF3n General",
  txt_projectpage_password: "Contrase\xF1a",
  txt_profile_picture: "Foto de perfil",
  txt_list_post: "Lista de publicaciones",
  txt_list_campaigns: "Lista de campa\xF1as",
  txt_connect_a_channel: "Canal de contenido",
  txt_back_to_dashboard: "Volver al panel",
  txt_menu_profile: "Perfil",
  txt_Username: "Nombre de usuario",
  txt_Email: "Correo Electr\xF3nico",
  txt_Status: "Status",
  txt_Birthday: "Cumplea\xF1os",
  txt_Phone: "Tel\xE9fono",
  txt_Address_1: "Direcci\xF3n 1",
  txt_Address_2: "Direcci\xF3n 2",
  txt_State: "Estado",
  txt_Country: "Pa\xEDs",
  txt_Zipcode: "C\xF3digo postal",
  txt_Fullname: "Nombre completo",
  txt_City: "Ciudad",
  txt_current_password: "Contrase\xF1a actual",
  txt_confirm_password: "Confirmar nueva contrase\xF1a",
  txt_new_password: "Nueva contrase\xF1a",
  txt_update: "Actualizar",
  delete: "Borrar",
  choose_an_action: "Elige una acci\xF3n",
  txt_create_project: "Crear proyecto",
  txt_create_post: "Crear publicaci\xF3n",
  txt_create_campaigns: "Crear Campa\xF1a",
  create_a_new_persona: "Crear una nueva persona",
  txt_coming_soon: "Pr\xF3ximamente",
  txt_filter: "Filtro",
  txt_clear_all: "Limpiar todo",
  txt_select_all: "Seleccionar todo",
  txt_deselect_all: "Deseleccionar todo",
  txt_columns: "Columnas",
  txt_list: "Lista",
  txt_thumb: "Pulgar",
  search_your_project: "Busque su proyecto",
  search_your_post: "Busque su publicaci\xF3n",
  search_your_campaign: "Busque su campa\xF1a",
  search_your_personas: "Busque sus personas",
  txt_project_name: "Nombre del proyecto",
  txt_lead: "gu\xEDa",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "Mostrar m\xE1s",
  start_date: "Fecha de inicio",
  end_date: "Fecha final",
  created_date: "Crear fecha",
  txt_short_description: "Breve descripci\xF3n",
  txt_short_description_about_project: "Breve descripci\xF3n sobre el proyecto.",
  txt_project_logo: "Logo del proyecto",
  txt_edit_project: "Editar proyecto",
  txt_save_project: "Guardar proyecto",
  txt_budget: "Presupuesto",
  txt_campaign_name: "Nombre de la campa\xF1a",
  create_campaign: "Crear Campa\xF1a",
  edit_campaign: "Editar campa\xF1a",
  save_campaign: "Guardar Campa\xF1a",
  txt_status: "Estado",
  create_your_1st_campaigns: "Crear su primera campa\xF1a",
  create_your_1st_project: "Crear su primer proyecto",
  create_your_1st_persona: "crear su primera personal",
  txt_name_personas: "Nombre",
  updated_date: "Fecha actualizada",
  connected_channels: "Canales conectados",
  txt_title: "T\xEDtulo",
  txt_channels: "Canales",
  txt_date: "Fecha",
  txt_edit: "Editar",
  txt_general: "General",
  txt_choose_the_project: "Elige el proyecto",
  txt_choose_the_campaign: "Elige la Campa\xF1a",
  txt_headline: "Titular",
  txt_add_channels: "Agregar canales",
  txt_save: "Salvar",
  txt_connected_channels: "Canales conectados",
  content_description: "Descripci\xF3n del contenido",
  txt_advance_mode: "Modo avanzado",
  txt_back: "de vuelta",
  txt_next: "la pr\xF3xima vez",
  txt_connecting: "Conectando",
  txt_connect: "Conectar",
  txt_enable: "Habilitar",
  txt_remove: "Remover",
  txt_type: "Escribe",
  txt_action: "Acci\xF3n",
  txt_profile: "Perfil",
  txt_days: "d\xEDas",
  txt_apply: "Aplicar",
  txt_0_days: "0 d\xEDas",
  txt_week: "semana",
  txt_month: "mes",
  txt_agenda: "orden del d\xEDa",
  txt_nopost_agenda: "No hay eventos en este rango.",
  txt_video: "Video",
  txt_digital_asset_management: "Gesti\xF3n de activos digitales",
  txt_digital_assets: "Activo digital",
  txt_publish: "Publicar",
  "txt_when_to_publish_this?": "\xBFCu\xE1ndo publicar esto?",
  txt_save_as_draft: "Guardar como borrador",
  txt_choose_file: "Elija el archivo",
  "txt_select...": "Seleccione...",
  txt_project: "Proyecto",
  txt_post_now: "Publicar ahora",
  txt_schedule: "Calendario",
  "txt_publish_date/time": "Fecha/hora de publicaci\xF3n",
  txt_update_successfully: "Actualizar correctamente",
  txt_update_unsuccessfully: "Actualizar sin \xE9xito",
  txt_please_upgrade_account: "Actualice la cuenta",
  txt_please_connect_a_channel: "Conecta un canal",
  txt_the_video_field_is_required: "El campo de v\xEDdeo es obligatorio",
  txt_logo_picture: "Logo",
  txt_canva_api_key_warning: "Reemplace REACT_APP_CANVA_API_KEY o el bot\xF3n de Canva solo funciona en localhost",
  txt_running: "Correr",
  txt_stop: "det\xE9ngase",
  txt_sign_in_to_getting_started: "Inicia sesi\xF3n para empezar.",
  txt_warning: "Advertencia",
  txt_successful: "Exitoso",
  txt_error: "Error",
  txt_forgot_password: "\xBFOlvidaste la contrase\xF1a?",
  txt_title_no_matching_results: "Sin resultados coincidentes",
  txt_text_no_matching_results: "No se puede encontrar ning\xFAn proyecto con esa palabra clave. Intente con otra palabra clave",
  "txt_ maintenance": "Mantenimiento",
  txt_all_dates: " Todas las fechas",
  txt_api_error: "Ocurri\xF3 un error. Vuelve a intentarlo m\xE1s tarde.",
  txt_intro_chatgpt: "\xA1Hola! \xBFC\xF3mo puedo ayudarte hoy?",
  txt_open_api_key_warning: "Ingrese las claves API de https://platform.openai.com/account/api-keys",
  txt_connect_channel: "Conectar canal",
  txt_create_content: "Crear contenido",
  txt_delete_folder: "Eliminar carpeta",
  txt_are_you_sure: "Estas seguro",
  txt_delete_assets_popup_desc: "\xBFEst\xE1 seguro de que desea eliminar este recurso?",
  txt_Cancel: "Cancelar",
  txt_yes_delete: "S\xED, Eliminar",
  txt_description_limmit: " La descripci\xF3n excede los caracteres permitidos ",
  txt_headline_limmit: " El t\xEDtulo excede los caracteres permitidos ",
  txt_the_media_field_is_required: " El campo multimedia es obligatorio ",
  txt_the_image_field_is_required: " El campo de la imagen es obligatorio ",
  txt_create_new_post: "Crear nueva publicaci\xF3n",
  txt_group_type: "Tipo de grupo",
  txt_content: "Contenido",
  txt_disconnect: "Desconectar",
  txt_failed: "Error",
  txt_bulk_disconnect: "Desconexi\xF3n masiva"
};

// src/translations/hr/common.json
var common_default4 = {
  txt_welcome_to: "Dobrodo\u0161li u ",
  txt_sign_in_to_see_latest_updates: "Prijavite se da vidite najnovija a\u017Euriranja",
  txt_or_continue_with: "Ili nastavite sa",
  txt_email: "Email",
  txt_password: "Zaporka",
  txt_remember_me: "Zapamti me",
  txt_sign_in: "Prijavi se",
  txt_sign_in_with_sso: "Prijavite se sa SSO",
  txt_sign_up: "Prijavi se za",
  txt_do_not_have_an_account: "Nemate ra\u010Dun? Napravite besplatni ra\u010Dun",
  txt_welcome_to_our_aesirx: "Dobrodo\u0161li u na\u0161 AesirX - DMA.",
  txt_sign_up_to_getting_started: "Prijavite se za po\u010Detak.",
  txt_or_register_with: "Ili se registrirajte sa ",
  txt_username: "Korisni\u010Dko ime",
  txt_what_is_the_main_thing_you_want_to_manage: "Koja je glavna stvar kojom \u017Eelite upravljati?",
  txt_you_agree_to_our: "Registracijom prihva\u0107am",
  txt_terms_of_service: "Uvjete pru\u017Eanja usluge",
  txt_privacy_policy: "politiku privatnosti",
  txt_and: "i",
  txt_already_have_an_account: "Ve\u0107 imam ra\u010Dun? Prijavi se",
  txt_email_already_exists: "Email ve\u0107 postoji",
  txt_already_exists: "Ve\u0107 postoji",
  txt_log_in: "Prijavi se",
  txt_loading: "U\u010Ditavanje",
  txt_forgot_your_password: "Zaboravili ste zaporku?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "E-po\u0161tom \u0107emo vam poslati upute o tome kako resetirati lozinku",
  txt_reset_password: "Resetiranje lozinke",
  txt_verify_your_email_address: "Potvrdi svoju email adresu!",
  txt_a_verification_email_has_been_sent_to_your_email: "Email za potvrdu poslan je na va\u0161u e-po\u0161tu",
  txt_please_check_your_email: "Provjerite svoju e-po\u0161tu i potvrdite ra\u010Dun klikom na gumb {{characters}} Potvrdite svoj email{{characters}} da bi ste dovr\u0161ili registraciju ra\u010Duna.",
  txt_if_you_do_not_receive_the_email: "Ako niste primili e-po\u0161tu, kliknite na gumb ispod da ponovno po\u0161aljete e-po\u0161tu za potvrdu.",
  txt_resend_email: "Ponovno po\u0161alji email",
  txt_search_for_something: "Tra\u017Eenje ne\u010Dega ...",
  txt_recent: "Nedavno",
  txt_view_all_projects: "Vidi sve projekte",
  txt_search_projects: "Pretra\u017Ei projekte ...",
  txt_create_a_new_project: "Kreiraj novi projekt",
  txt_all_projects: "Svi projekti",
  txt_help_center: "centar za pomo\u0107",
  txt_sign_out: "Odjavi se",
  txt_notifications: "Obavijesti",
  txt_mark_all_as_read: "Ozna\u010Di sve kao pro\u010Ditano",
  txt_internal: "Interni",
  txt_client: "Klijent",
  txt_task_complete: "Zadatak zavr\u0161en",
  txt_more: "Vi\u0161e",
  txt_projects: "Projekti",
  txt_assigned_to_me: "Dodijeljeno meni",
  txt_campaigns: "Kampanje",
  txt_marketing_automation_made_easy: "Marketin\u0161ka automatizacija, na lak\u0161i na\u010Din!",
  txt_we_have_created_a_step_by_step_process: "Napravili smo postupak koji \u0107e Vas korak po korak voditi kako zapo\u010Deti.",
  txt_social: "Dru\u0161tvani mediji",
  txt_cms: "CMS",
  txt_mail: "Mail",
  txt_title_calendar: "Raspored",
  txt_title_projects: "Projekti",
  txt_title_campaigns: "Lista Kampanja",
  txt_title_content: "Lista Projekta",
  txt_title_channels: "Spoji se na Kanal",
  txt_title_digital_assets: "Digitalni resursi",
  txt_title_new_calendar: "Novi raspored",
  txt_menu_calendar: "Kalendar",
  txt_menu_projects: "Projekti",
  txt_menu_campaigns: "Kampanje",
  txt_menu_content: "Sadr\u017Eaj",
  txt_menu_channels: "Kanali",
  txt_menu_digital_assets: "Digitalni resursi",
  txt_today: "Danas",
  txt_general_information: "Op\u0107e informacije",
  txt_projectpage_password: "Zaporka",
  txt_profile_picture: "Slika profila",
  txt_list_post: "Lista Objava",
  txt_list_campaigns: "Lista Kampanja",
  txt_connect_a_channel: "Spoji se na Kanal",
  txt_back_to_dashboard: "Natrag na nadzornu plo\u010Du",
  txt_menu_profile: "Profil",
  txt_Username: "Korisni\u010Dko ime",
  txt_Email: "Email",
  txt_Status: "Status",
  txt_Birthday: "Ro\u0111endan",
  txt_Phone: "Telefon",
  txt_Address_1: "Addresa 1",
  txt_Address_2: "Addresa 2",
  txt_State: "Pokrajna",
  txt_Country: "Dr\u017Eava",
  txt_Zipcode: "Po\u0161tanski broj",
  txt_Fullname: "Puno ime",
  txt_City: "Grad",
  txt_current_password: "Trenutna Zaporka",
  txt_confirm_password: "Potvrdi novu Zaporku",
  txt_new_password: "Nova Zaporka",
  txt_update: "A\u017Euriraj",
  delete: "Izbri\u0161i",
  choose_an_action: "Izaberi akciju",
  txt_create_project: "Kreiraj Projekt",
  txt_create_post: "Kreiraj Objavu",
  txt_create_campaigns: "Kreiraj Kampanju",
  create_a_new_persona: "Stvorite novu osobu",
  txt_coming_soon: "Dolazi uskoro",
  txt_filter: "Filter",
  txt_clear_all: "O\u010Disti sve",
  txt_select_all: "Odaberi sve",
  txt_deselect_all: "Odzna\u010Di sve",
  txt_columns: "Kolumne",
  txt_list: "Popis",
  txt_thumb: "Palac",
  search_your_project: "Pretra\u017Ei svoj projekt",
  search_your_post: "Pretra\u017Ei svoje objave",
  search_your_campaign: "SPretra\u017Ei svoje kampanje",
  search_your_personas: "Pretra\u017Ei svoje osobe",
  txt_project_name: "Ime projekta",
  txt_lead: "Vo\u0111a",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "Prika\u017Ei vi\u0161e",
  start_date: "Datum po\u010Detka",
  end_date: "Datum zavr\u0161etka",
  created_date: "Datum kreiranja",
  txt_short_description: "Kratki opis",
  txt_short_description_about_project: "Kratki opis o projektu",
  txt_project_logo: "Logo projekta",
  txt_edit_project: "Uredi projekt",
  txt_save_project: "Spremi projekt",
  txt_budget: "Prora\u010Dun",
  txt_campaign_name: "Ime kampanje",
  create_campaign: "Kreiraj kampanju",
  edit_campaign: "Uredi kampanju",
  save_campaign: "Spremi kampanju",
  txt_status: "Status",
  create_your_1st_campaigns: "Kreiraj svoju prvu kampanju",
  create_your_1st_project: "Kreiraj svoj prvi projekt",
  create_your_1st_persona: "Kreiraj svoju prvu osobu",
  txt_name_personas: "Ime",
  updated_date: "Datum a\u017Euriranja",
  connected_channels: "Spojeni kanali",
  txt_title: "Naslov",
  txt_channels: "Kanali",
  txt_date: "Datum",
  txt_edit: "Uredi",
  txt_general: "Op\u0107e",
  txt_choose_the_project: "Izaberi projekt",
  txt_choose_the_campaign: "Izaberi kampanju",
  txt_headline: "Naslov",
  txt_add_channels: "Dodaj kanale",
  txt_save: "Spremi",
  txt_connected_channels: "Spojeni kanali",
  content_description: "Opis sadr\u017Eaja",
  txt_advance_mode: "Napredni na\u010Din rada",
  txt_back: "Natrag",
  txt_next: "Naprijed",
  txt_connecting: "Spajanje",
  txt_connect: "Spojen",
  txt_enable: "Omogu\u0107en",
  txt_remove: "Maknuti",
  txt_type: "Vrsta",
  txt_action: "Akcija",
  txt_profile: "Profil",
  txt_days: " dani",
  txt_apply: "Primjeni",
  txt_0_days: "0 dana",
  txt_week: "tjedan",
  txt_month: "mjesec",
  txt_agenda: "dnevi red",
  txt_nopost_agenda: "Nema doga\u0111aja u ovom rasponu.",
  txt_video: "Video",
  txt_digital_asset_management: "Upravljanje digitalnim resursima",
  txt_digital_assets: "Digitalni resursi",
  txt_publish: "Objavi",
  "txt_when_to_publish_this?": "Kada treba objaviti ovo ?",
  txt_save_as_draft: "Spremi kao nacrt",
  txt_choose_file: "Izaberi datoteku",
  "txt_select...": "Izaberi...",
  txt_project: "Projekt",
  txt_post_now: "Objavi sada",
  txt_schedule: "Raspored",
  "txt_publish_date/time": "Datum/vrijeme objave",
  txt_update_successfully: "A\u017Euriranje uspje\u0161no",
  txt_update_unsuccessfully: "A\u017Euriranje neuspje\u0161no",
  txt_please_upgrade_account: "Molim Vas da a\u017Eurirate ra\u0107un",
  txt_please_connect_a_channel: "Molim Vas da spojite kanal",
  txt_the_video_field_is_required: "Polje za video je obavezno",
  txt_logo_picture: "Logo",
  txt_canva_api_key_warning: "Molimo Vas da zamjenite REACT_APP_CANVA_API_KEY ili Canva gumbi \u0107e jedino raditi na localhosu",
  txt_running: "Radi...",
  txt_stop: "Stani",
  txt_sign_in_to_getting_started: "Prijavite se da biste zapo\u010Deli.",
  txt_warning: "Upozorenje",
  txt_successful: "Uspje\u0161no",
  txt_error: "Gre\u0161ka",
  txt_forgot_password: "Zaboravljena zaporka ?",
  txt_title_no_matching_results: "Nema odgovaraju\u0107ih rezultata",
  txt_text_no_matching_results: "Nije mogu\u0107e prona\u0107i nijedan projekt s tom klju\u010Dnom rije\u010Di. Poku\u0161ajte s drugom klju\u010Dnom rije\u010Di.",
  "txt_ maintenance": "Odr\u017Eanje",
  txt_all_dates: " Svi datumi",
  txt_api_error: "Do\u0161lo je do pogre\u0161ke. Poku\u0161ajte ponovno kasnije.",
  txt_intro_chatgpt: "Bok! Kako vam mogu pomo\u0107i danas?",
  txt_open_api_key_warning: "Unesite API klju\u010Deve s https://platform.openai.com/account/api-keys",
  txt_connect_channel: "Pove\u017Ei kanal",
  txt_create_content: "Stvori sadr\u017Eaj",
  txt_delete_folder: "Izbri\u0161i mapu",
  txt_are_you_sure: "Da li set sigurni?",
  txt_delete_assets_popup_desc: "Jeste li sigurni da \u017Eelite izbrisati ovaj materijal ?",
  txt_Cancel: "Prekini",
  txt_yes_delete: "Da, izbri\u0161i",
  txt_description_limit: " La description d\xE9passe les caract\xE8res autoris\xE9s ",
  txt_headline_limit: " Le titre d\xE9passe les caract\xE8res autoris\xE9s ",
  txt_the_media_field_is_required: " Le champ m\xE9dia est obligatoire ",
  txt_the_image_field_is_required: " Le champ image est obligatoire ",
  txt_create_new_post: "Stvori novi post",
  txt_group_type: "Vrsta grupe",
  txt_content: "Sadr\u017Eaj",
  txt_disconnect: "Prekini vezu",
  txt_failed: "Neuspje\u0161no",
  txt_bulk_disconnect: "Skupno prekidanje veze"
};

// src/translations/th/common.json
var common_default5 = {
  txt_welcome_to: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E19\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48",
  txt_sign_in_to_see_latest_updates: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E14\u0E39\u0E01\u0E32\u0E23\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15\u0E25\u0E48\u0E32\u0E2A\u0E38\u0E14",
  txt_or_continue_with: "\u0E2B\u0E23\u0E37\u0E2D\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23\u0E15\u0E48\u0E2D\u0E14\u0E49\u0E27\u0E22",
  txt_email: "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  txt_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_remember_me: "\u0E08\u0E14\u0E08\u0E33\u0E09\u0E31\u0E19",
  txt_sign_in: "\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A",
  txt_sign_in_with_sso: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E14\u0E49\u0E27\u0E22 AesirX SSO",
  txt_sign_up: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49",
  txt_do_not_have_an_account: "\u0E22\u0E31\u0E07\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49? \u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E1F\u0E23\u0E35",
  txt_welcome_to_our_aesirx: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E19\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48 AesirX - DMA.",
  txt_sign_up_to_getting_started: "\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_or_register_with: "\u0E2B\u0E23\u0E37\u0E2D\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19\u0E14\u0E49\u0E27\u0E22",
  txt_username: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_what_is_the_main_thing_you_want_to_manage: "\u0E2D\u0E30\u0E44\u0E23\u0E04\u0E37\u0E2D\u0E2A\u0E34\u0E48\u0E07\u0E2A\u0E33\u0E04\u0E31\u0E0D\u0E17\u0E35\u0E48\u0E04\u0E38\u0E13\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E08\u0E31\u0E14\u0E01\u0E32\u0E23?",
  txt_you_agree_to_our: "\u0E42\u0E14\u0E22\u0E01\u0E32\u0E23\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19 \u0E09\u0E31\u0E19\u0E22\u0E2D\u0E21\u0E23\u0E31\u0E1A",
  txt_terms_of_service: "\u0E40\u0E07\u0E37\u0E48\u0E2D\u0E19\u0E44\u0E02\u0E01\u0E32\u0E23\u0E43\u0E2B\u0E49\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23",
  txt_privacy_policy: "\u0E19\u0E42\u0E22\u0E1A\u0E32\u0E22\u0E04\u0E27\u0E32\u0E21\u0E40\u0E1B\u0E47\u0E19\u0E2A\u0E48\u0E27\u0E19\u0E15\u0E31\u0E27",
  txt_and: "\u0E41\u0E25\u0E30",
  txt_already_have_an_account: "\u0E21\u0E35\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27? \u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A",
  txt_email_already_exists: "\u0E21\u0E35\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27",
  txt_already_exists: "\u0E21\u0E35\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27",
  txt_log_in: "\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A",
  txt_loading: "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E42\u0E2B\u0E25\u0E14",
  txt_forgot_your_password: "\u0E25\u0E37\u0E21\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "\u0E40\u0E23\u0E32\u0E08\u0E30\u0E2A\u0E48\u0E07\u0E04\u0E33\u0E41\u0E19\u0E30\u0E19\u0E33\u0E43\u0E19\u0E01\u0E32\u0E23\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E43\u0E2B\u0E49\u0E04\u0E38\u0E13\u0E17\u0E32\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25",
  txt_reset_password: "\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_verify_your_email_address: "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13!",
  txt_a_verification_email_has_been_sent_to_your_email: "\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E44\u0E1B\u0E17\u0E35\u0E48\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E41\u0E25\u0E49\u0E27",
  txt_please_check_your_email: "\u0E42\u0E1B\u0E23\u0E14\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E41\u0E25\u0E30\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E42\u0E14\u0E22\u0E04\u0E25\u0E34\u0E01\u0E17\u0E35\u0E48\u0E1B\u0E38\u0E48\u0E21 {{characters}}Confirm your email{{characters}} \u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E2A\u0E23\u0E47\u0E08\u0E2A\u0E34\u0E49\u0E19\u0E01\u0E32\u0E23\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_if_you_do_not_receive_the_email: "\u0E2B\u0E32\u0E01\u0E04\u0E38\u0E13\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25 \u0E43\u0E2B\u0E49\u0E04\u0E25\u0E34\u0E01\u0E1B\u0E38\u0E48\u0E21\u0E14\u0E49\u0E32\u0E19\u0E25\u0E48\u0E32\u0E07\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07",
  txt_resend_email: "\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07",
  txt_search_for_something: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E1A\u0E32\u0E07\u0E2A\u0E34\u0E48\u0E07 ...",
  txt_recent: "\u0E25\u0E48\u0E32\u0E2A\u0E38\u0E14",
  txt_view_all_projects: "\u0E14\u0E39\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_search_projects: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23 ...",
  txt_create_a_new_project: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23\u0E43\u0E2B\u0E21\u0E48",
  txt_all_projects: "\u0E17\u0E38\u0E01\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_help_center: "\u0E28\u0E39\u0E19\u0E22\u0E4C\u0E0A\u0E48\u0E27\u0E22\u0E40\u0E2B\u0E25\u0E37\u0E2D",
  txt_sign_out: "\u0E2D\u0E2D\u0E01\u0E08\u0E32\u0E01\u0E23\u0E30\u0E1A\u0E1A",
  txt_notifications: "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  txt_mark_all_as_read: "\u0E17\u0E33\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14\u0E27\u0E48\u0E32\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27",
  txt_internal: "\u0E20\u0E32\u0E22\u0E43\u0E19",
  txt_client: "\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32",
  txt_task_complete: "\u0E07\u0E32\u0E19\u0E40\u0E2A\u0E23\u0E47\u0E08\u0E2A\u0E21\u0E1A\u0E39\u0E23\u0E13\u0E4C",
  txt_more: "\u0E21\u0E32\u0E01\u0E02\u0E36\u0E49\u0E19",
  txt_projects: "\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_assigned_to_me: "\u0E21\u0E2D\u0E1A\u0E2B\u0E21\u0E32\u0E22\u0E43\u0E2B\u0E49\u0E09\u0E31\u0E19",
  txt_campaigns: "\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  txt_marketing_automation_made_easy: "\u0E23\u0E30\u0E1A\u0E1A\u0E01\u0E32\u0E23\u0E15\u0E25\u0E32\u0E14\u0E2D\u0E31\u0E15\u0E42\u0E19\u0E21\u0E31\u0E15\u0E34 \u0E17\u0E33\u0E43\u0E2B\u0E49\u0E07\u0E48\u0E32\u0E22\u0E02\u0E36\u0E49\u0E19!",
  txt_we_have_created_a_step_by_step_process: "\u0E40\u0E23\u0E32\u0E44\u0E14\u0E49\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E01\u0E23\u0E30\u0E1A\u0E27\u0E19\u0E01\u0E32\u0E23\u0E17\u0E35\u0E25\u0E30\u0E02\u0E31\u0E49\u0E19\u0E15\u0E2D\u0E19\u0E17\u0E35\u0E48\u0E08\u0E30\u0E41\u0E19\u0E30\u0E19\u0E33\u0E04\u0E38\u0E13\u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E01\u0E31\u0E1A\u0E27\u0E34\u0E18\u0E35\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_social: "\u0E2A\u0E37\u0E48\u0E2D\u0E2A\u0E31\u0E07\u0E04\u0E21",
  txt_cms: "CMS",
  txt_mail: "\u0E08\u0E14\u0E2B\u0E21\u0E32\u0E22",
  txt_title_calendar: "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E01\u0E32\u0E23",
  txt_title_projects: "\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_title_campaigns: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  txt_title_content: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_title_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_title_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_title_new_calendar: "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E01\u0E32\u0E23\u0E43\u0E2B\u0E21\u0E48",
  txt_menu_calendar: "\u0E1B\u0E0F\u0E34\u0E17\u0E34\u0E19",
  txt_menu_projects: "\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_menu_campaigns: "\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  txt_menu_content: "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32",
  txt_menu_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  txt_menu_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_today: "\u0E27\u0E31\u0E19\u0E19\u0E35\u0E49",
  txt_general_information: "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E17\u0E31\u0E48\u0E27\u0E44\u0E1B",
  txt_projectpage_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_profile_picture: "\u0E23\u0E39\u0E1B\u0E1B\u0E23\u0E30\u0E27\u0E31\u0E15\u0E34",
  txt_list_post: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E42\u0E1E\u0E2A",
  txt_list_campaigns: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  txt_connect_a_channel: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07\u0E17\u0E35\u0E48\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_back_to_dashboard: "\u0E01\u0E25\u0E31\u0E1A\u0E44\u0E1B\u0E17\u0E35\u0E48\u0E41\u0E14\u0E0A\u0E1A\u0E2D\u0E23\u0E4C\u0E14",
  txt_menu_profile: "\u0E1B\u0E23\u0E30\u0E27\u0E31\u0E15\u0E34\u0E42\u0E14\u0E22\u0E22\u0E48\u0E2D",
  txt_Username: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49",
  txt_Email: "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  txt_Status: "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  txt_Birthday: "\u0E40\u0E01\u0E34\u0E14\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48",
  txt_Phone: "\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C",
  txt_Address_1: "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 1",
  txt_Address_2: "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 2",
  txt_State: "\u0E08\u0E31\u0E07\u0E2B\u0E27\u0E31\u0E14",
  txt_Country: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  txt_Zipcode: "\u0E23\u0E2B\u0E31\u0E2A\u0E44\u0E1B\u0E23\u0E29\u0E13\u0E35\u0E22\u0E4C",
  txt_Fullname: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E15\u0E47\u0E21",
  txt_City: "\u0E40\u0E02\u0E15/\u0E2D\u0E33\u0E40\u0E20\u0E2D",
  txt_current_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19",
  txt_confirm_password: "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E43\u0E2B\u0E21\u0E48",
  txt_new_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E43\u0E2B\u0E21\u0E48",
  txt_update: "\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07",
  delete: "\u0E25\u0E1A",
  choose_an_action: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E01\u0E23\u0E30\u0E17\u0E33",
  txt_create_project: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_create_post: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E1E\u0E2A",
  txt_create_campaigns: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  create_a_new_persona: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1A\u0E38\u0E04\u0E25\u0E34\u0E01\u0E43\u0E2B\u0E21\u0E48",
  txt_coming_soon: "\u0E40\u0E23\u0E47\u0E27\u0E46 \u0E19\u0E35\u0E49",
  txt_filter: "\u0E15\u0E31\u0E27\u0E01\u0E23\u0E2D\u0E07",
  txt_clear_all: "\u0E25\u0E49\u0E32\u0E07\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_select_all: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_deselect_all: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01\u0E01\u0E32\u0E23\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_columns: "\u0E04\u0E2D\u0E25\u0E31\u0E21\u0E20\u0E4C",
  txt_list: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23",
  txt_thumb: "\u0E2B\u0E31\u0E27\u0E41\u0E21\u0E48\u0E21\u0E37\u0E2D",
  search_your_project: "\u0E04\u0E49\u0E19\u0E2B\u0E32 \u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  search_your_post: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E42\u0E1E\u0E2A\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  search_your_campaign: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  search_your_personas: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E15\u0E31\u0E27\u0E15\u0E19\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_project_name: "\u0E0A\u0E37\u0E48\u0E2D\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_lead: "Lead",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "\u0E41\u0E2A\u0E14\u0E07\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E02\u0E36\u0E49\u0E19",
  start_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E40\u0E23\u0E34\u0E48\u0E21",
  end_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E34\u0E49\u0E19\u0E2A\u0E38\u0E14",
  created_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E23\u0E49\u0E32\u0E07",
  txt_short_description: "\u0E23\u0E32\u0E22\u0E25\u0E30\u0E40\u0E2D\u0E35\u0E22\u0E14\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E22\u0E48\u0E2D",
  txt_short_description_about_project: "\u0E23\u0E32\u0E22\u0E25\u0E30\u0E40\u0E2D\u0E35\u0E22\u0E14\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E22\u0E48\u0E2D \u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E01\u0E31\u0E1A\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_project_logo: "\u0E42\u0E25\u0E42\u0E01\u0E49\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_edit_project: "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_save_project: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_budget: "\u0E07\u0E1A\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13",
  txt_campaign_name: "\u0E0A\u0E37\u0E48\u0E2D\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  create_campaign: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  edit_campaign: "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  save_campaign: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  txt_status: "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  create_your_1st_campaigns: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D\u0E41\u0E23\u0E01\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  create_your_1st_project: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23\u0E41\u0E23\u0E01\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  create_your_1st_persona: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1A\u0E38\u0E04\u0E25\u0E34\u0E01\u0E20\u0E32\u0E1E\u0E41\u0E23\u0E01\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_name_personas: "\u0E0A\u0E37\u0E48\u0E2D",
  updated_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15",
  connected_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E35\u0E48\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_title: "\u0E0A\u0E37\u0E48\u0E2D",
  txt_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  txt_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48",
  txt_edit: "\u0E41\u0E01\u0E49\u0E44\u0E02",
  txt_general: "\u0E17\u0E31\u0E48\u0E27\u0E44\u0E1B",
  txt_choose_the_project: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_choose_the_campaign: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E41\u0E04\u0E21\u0E40\u0E1B\u0E0D",
  txt_headline: "Headline",
  txt_add_channels: "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  txt_save: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01",
  txt_connected_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E35\u0E48\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  content_description: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32",
  txt_advance_mode: "\u0E42\u0E2B\u0E21\u0E14\u0E02\u0E31\u0E49\u0E19\u0E2A\u0E39\u0E07",
  txt_back: "\u0E22\u0E49\u0E2D\u0E19\u0E01\u0E25\u0E31\u0E1A",
  txt_next: "\u0E15\u0E48\u0E2D\u0E44\u0E1B",
  txt_connecting: "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_connect: "\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_enable: "\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49",
  txt_remove: "\u0E40\u0E2D\u0E32\u0E2D\u0E2D\u0E01",
  txt_type: "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17",
  txt_action: "\u0E01\u0E32\u0E23\u0E01\u0E23\u0E30\u0E17\u0E33",
  txt_profile: "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2A\u0E48\u0E27\u0E19\u0E15\u0E31\u0E27",
  txt_days: " \u0E27\u0E31\u0E19",
  txt_apply: "\u0E19\u0E33\u0E21\u0E32\u0E43\u0E0A\u0E49",
  txt_0_days: "0 \u0E27\u0E31\u0E19",
  txt_week: "\u0E2A\u0E31\u0E1B\u0E14\u0E32\u0E2B\u0E4C",
  txt_month: "\u0E40\u0E14\u0E37\u0E2D\u0E19",
  txt_agenda: "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E01\u0E32\u0E23",
  txt_nopost_agenda: "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E01\u0E34\u0E08\u0E01\u0E23\u0E23\u0E21\u0E43\u0E19\u0E0A\u0E48\u0E27\u0E07\u0E19\u0E35\u0E49",
  txt_video: "Video",
  txt_digital_asset_management: "\u0E01\u0E32\u0E23\u0E08\u0E31\u0E14\u0E01\u0E32\u0E23\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_publish: "\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48",
  "txt_when_to_publish_this?": "\u0E40\u0E21\u0E37\u0E48\u0E2D\u0E44\u0E23\u0E08\u0E30\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48\u0E2A\u0E34\u0E48\u0E07\u0E19\u0E35\u0E49 ?",
  txt_save_as_draft: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E40\u0E1B\u0E47\u0E19\u0E23\u0E48\u0E32\u0E07",
  txt_choose_file: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E1F\u0E25\u0E4C",
  "txt_select...": "\u0E40\u0E25\u0E37\u0E2D\u0E01...",
  txt_project: "\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_post_now: "\u0E42\u0E1E\u0E2A \u0E15\u0E2D\u0E19\u0E19\u0E35\u0E49",
  txt_schedule: "\u0E15\u0E31\u0E49\u0E07\u0E40\u0E27\u0E25\u0E32\u0E25\u0E48\u0E27\u0E07\u0E2B\u0E19\u0E49\u0E32",
  "txt_publish_date/time": "\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48 \u0E27\u0E31\u0E19/\u0E40\u0E27\u0E25\u0E32",
  txt_update_successfully: "\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07\u0E40\u0E2A\u0E23\u0E47\u0E08\u0E40\u0E23\u0E35\u0E22\u0E1A\u0E23\u0E49\u0E2D\u0E22",
  txt_update_unsuccessfully: "\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07\u0E44\u0E21\u0E48\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  txt_please_upgrade_account: "\u0E01\u0E23\u0E38\u0E13\u0E32\u0E2D\u0E31\u0E1A\u0E40\u0E01\u0E23\u0E14\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_please_connect_a_channel: "\u0E01\u0E23\u0E38\u0E13\u0E32\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  txt_the_video_field_is_required: "\u0E1F\u0E34\u0E25\u0E14\u0E4C\u0E27\u0E35\u0E14\u0E35\u0E42\u0E2D \u0E08\u0E33\u0E40\u0E1B\u0E47\u0E19\u0E15\u0E49\u0E2D\u0E07\u0E21\u0E35",
  txt_logo_picture: "\u0E42\u0E25\u0E42\u0E01\u0E49",
  txt_canva_api_key_warning: "\u0E42\u0E1B\u0E23\u0E14\u0E41\u0E17\u0E19\u0E17\u0E35\u0E48 REACT_APP_CANVA_API_KEY \u0E21\u0E34\u0E09\u0E30\u0E19\u0E31\u0E49\u0E19\u0E1B\u0E38\u0E48\u0E21 Canva \u0E08\u0E30\u0E17\u0E33\u0E07\u0E32\u0E19\u0E1A\u0E19 localhost \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  txt_running: "\u0E27\u0E34\u0E48\u0E07",
  txt_stop: "\u0E2B\u0E22\u0E38\u0E14",
  txt_sign_in_to_getting_started: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_warning: "\u0E04\u0E33\u0E40\u0E15\u0E37\u0E2D\u0E19",
  txt_successful: "\u0E1B\u0E23\u0E30\u0E2A\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  txt_error: "\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14",
  txt_forgot_password: "\u0E25\u0E37\u0E21\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19 ?",
  txt_title_no_matching_results: "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1C\u0E25\u0E25\u0E31\u0E1E\u0E18\u0E4C\u0E17\u0E35\u0E48\u0E15\u0E23\u0E07\u0E01\u0E31\u0E19",
  txt_text_no_matching_results: "\u0E44\u0E21\u0E48\u0E1E\u0E1A\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23\u0E43\u0E14\u0E46 \u0E17\u0E35\u0E48\u0E21\u0E35\u0E04\u0E33\u0E2B\u0E25\u0E31\u0E01\u0E19\u0E31\u0E49\u0E19 \u0E42\u0E1B\u0E23\u0E14\u0E25\u0E2D\u0E07\u0E04\u0E33\u0E2B\u0E25\u0E31\u0E01\u0E2D\u0E37\u0E48\u0E19",
  "txt_ maintenance": "\u0E01\u0E32\u0E23\u0E0B\u0E48\u0E2D\u0E21\u0E1A\u0E33\u0E23\u0E38\u0E07",
  txt_all_dates: " \u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_api_error: "\u0E40\u0E01\u0E34\u0E14\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14 \u0E42\u0E1B\u0E23\u0E14\u0E25\u0E2D\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07\u0E43\u0E19\u0E20\u0E32\u0E22\u0E2B\u0E25\u0E31\u0E07",
  txt_intro_chatgpt: "\u0E2A\u0E27\u0E31\u0E2A\u0E14\u0E35 \u0E27\u0E31\u0E19\u0E19\u0E35\u0E49\u0E09\u0E31\u0E19\u0E08\u0E30\u0E0A\u0E48\u0E27\u0E22\u0E04\u0E38\u0E13\u0E44\u0E14\u0E49\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E44\u0E23",
  txt_open_api_key_warning: "\u0E42\u0E1B\u0E23\u0E14\u0E1B\u0E49\u0E2D\u0E19\u0E04\u0E35\u0E22\u0E4C API \u0E08\u0E32\u0E01 https://platform.openai.com/account/api-keys",
  txt_connect_channel: "\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13",
  txt_create_content: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32",
  txt_delete_folder: "\u0E25\u0E1A\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_are_you_sure: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E19\u0E30?",
  txt_delete_assets_popup_desc: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E19\u0E35\u0E49 ?",
  txt_Cancel: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
  txt_yes_delete: "\u0E43\u0E0A\u0E48, \u0E25\u0E1A",
  txt_description_limmit: " \u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E40\u0E01\u0E34\u0E19\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E17\u0E35\u0E48\u0E2D\u0E19\u0E38\u0E0D\u0E32\u0E15 ",
  txt_headline_limmit: " \u0E1A\u0E23\u0E23\u0E17\u0E31\u0E14\u0E41\u0E23\u0E01\u0E40\u0E01\u0E34\u0E19\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E17\u0E35\u0E48\u0E2D\u0E19\u0E38\u0E0D\u0E32\u0E15 ",
  txt_the_media_field_is_required: " \u0E1F\u0E34\u0E25\u0E14\u0E4C\u0E2A\u0E37\u0E48\u0E2D\u0E08\u0E33\u0E40\u0E1B\u0E47\u0E19 ",
  txt_the_image_field_is_required: " \u0E15\u0E49\u0E2D\u0E07\u0E23\u0E30\u0E1A\u0E38\u0E0A\u0E48\u0E2D\u0E07\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E ",
  txt_create_new_post: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E1E\u0E2A\u0E15\u0E4C\u0E43\u0E2B\u0E21\u0E48",
  txt_group_type: "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E25\u0E38\u0E48\u0E21",
  txt_content: "Sadr\u017Eaj",
  txt_disconnect: "Prekini vezu",
  txt_failed: "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  txt_bulk_disconnect: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E40\u0E1B\u0E47\u0E19\u0E01\u0E25\u0E38\u0E48\u0E21"
};

// src/translations/ua/common.json
var common_default6 = {
  txt_welcome_to: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0434\u043E ",
  txt_sign_in_to_see_latest_updates: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0431\u0430\u0447\u0438\u0442\u0438 \u043E\u0441\u0442\u0430\u043D\u043D\u0456 \u043E\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F.",
  txt_or_continue_with: "\u0410\u0431\u043E \u043F\u0440\u043E\u0434\u043E\u0432\u0436\u0438\u0442\u0438",
  txt_email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430",
  txt_password: "\u041F\u0430\u0440\u043E\u043B\u044C",
  txt_remember_me: "\u0417\u0430\u043F\u0430\u043C'\u044F\u0442\u0430\u0442\u0438 \u043C\u0435\u043D\u0435",
  txt_sign_in: "\u0423\u0432\u0456\u0439\u0442\u0438",
  txt_sign_in_with_sso: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C \u0437\u0430 \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u043E\u044E AesirX SSO",
  txt_sign_up: "\u0417\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0432\u0430\u0442\u0438\u0441\u044F",
  txt_do_not_have_an_account: "\u041D\u0435 \u043C\u0430\u0454\u0442\u0435 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u043E\u0433\u043E \u0437\u0430\u043F\u0438\u0441\u0443? \u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u0431\u0435\u0437\u043A\u043E\u0448\u0442\u043E\u0432\u043D\u0438\u0439 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441",
  txt_welcome_to_our_aesirx: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0434\u043E \u043D\u0430\u0448\u043E\u0433\u043E AesirX - DMA.",
  txt_sign_up_to_getting_started: "\u0417\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0439\u0442\u0435\u0441\u044F, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438 \u0440\u043E\u0431\u043E\u0442\u0443.",
  txt_or_register_with: "\u0410\u0431\u043E \u0437\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0432\u0430\u0442\u0438\u0441\u044F \u0432",
  txt_username: "\u0406\u043C'\u044F \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430",
  txt_what_is_the_main_thing_you_want_to_manage: "\u0429\u043E \u0454 \u0433\u043E\u043B\u043E\u0432\u043D\u0438\u043C, \u0447\u0438\u043C \u0432\u0438 \u0445\u043E\u0447\u0435\u0442\u0435 \u043A\u0435\u0440\u0443\u0432\u0430\u0442\u0438?",
  txt_you_agree_to_our: "\u0420\u0435\u0454\u0441\u0442\u0440\u0443\u044E\u0447\u0438\u0441\u044C, \u044F \u043F\u0440\u0438\u0439\u043C\u0430\u044E",
  txt_terms_of_service: "\u0443\u043C\u043E\u0432\u0438 \u043E\u0431\u0441\u043B\u0443\u0433\u043E\u0432\u0443\u0432\u0430\u043D\u043D\u044F",
  txt_privacy_policy: "\u043F\u043E\u043B\u0456\u0442\u0438\u043A\u0430 \u043A\u043E\u043D\u0444\u0456\u0434\u0435\u043D\u0446\u0456\u0439\u043D\u043E\u0441\u0442\u0456",
  txt_and: "\u0456",
  txt_already_have_an_account: "\u0412\u0436\u0435 \u043C\u0430\u0454\u0442\u0435 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441? \u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C",
  txt_email_already_exists: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u0430\u0434\u0440\u0435\u0441\u0430 \u0432\u0436\u0435 \u0456\u0441\u043D\u0443\u0454",
  txt_already_exists: "\u0412\u0436\u0435 \u0456\u0441\u043D\u0443\u0454",
  txt_log_in: "\u0423\u0432\u0456\u0439\u0442\u0438",
  txt_loading: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F",
  txt_forgot_your_password: "\u0417\u0430\u0431\u0443\u043B\u0438 \u0441\u0432\u0456\u0439 \u043F\u0430\u0440\u043E\u043B\u044C?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "\u041C\u0438 \u043D\u0430\u0434\u0456\u0448\u043B\u0435\u043C\u043E \u0432\u0430\u043C \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u044E \u043F\u043E\u0448\u0442\u043E\u044E \u0456\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0456\u0457 \u0449\u043E\u0434\u043E \u0442\u043E\u0433\u043E, \u044F\u043A \u0441\u043A\u0438\u043D\u0443\u0442\u0438 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_reset_password: "\u0421\u043A\u0438\u043D\u0443\u0442\u0438 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_verify_your_email_address: "\u041F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u044C\u0442\u0435 \u0441\u0432\u043E\u044E \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443 \u0430\u0434\u0440\u0435\u0441\u0443!",
  txt_a_verification_email_has_been_sent_to_your_email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0438\u0439 \u043B\u0438\u0441\u0442 \u0434\u043B\u044F \u043F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u0436\u0435\u043D\u043D\u044F \u043D\u0430\u0434\u0456\u0441\u043B\u0430\u043D\u043E \u043D\u0430 \u0432\u0430\u0448\u0443 \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443 \u0430\u0434\u0440\u0435\u0441\u0443",
  txt_please_check_your_email: "\u041F\u0435\u0440\u0435\u0432\u0456\u0440\u0442\u0435 \u0441\u0432\u043E\u044E \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443 \u043F\u043E\u0448\u0442\u0443 \u0442\u0430 \u043F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u044C\u0442\u0435 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441, \u043D\u0430\u0442\u0438\u0441\u043D\u0443\u0432\u0448\u0438 \u043A\u043D\u043E\u043F\u043A\u0443 {{characters}}\u041F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u044C\u0442\u0435 \u0441\u0432\u043E\u044E \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443 \u0430\u0434\u0440\u0435\u0441\u0443{{characters}}, \u0449\u043E\u0431 \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u0438 \u0440\u0435\u0454\u0441\u0442\u0440\u0430\u0446\u0456\u044E \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u043E\u0433\u043E \u0437\u0430\u043F\u0438\u0441\u0443.",
  txt_if_you_do_not_receive_the_email: "\u042F\u043A\u0449\u043E \u0432\u0438 \u043D\u0435 \u043E\u0442\u0440\u0438\u043C\u0430\u043B\u0438 \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0438\u0439 \u043B\u0438\u0441\u0442, \u043D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C \u043A\u043D\u043E\u043F\u043A\u0443 \u043D\u0438\u0436\u0447\u0435, \u0449\u043E\u0431 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043D\u0430\u0434\u0456\u0441\u043B\u0430\u0442\u0438 \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0438\u0439 \u043B\u0438\u0441\u0442 \u0434\u043B\u044F \u043F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u0436\u0435\u043D\u043D\u044F.",
  txt_resend_email: "\u041F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043D\u0430\u0434\u0456\u0441\u043B\u0430\u0442\u0438 \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0438\u0439 \u043B\u0438\u0441\u0442",
  txt_search_for_something: "\u0428\u0443\u043A\u0430\u0442\u0438 \u0449\u043E\u0441\u044C ...",
  txt_recent: "\u041E\u0441\u0442\u0430\u043D\u043D\u0456",
  txt_view_all_projects: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438 \u0432\u0441\u0456 \u043F\u0440\u043E\u0435\u043A\u0442\u0438",
  txt_search_projects: "\u0428\u0443\u043A\u0430\u0442\u0438 \u043F\u0440\u043E\u0435\u043A\u0442\u0438 ...",
  txt_create_a_new_project: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043D\u043E\u0432\u0438\u0439 \u043F\u0440\u043E\u0435\u043A\u0442",
  txt_all_projects: "\u0423\u0441\u0456 \u043F\u0440\u043E\u0435\u043A\u0442\u0438",
  txt_help_center: "\u0434\u043E\u0432\u0456\u0434\u043A\u043E\u0432\u0438\u0439 \u0446\u0435\u043D\u0442\u0440",
  txt_sign_out: "\u0412\u0438\u0439\u0442\u0438",
  txt_notifications: "\u0421\u043F\u043E\u0432\u0456\u0449\u0435\u043D\u043D\u044F",
  txt_mark_all_as_read: "\u041F\u043E\u0437\u043D\u0430\u0447\u0438\u0442\u0438 \u0432\u0441\u0435 \u044F\u043A \u043F\u0440\u043E\u0447\u0438\u0442\u0430\u043D\u0435",
  txt_internal: "\u0412\u043D\u0443\u0442\u0440\u0456\u0448\u043D\u0456\u0439",
  txt_client: "\u041A\u043B\u0456\u0454\u043D\u0442",
  txt_task_complete: "\u0417\u0430\u0432\u0434\u0430\u043D\u043D\u044F \u0432\u0438\u043A\u043E\u043D\u0430\u043D\u043E",
  txt_more: "\u0411\u0456\u043B\u044C\u0448\u0435",
  txt_projects: "\u041F\u0440\u043E\u0435\u043A\u0442\u0438",
  txt_assigned_to_me: "\u041F\u0440\u0438\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u043C\u0435\u043D\u0456",
  txt_campaigns: "\u041A\u0430\u043C\u043F\u0430\u043D\u0456\u0457",
  txt_marketing_automation_made_easy: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0437\u0430\u0446\u0456\u044F \u043C\u0430\u0440\u043A\u0435\u0442\u0438\u043D\u0433\u0443 \u2013 \u0446\u0435 \u043B\u0435\u0433\u043A\u043E!",
  txt_we_have_created_a_step_by_step_process: "\u041C\u0438 \u0441\u0442\u0432\u043E\u0440\u0438\u043B\u0438 \u043F\u043E\u043A\u0440\u043E\u043A\u043E\u0432\u0438\u0439 \u043F\u0440\u043E\u0446\u0435\u0441, \u044F\u043A\u0438\u0439 \u0434\u043E\u043F\u043E\u043C\u043E\u0436\u0435 \u0432\u0430\u043C \u043F\u043E\u0447\u0430\u0442\u0438 \u0440\u043E\u0431\u043E\u0442\u0443.",
  txt_social: "\u0421\u043E\u0446\u0456\u0430\u043B\u044C\u043D\u0456 \u043C\u0435\u0440\u0435\u0436\u0456",
  txt_cms: "CMS",
  txt_mail: "\u041F\u043E\u0448\u0442\u0430",
  txt_title_calendar: "\u0420\u043E\u0437\u043A\u043B\u0430\u0434",
  txt_title_projects: "\u041F\u0440\u043E\u0435\u043A\u0442\u0438",
  txt_title_campaigns: "\u0421\u043F\u0438\u0441\u043E\u043A \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u0439",
  txt_title_content: "\u0421\u043F\u0438\u0441\u043E\u043A \u043F\u0440\u043E\u0435\u043A\u0442\u0443",
  txt_title_channels: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438 \u043A\u0430\u043D\u0430\u043B",
  txt_title_digital_assets: "\u0426\u0438\u0444\u0440\u043E\u0432\u0456 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_title_new_calendar: "\u041D\u043E\u0432\u0438\u0439 \u0440\u043E\u0437\u043A\u043B\u0430\u0434",
  txt_menu_calendar: "\u041A\u0430\u043B\u0435\u043D\u0434\u0430\u0440",
  txt_menu_projects: "\u041F\u0440\u043E\u0435\u043A\u0442\u0438",
  txt_menu_campaigns: "\u041A\u0430\u043C\u043F\u0430\u043D\u0456\u0457",
  txt_menu_content: "\u0412\u043C\u0456\u0441\u0442",
  txt_menu_channels: "\u041A\u0430\u043D\u0430\u043B\u0438",
  txt_menu_digital_assets: "\u0426\u0438\u0444\u0440\u043E\u0432\u0456 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_today: "\u0421\u044C\u043E\u0433\u043E\u0434\u043D\u0456",
  txt_general_information: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0430 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044F",
  txt_projectpage_password: "\u041F\u0430\u0440\u043E\u043B\u044C",
  txt_profile_picture: "\u0417\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F \u043F\u0440\u043E\u0444\u0456\u043B\u044E",
  txt_list_post: "\u0421\u043F\u0438\u0441\u043E\u043A \u0434\u043E\u043F\u0438\u0441\u0456\u0432",
  txt_list_campaigns: "\u0421\u043F\u0438\u0441\u043E\u043A \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u0439",
  txt_connect_a_channel: "\u0412\u043C\u0456\u0441\u0442 \u043A\u0430\u043D\u0430\u043B\u0443",
  txt_back_to_dashboard: "\u041D\u0430\u0437\u0430\u0434 \u0434\u043E \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u043E\u0457 \u043F\u0430\u043D\u0435\u043B\u0456",
  txt_menu_profile: "\u041F\u0440\u043E\u0444\u0456\u043B\u044C",
  txt_Username: "\u0406\u043C'\u044F \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430",
  txt_Email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430",
  txt_Status: "\u0421\u0442\u0430\u0442\u0443\u0441",
  txt_Birthday: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0440\u043E\u0434\u0436\u0435\u043D\u043D\u044F",
  txt_Phone: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
  txt_Address_1: "\u0410\u0434\u0440\u0435\u0441\u0430 1",
  txt_Address_2: "\u0410\u0434\u0440\u0435\u0441\u0430 2",
  txt_State: "\u0428\u0442\u0430\u0442",
  txt_Country: "\u041A\u0440\u0430\u0457\u043D\u0430",
  txt_Zipcode: "\u041F\u043E\u0448\u0442\u043E\u0432\u0438\u0439 \u0456\u043D\u0434\u0435\u043A\u0441",
  txt_Fullname: "\u041F\u043E\u0432\u043D\u0435 \u0456\u043C'\u044F",
  txt_City: "\u041C\u0456\u0441\u0442\u043E",
  txt_current_password: "\u041F\u043E\u0442\u043E\u0447\u043D\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_confirm_password: "\u041F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0438 \u043D\u043E\u0432\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_new_password: "\u041D\u043E\u0432\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_update: "\u041E\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F",
  delete: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  choose_an_action: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0434\u0456\u044E",
  txt_create_project: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043F\u0440\u043E\u0435\u043A\u0442",
  txt_create_post: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043F\u043E\u0441\u0442",
  txt_create_campaigns: "\u0421\u0442\u0432\u043E\u0440\u0435\u043D\u043D\u044F \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u0439",
  create_a_new_persona: "\u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u043D\u043E\u0432\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0443",
  txt_coming_soon: "\u041D\u0435\u0437\u0430\u0431\u0430\u0440\u043E\u043C",
  txt_filter: "\u0424\u0456\u043B\u044C\u0442\u0440",
  txt_clear_all: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u0438 \u0432\u0441\u0435",
  txt_select_all: "\u0412\u0438\u0431\u0440\u0430\u0442\u0438 \u0432\u0441\u0435",
  txt_deselect_all: "C\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438 \u0432\u0438\u0431\u0456\u0440 \u0443\u0441\u0456\u0445",
  txt_columns: "\u0421\u0442\u043E\u0432\u043F\u0446\u0456",
  txt_list: "\u0421\u043F\u0438\u0441\u043E\u043A",
  txt_thumb: "\u041C\u0456\u043D\u0456\u0430\u0442\u044E\u0440\u0430",
  search_your_project: "\u0417\u043D\u0430\u0439\u0434\u0456\u0442\u044C \u0441\u0432\u0456\u0439 \u043F\u0440\u043E\u0435\u043A\u0442",
  search_your_post: "\u0417\u043D\u0430\u0439\u0434\u0456\u0442\u044C \u0441\u0432\u0456\u0439 \u043F\u0440\u043E\u0435\u043A\u0442",
  search_your_campaign: "\u0417\u043D\u0430\u0439\u0434\u0456\u0442\u044C \u0441\u0432\u043E\u044E \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u044E",
  search_your_personas: "\u0417\u043D\u0430\u0439\u0434\u0456\u0442\u044C \u0441\u0432\u043E\u0457\u0445 \u043F\u0435\u0440\u0441\u043E\u043D",
  txt_project_name: "\u041D\u0430\u0437\u0432\u0430 \u043F\u0440\u043E\u0435\u043A\u0442\u0443",
  txt_lead: "\u0412\u0435\u0441\u0442\u0438",
  txt_peter_stanbridge: "\u041F\u0456\u0442\u0435\u0440 \u0421\u0442\u0435\u043D\u0431\u0440\u0456\u0434\u0436",
  txt_show_more: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0438 \u0431\u0456\u043B\u044C\u0448\u0435",
  start_date: "\u0414\u0430\u0442\u0430 \u043F\u043E\u0447\u0430\u0442\u043A\u0443",
  end_date: "\u0414\u0430\u0442\u0430 \u0437\u0430\u043A\u0456\u043D\u0447\u0435\u043D\u043D\u044F",
  created_date: "\u0414\u0430\u0442\u0430 \u0441\u0442\u0432\u043E\u0440\u0435\u043D\u043D\u044F",
  txt_short_description: "\u041A\u043E\u0440\u043E\u0442\u043A\u0438\u0439 \u043E\u043F\u0438\u0441",
  txt_short_description_about_project: "\u041A\u043E\u0440\u043E\u0442\u043A\u0438\u0439 \u043E\u043F\u0438\u0441 \u043F\u0440\u043E\u0435\u043A\u0442\u0443",
  txt_project_logo: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F \u043F\u0440\u043E\u0435\u043A\u0442\u0443",
  txt_edit_project: "\u0420\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438 \u043F\u0440\u043E\u0435\u043A\u0442",
  txt_save_project: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u043F\u0440\u043E\u0435\u043A\u0442",
  txt_budget: "\u0411\u044E\u0434\u0436\u0435\u0442",
  txt_campaign_name: "\u041D\u0430\u0437\u0432\u0430 \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u0457",
  create_campaign: "\u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u044E",
  edit_campaign: "\u0420\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438 \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u044E",
  save_campaign: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u044E",
  txt_status: "\u0421\u0442\u0430\u0442\u0443\u0441",
  create_your_1st_campaigns: "\u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u0432\u0430\u0448\u0456 \u043F\u0435\u0440\u0448\u0456 \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u0457",
  create_your_1st_project: "\u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u0432\u0430\u0448 1-\u0439 \u043F\u0440\u043E\u0435\u043A\u0442",
  create_your_1st_persona: "\u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u0441\u0432\u043E\u044E \u043F\u0435\u0440\u0448\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0443",
  txt_name_personas: "\u0406\u043C'\u044F",
  updated_date: "\u0414\u0430\u0442\u0430 \u043E\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F",
  connected_channels: "\u041F\u043E\u0432'\u044F\u0437\u0430\u043D\u0456 \u043A\u0430\u043D\u0430\u043B\u0438",
  txt_title: "\u041D\u0430\u0437\u0432\u0430",
  txt_channels: "\u041A\u0430\u043D\u0430\u043B\u0438",
  txt_date: "\u0414\u0430\u0442\u0430",
  txt_edit: "\u0420\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438",
  txt_general: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0438\u0439",
  txt_choose_the_project: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u043F\u0440\u043E\u0435\u043A\u0442",
  txt_choose_the_campaign: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u043A\u0430\u043C\u043F\u0430\u043D\u0456\u044E",
  txt_headline: "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A",
  txt_add_channels: "\u0414\u043E\u0434\u0430\u0442\u0438 \u043A\u0430\u043D\u0430\u043B\u0438",
  txt_save: "\u0437\u0431\u0435\u0440\u0435\u0433\u0442\u0438",
  txt_connected_channels: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0456 \u043A\u0430\u043D\u0430\u043B\u0438",
  content_description: "\u041E\u043F\u0438\u0441 \u0432\u043C\u0456\u0441\u0442\u0443",
  txt_advance_mode: "\u0420\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u0438\u0439 \u0440\u0435\u0436\u0438\u043C",
  txt_back: "\u041D\u0430\u0437\u0430\u0434",
  txt_next: "\u0414\u0430\u043B\u0456",
  txt_connecting: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044F",
  txt_connect: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438\u0441\u044F",
  txt_enable: "\u0434\u0430\u0432\u0430\u0442\u0438 \u0437\u043C\u043E\u0433\u0443",
  txt_remove: "\u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_type: "\u0434\u0440\u0443\u043A",
  txt_action: "\u0414\u0456\u044F",
  txt_profile: "\u041F\u0440\u043E\u0444\u0456\u043B\u044C",
  txt_days: " \u0434\u043D\u0456\u0432",
  txt_apply: "\u0417\u0430\u0441\u0442\u043E\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_0_days: "0 \u0434\u043D\u0456\u0432",
  txt_week: "\u0442\u0438\u0436\u0434\u0435\u043D\u044C",
  txt_month: "\u043C\u0456\u0441\u044F\u0447\u043D\u0438\u043A",
  txt_agenda: "\u043F\u043E\u0440\u044F\u0434\u043E\u043A \u0434\u0435\u043D\u043D\u0438\u0439",
  txt_nopost_agenda: "\u0423 \u0446\u044C\u043E\u043C\u0443 \u0434\u0456\u0430\u043F\u0430\u0437\u043E\u043D\u0456 \u043D\u0435\u043C\u0430\u0454 \u043F\u043E\u0434\u0456\u0439.",
  txt_video: "\u0432\u0456\u0434\u0435\u043E",
  txt_digital_asset_management: "\u0423\u043F\u0440\u0430\u0432\u043B\u0456\u043D\u043D\u044F \u0446\u0438\u0444\u0440\u043E\u0432\u0438\u043C\u0438 \u0430\u043A\u0442\u0438\u0432\u0430\u043C\u0438",
  txt_digital_assets: "\u0426\u0438\u0444\u0440\u043E\u0432\u0438\u0439 \u0430\u043A\u0442\u0438\u0432",
  txt_publish: "\u041E\u043F\u0443\u0431\u043B\u0456\u043A\u0443\u0432\u0430\u0442\u0438",
  "txt_when_to_publish_this?": "\u041A\u043E\u043B\u0438 \u043E\u043F\u0443\u0431\u043B\u0456\u043A\u0443\u0432\u0430\u0442\u0438 \u0446\u0435?",
  txt_save_as_draft: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u044F\u043A \u0447\u0435\u0440\u043D\u0435\u0442\u043A\u0443",
  txt_choose_file: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0444\u0430\u0439\u043B",
  "txt_select...": "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C...",
  txt_project: "\u0414\u0435\u043C\u043E\u043D\u0441\u0442\u0440\u0443\u0432\u0430\u0442\u0438",
  txt_post_now: "\u041E\u043F\u0443\u0431\u043B\u0456\u043A\u0443\u0432\u0430\u0442\u0438 \u0437\u0430\u0440\u0430\u0437",
  txt_schedule: "\u0440\u043E\u0437\u043A\u043B\u0430\u0434",
  "txt_publish_date/time": "\u0414\u0430\u0442\u0430/\u0447\u0430\u0441 \u043F\u0443\u0431\u043B\u0456\u043A\u0430\u0446\u0456\u0457",
  txt_update_successfully: "\u041E\u043D\u043E\u0432\u043B\u0435\u043D\u043E \u0443\u0441\u043F\u0456\u0448\u043D\u043E",
  txt_update_unsuccessfully: "\u041D\u0435\u0432\u0434\u0430\u043B\u0435 \u043E\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F",
  txt_please_upgrade_account: "\u041E\u043D\u043E\u0432\u0456\u0442\u044C \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441",
  txt_please_connect_a_channel: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0456\u0442\u044C \u043A\u0430\u043D\u0430\u043B",
  txt_the_video_field_is_required: "\u041F\u043E\u043B\u0435 \u0432\u0456\u0434\u0435\u043E \u0454 \u043E\u0431\u043E\u0432\u2019\u044F\u0437\u043A\u043E\u0432\u0438\u043C",
  txt_logo_picture: "\u043B\u043E\u0433\u043E\u0442\u0438\u043F",
  txt_canva_api_key_warning: "\u0411\u0443\u0434\u044C \u043B\u0430\u0441\u043A\u0430, \u0437\u0430\u043C\u0456\u043D\u0456\u0442\u044C REACT_APP_CANVA_API_KEY \u0430\u0431\u043E \u043A\u043D\u043E\u043F\u043A\u0430 Canva \u043F\u0440\u0430\u0446\u044E\u0454 \u043B\u0438\u0448\u0435 \u043D\u0430 \u043B\u043E\u043A\u0430\u043B\u044C\u043D\u043E\u043C\u0443 \u0445\u043E\u0441\u0442\u0456",
  txt_running: "\u0411\u0456\u0433",
  txt_stop: "\u0421\u0422\u041E\u041F",
  txt_sign_in_to_getting_started: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438.",
  txt_warning: "\u041F\u043E\u043F\u0435\u0440\u0435\u0434\u0436\u0435\u043D\u043D\u044F",
  txt_successful: "\u0423\u0441\u043F\u0456\u0448\u043D\u043E",
  txt_error: "\u041F\u043E\u043C\u0438\u043B\u043A\u0430",
  txt_forgot_password: "\u0417\u0430\u0431\u0443\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?",
  txt_title_no_matching_results: "\u041D\u0435\u043C\u0430\u0454 \u0432\u0456\u0434\u043F\u043E\u0432\u0456\u0434\u043D\u0438\u0445 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0456\u0432",
  txt_text_no_matching_results: "\u041D\u0435 \u0432\u0434\u0430\u043B\u043E\u0441\u044F \u0437\u043D\u0430\u0439\u0442\u0438 \u0436\u043E\u0434\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0435\u043A\u0442\u0443 \u0437 \u0446\u0438\u043C \u043A\u043B\u044E\u0447\u043E\u0432\u0438\u043C \u0441\u043B\u043E\u0432\u043E\u043C. \u0421\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0456\u043D\u0448\u0435 \u043A\u043B\u044E\u0447\u043E\u0432\u0435 \u0441\u043B\u043E\u0432\u043E.",
  "txt_ maintenance": "\u043E\u0431\u0441\u043B\u0443\u0433\u043E\u0432\u0443\u0432\u0430\u043D\u043D\u044F",
  txt_all_dates: " \u0423\u0441\u0456 \u0434\u0430\u0442\u0438",
  txt_api_error: "\u0421\u0442\u0430\u043B\u0430\u0441\u044F \u043F\u043E\u043C\u0438\u043B\u043A\u0430. \u041F\u043E\u0432\u0442\u043E\u0440\u0456\u0442\u044C \u0441\u043F\u0440\u043E\u0431\u0443 \u043F\u0456\u0437\u043D\u0456\u0448\u0435.",
  txt_intro_chatgpt: "\u0412\u0456\u0442\u0430\u044E! \u0427\u0438\u043C \u044F \u043C\u043E\u0436\u0443 \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u0442\u0438 \u0432\u0430\u043C \u0441\u044C\u043E\u0433\u043E\u0434\u043D\u0456?",
  txt_open_api_key_warning: "\u0411\u0443\u0434\u044C \u043B\u0430\u0441\u043A\u0430, \u0432\u0432\u0435\u0434\u0456\u0442\u044C \u043A\u043B\u044E\u0447\u0456 API \u0437 https://platform.openai.com/account/api-keys",
  txt_connect_channel: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438 \u043A\u0430\u043D\u0430\u043B",
  txt_create_content: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u0432\u043C\u0456\u0441\u0442",
  txt_delete_folder: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u043F\u0430\u043F\u043A\u0443",
  txt_are_you_sure: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456?",
  txt_delete_assets_popup_desc: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456, \u0449\u043E \u0445\u043E\u0447\u0435\u0442\u0435 \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u0446\u0435\u0439 \u0440\u0435\u0441\u0443\u0440\u0441?",
  txt_Cancel: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_yes_delete: "\u0422\u0430\u043A, \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_description_limmit: "\u041E\u043F\u0438\u0441 \u043F\u0435\u0440\u0435\u0432\u0438\u0449\u0443\u0454 \u0434\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u0443 \u043A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u0441\u0438\u043C\u0432\u043E\u043B\u0456\u0432 ",
  txt_headline_limmit: " \u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A \u043F\u0435\u0440\u0435\u0432\u0438\u0449\u0443\u0454 \u0434\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u0443 \u043A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u0441\u0438\u043C\u0432\u043E\u043B\u0456\u0432 ",
  txt_the_media_field_is_required: " \u041F\u043E\u043B\u0435 \u043C\u0435\u0434\u0456\u0430 \u0454 \u043E\u0431\u043E\u0432'\u044F\u0437\u043A\u043E\u0432\u0438\u043C ",
  txt_the_image_field_is_required: " \u041F\u043E\u043B\u0435 \u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F \u0454 \u043E\u0431\u043E\u0432'\u044F\u0437\u043A\u043E\u0432\u0438\u043C ",
  txt_create_new_post: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043D\u043E\u0432\u0443 \u043F\u0443\u0431\u043B\u0456\u043A\u0430\u0446\u0456\u044E",
  txt_group_type: "\u0422\u0438\u043F \u0433\u0440\u0443\u043F\u0438",
  txt_content: "\u0412\u043C\u0456\u0441\u0442",
  txt_disconnect: "\u0412\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438",
  txt_failed: "\u041F\u043E\u043C\u0438\u043B\u043A\u0430",
  txt_bulk_disconnect: "\u041C\u0430\u0441\u043E\u0432\u0435 \u0432\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044F"
};

// src/translations/vi/common.json
var common_default7 = {
  txt_welcome_to: "ch\xE0o m\u1EEBng \u0111\u1EBFn",
  txt_sign_in_to_see_latest_updates: "\u0110\u0103ng Nh\u1EADp \u0111\u1EC3 xem c\xE1c c\u1EADp nh\u1EADt m\u1EDBi nh\u1EA5t.",
  txt_or_continue_with: "ho\u1EB7c ti\u1EBFp t\u1EE5c v\u1EDBi",
  txt_email: "Email",
  txt_password: "M\u1EADt Kh\u1EA9u",
  txt_remember_me: "L\u01B0u m\u1EADt kh\u1EA9u",
  txt_sign_in: "\u0110\u0103ng Nh\u1EADp",
  txt_sign_in_with_sso: "\u0110\u0103ng nh\u1EADp b\u1EB1ng AesirX SSO",
  txt_sign_up: "\u0110\u0103ng K\xFD",
  txt_do_not_have_an_account: "Ch\u01B0a c\xF3 t\xE0i kho\u1EA3n? T\u1EA1o t\xE0i kho\u1EA3n mi\u1EC5n ph\xED",
  txt_welcome_to_our_aesirx: "Ch\xE0o m\u1EEBng \u0111\u1EBFn AesirX - DMA.",
  txt_sign_up_to_getting_started: "\u0110\u0103ng K\xFD \u0111\u1EC3 b\u1EAFt \u0111\u1EA7u.",
  txt_or_register_with: "Ho\u1EB7c \u0111\u0103ng k\xFD v\u1EDBi",
  txt_username: "T\xEAn ng\u01B0\u1EDDi d\xF9ng",
  txt_what_is_the_main_thing_you_want_to_manage: "Qu\u1EA3n l\xFD nh\u1EEFng ph\u1EA7n ch\xEDnh?",
  txt_you_agree_to_our: "b\u1EB1ng c\xE1ch \u0111\u0103ng k\xFD, t\xF4i \u0111\u1ED3ng \xFD v\u1EDBi",
  txt_terms_of_service: "c\xE1c \u0111i\u1EC1u kho\u1EA3n d\u1ECBch v\u1EE5",
  txt_privacy_policy: "ch\xEDnh s\xE1ch b\u1EA3o m\u1EADt",
  txt_and: "v\xE0",
  txt_already_have_an_account: "\u0110\xE3 c\xF3 t\xE0i kho\u1EA3n? \u0110\u0103ng nh\u1EADp",
  txt_email_already_exists: "Email \u0111\xE3 t\u1ED3n t\u1EA1i",
  txt_already_exists: "\u0110\xE3 t\u1ED3n t\u1EA1i",
  txt_log_in: "\u0110\u0103ng Nh\u1EADp",
  txt_loading: "\u0110ang t\u1EA3i",
  txt_forgot_your_password: "Qu\xEAn m\u1EADt kh\u1EA9u?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "Ch\xFAng t\xF4i s\u1EBD g\u1EEDi email h\u01B0\u1EDBng d\u1EABn b\u1EA1n \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u",
  txt_reset_password: "\u0110\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u",
  txt_verify_your_email_address: "X\xE1c th\u1EF1c \u0111\u1ECBa ch\u1EC9 email c\u1EE7a b\u1EA1n!",
  txt_a_verification_email_has_been_sent_to_your_email: "M\u1ED9t email x\xE1c th\u1EF1c \u0111\xE3 \u0111\u01B0\u1EE3c g\u1EEDi \u0111\u1EBFn email c\u1EE7a b\u1EA1n",
  txt_please_check_your_email: "Vui l\xF2ng ki\u1EC3m tra email c\u1EE7a b\u1EA1n v\xE0 x\xE1c minh t\xE0i kho\u1EA3n b\u1EB1ng c\xE1ch nh\u1EA5p v\xE0o n\xFAt {{character}} X\xE1c nh\u1EADn email c\u1EE7a b\u1EA1n {{character}} \u0111\u1EC3 ho\xE0n t\u1EA5t vi\u1EC7c \u0111\u0103ng k\xFD t\xE0i kho\u1EA3n c\u1EE7a b\u1EA1n.",
  txt_if_you_do_not_receive_the_email: "N\u1EBFu b\u1EA1n kh\xF4ng nh\u1EADn \u0111\u01B0\u1EE3c email, h\xE3y nh\u1EA5p v\xE0o n\xFAt b\xEAn d\u01B0\u1EDBi \u0111\u1EC3 g\u1EEDi l\u1EA1i email x\xE1c th\u1EF1c.",
  txt_resend_email: "G\u1EEDi l\u1EA1i email",
  txt_search_for_something: "T\xECm ki\u1EBFm ...",
  txt_recent: "G\u1EA7n \u0111\xE2y",
  txt_view_all_projects: "Xem t\u1EA5t c\u1EA3 c\xE1c d\u1EF1 \xE1n",
  txt_search_projects: "T\xECm ki\u1EBFm d\u1EF1 \xE1n...",
  txt_create_a_new_project: "T\u1EA1o d\u1EF1 \xE1n m\u1EDBi",
  txt_all_projects: "T\u1EA5t c\u1EA3 d\u1EF1 \xE1n",
  txt_help_center: "Trung t\xE2m tr\u1EE3 gi\xFAp",
  txt_sign_out: "\u0110\u0103ng Xu\u1EA5t",
  txt_notifications: "Th\xF4ng B\xE1o",
  txt_mark_all_as_read: "\u0110\xE1nh d\u1EA5u \u0111\xE3 \u0111\u1ECDc t\u1EA5t c\u1EA3",
  txt_internal: "N\u1ED9i b\u1ED9",
  txt_client: "Kh\xE1ch h\xE0ng",
  txt_task_complete: "Ho\xE0n th\xE0nh nhi\u1EC7m v\u1EE5",
  txt_more: "Th\xEAm",
  txt_projects: "C\xE1c d\u1EF1 \xE1n",
  txt_assigned_to_me: "Giao cho T\xF4i",
  txt_campaigns: "C\xE1c chi\u1EBFn d\u1ECBch",
  txt_marketing_automation_made_easy: "Ti\u1EBFp th\u1ECB t\u1EF1 \u0111\u1ED9ng, d\u1EC5 d\xE0ng!",
  txt_we_have_created_a_step_by_step_process: "Ch\xFAng t\xF4i \u0111\xE3 t\u1EA1o ra m\u1ED9t quy tr\xECnh t\u1EEBng b\u01B0\u1EDBc \u0111\u1EC3 h\u01B0\u1EDBng d\u1EABn b\u1EA1n c\xE1ch b\u1EAFt \u0111\u1EA7u.",
  txt_social: "Social Media",
  txt_cms: "CMS",
  txt_mail: "Mail",
  txt_title_calendar: "L\u1ECBch",
  txt_title_projects: "C\xE1c D\u1EF1 \xC1n",
  txt_title_campaigns: "Danh s\xE1ch c\xE1c Chi\u1EBFn D\u1ECBch",
  txt_title_content: "Danh s\xE1ch c\xE1c D\u1EF1 \xC1n",
  txt_title_channels: "Li\xEAn k\u1EBFt c\xE1c K\xEAnh",
  txt_title_digital_assets: "T\xE0i Nguy\xEAn K\u1EF9 Thu\u1EADt S\u1ED1",
  txt_title_new_calendar: "L\u1ECBch M\u1EDBi",
  txt_menu_calendar: "L\u1ECBch",
  txt_menu_projects: "C\xE1c D\u1EF1 \xC1n",
  txt_menu_campaigns: "C\xE1c Chi\u1EBFn D\u1ECBch",
  txt_menu_content: "N\u1ED9i Dung",
  txt_menu_channels: "C\xE1c K\xEAnh",
  txt_menu_digital_assets: "T\xE0i Nguy\xEAn K\u1EF9 Thu\u1EADt S\u1ED1",
  txt_today: "H\xF4m nay",
  txt_general_information: "Th\xF4ng tin t\u1ED5ng h\u1EE3p",
  txt_projectpage_password: "M\u1EADt Kh\u1EA9u",
  txt_profile_picture: "\u1EA2nh \u0110\u1EA1i Di\u1EC7n",
  txt_list_post: "Danh s\xE1ch c\xE1c B\xE0i \u0110\u0103ng",
  txt_list_campaigns: "Danh s\xE1ch c\xE1c Chi\u1EBFn D\u1ECBch",
  txt_connect_a_channel: "Li\xEAn k\u1EBFt K\xEAnh",
  txt_back_to_dashboard: "Quay v\u1EC1 Trang Ch\u1EE7",
  txt_menu_profile: "H\u1ED3 S\u01A1",
  txt_Username: "T\xEAn ng\u01B0\u1EDDi d\xF9ng",
  txt_Email: "Email",
  txt_Status: "Tr\u1EA1ng th\xE1i",
  txt_Birthday: "Sinh nh\u1EADt",
  txt_Phone: "S\u1ED1 \u0111i\u1EC7n tho\u1EA1i",
  txt_Address_1: "\u0110\u1ECBa ch\u1EC9 1",
  txt_Address_2: "\u0110\u1ECBa ch\u1EC9 2",
  txt_State: "Bang",
  txt_Country: "Qu\u1ED1c gia",
  txt_Zipcode: "Zipcode",
  txt_Fullname: "T\xEAn \u0111\u1EA7y \u0111\u1EE7",
  txt_City: "Th\xE0nh ph\u1ED1",
  txt_current_password: "M\u1EADt kh\u1EA9u hi\u1EC7n t\u1EA1i",
  txt_confirm_password: "X\xE1c nh\u1EADn m\u1EADt kh\u1EA9u m\u1EDBi",
  txt_new_password: "M\u1EADt kh\u1EA9u m\u1EDBi",
  txt_update: "C\u1EADp nh\u1EADt",
  delete: "Xo\xE1",
  choose_an_action: "Ch\u1ECDn h\xE0nh \u0111\u1ED9ng",
  txt_create_project: "T\u1EA1o D\u1EF1 \xC1n",
  txt_create_post: "T\u1EA1o b\xE0i \u0111\u0103ng",
  txt_create_campaigns: "T\u1EA1o Chi\u1EBFn D\u1ECBch",
  create_a_new_persona: "T\u1EA1o ch\xE2n dung kh\xE1ch h\xE0ng m\u1EDBi",
  txt_coming_soon: "S\u1EAFp ra m\u1EAFt",
  txt_filter: "B\u1ED9 l\u1ECDc",
  txt_clear_all: "Xo\xE1 t\u1EA5t c\u1EA3",
  txt_select_all: "Ch\u1ECDn t\u1EA5t c\u1EA3",
  txt_deselect_all: "B\u1ECF ch\u1ECDn t\u1EA5t c\u1EA3",
  txt_columns: "C\u1ED9t",
  txt_list: "Danh s\xE1ch",
  txt_thumb: "Thu nh\u1ECF",
  search_your_project: "T\xECm ki\u1EBFm d\u1EF1 \xE1n c\u1EE7a b\u1EA1n",
  search_your_post: "T\xECm ki\u1EBFm b\xE0i \u0111\u0103ng c\u1EE7a b\u1EA1n",
  search_your_campaign: "T\xECm ki\u1EBFm chi\u1EBFn d\u1ECBch c\u1EE7a b\u1EA1n",
  search_your_personas: "T\xECm ki\u1EBFm ch\xE2n dung kh\xE1ch h\xE0ng c\u1EE7a b\u1EA1n",
  txt_project_name: "T\xEAn D\u1EF1 \xC1n",
  txt_lead: "Kh\xE1ch h\xE0ng ti\u1EC1m n\u0103ng",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "Hi\u1EC3n th\u1ECB th\xEAm",
  start_date: "Ng\xE0y b\u1EAFt \u0111\u1EA7u",
  end_date: "Ng\xE0y k\u1EBFt th\xFAc",
  created_date: "Ng\xE0y \u0111\u01B0\u1EE3c t\u1EA1o",
  txt_short_description: "M\xF4 t\u1EA3 ng\u1EAFn",
  txt_short_description_about_project: "M\xF4 t\u1EA3 ng\u1EAFn v\u1EC1 d\u1EF1 \xE1n",
  txt_project_logo: "Logo d\u1EF1 \xE1n",
  txt_edit_project: "Ch\u1EC9nh s\u1EEDa d\u1EF1 \xE1n",
  txt_save_project: "L\u01B0u d\u1EF1 \xE1n",
  txt_budget: "Ng\xE2n s\xE1ch",
  txt_campaign_name: "T\xEAn chi\u1EBFn d\u1ECBch",
  create_campaign: "T\u1EA1o chi\u1EBFn d\u1ECBch",
  edit_campaign: "Ch\u1EC9nh s\u1EEDa chi\u1EBFn d\u1ECBch",
  save_campaign: "L\u01B0u chi\u1EBFn d\u1ECBch",
  txt_status: "Tr\u1EA1ng th\xE1i",
  create_your_1st_campaigns: "T\u1EA1o chi\u1EBFn d\u1ECBch \u0111\u1EA7u ti\xEAn c\u1EE7a b\u1EA1n",
  create_your_1st_project: "T\u1EA1o d\u1EF1 \xE1n \u0111\u1EA7u ti\xEAn c\u1EE7a b\u1EA1n",
  create_your_1st_persona: "T\u1EA1o ch\xE2n dung kh\xE1ch h\xE0ng \u0111\u1EA7u ti\xEAn c\u1EE7a b\u1EA1n",
  txt_name_personas: "T\xEAn",
  updated_date: "Ng\xE0y c\u1EADp nh\u1EADt",
  connected_channels: "C\xE1c K\xEAnh \u0111\u01B0\u1EE3c li\xEAn k\u1EBFt",
  txt_title: "T\u1EF1a \u0111\u1EC1",
  txt_channels: "C\xE1c K\xEAnh",
  txt_date: "Ng\xE0y",
  txt_edit: "Ch\u1EC9nh s\u1EEDa",
  txt_general: "T\u1ED5ng h\u1EE3p",
  txt_choose_the_project: "Ch\u1ECDn D\u1EF1 \xC1n",
  txt_choose_the_campaign: "Ch\u1ECDn Chi\u1EBFn D\u1ECBch",
  txt_headline: "Ti\xEAu \u0111\u1EC1",
  txt_add_channels: "Th\xEAm c\xE1c k\xEAnh",
  txt_save: "L\u01B0u",
  txt_connected_channels: "Li\xEAn k\u1EBFt c\xE1c k\xEAnh",
  content_description: "M\xF4 t\u1EA3 n\u1ED9i dung",
  txt_advance_mode: "Ch\u1EBF \u0111\u1ED9 n\xE2ng cao",
  txt_back: "Tr\u1EDF l\u1EA1i",
  txt_next: "Ti\u1EBFp theo",
  txt_connecting: "\u0110ang k\u1EBFt n\u1ED1i",
  txt_connect: "Li\xEAn k\u1EBFt",
  txt_enable: "Cho ph\xE9p",
  txt_remove: "X\xF3a",
  txt_type: "Ki\xEA\u0309u",
  txt_action: "Ho\u1EA1t \u0111\u1ED9ng",
  txt_profile: "H\u1ED3 s\u01A1",
  txt_days: "ng\xE0y",
  txt_apply: "Apply",
  txt_0_days: "0 ng\xE0y",
  txt_week: "tu\u1EA7n",
  txt_month: "th\xE1ng",
  txt_agenda: "nh\xE2\u0323t ky\u0341",
  txt_nopost_agenda: "Kh\xF4ng c\xF3 s\u1EF1 ki\u1EC7n n\xE0o trong ph\u1EA1m vi n\xE0y.",
  txt_video: "Video",
  txt_digital_asset_management: "Qu\u1EA3n l\xFD t\xE0i s\u1EA3n k\u1EF9 thu\u1EADt s\u1ED1",
  txt_digital_assets: "T\xE0i s\u1EA3n k\u1EF9 thu\u1EADt s\u1ED1",
  txt_publish: "Xu\xE2\u0341t ba\u0309n",
  "txt_when_to_publish_this?": "Khi n\xE0o th\xEC xu\u1EA5t b\u1EA3n c\xE1i n\xE0y?",
  txt_save_as_draft: "L\u01B0u d\u01B0\u1EDBi d\u1EA1ng b\u1EA3n nh\xE1p",
  txt_choose_file: "Ch\u1ECDn t\u1EADp tin",
  "txt_select...": "L\u1EF1a ch\u1ECDn...",
  txt_project: "D\u1EF1 \xE1n",
  txt_post_now: "\u0110\u0103ng ngay",
  txt_schedule: "L\u1ECBch tr\xECnh",
  "txt_publish_date/time": "Ng\xE0y / gi\u1EDD xu\u1EA5t b\u1EA3n",
  txt_update_successfully: "C\u1EADp nh\u1EADt th\xE0nh c\xF4ng",
  txt_update_unsuccessfully: "C\u1EADp nh\u1EADt kh\xF4ng th\xE0nh c\xF4ng",
  txt_please_upgrade_account: "Vui l\xF2ng n\xE2ng c\u1EA5p t\xE0i kho\u1EA3n",
  txt_please_connect_a_channel: "H\xE3y k\u1EBFt n\u1ED1i m\u1ED9t k\xEAnh",
  txt_the_video_field_is_required: "Tr\u01B0\u1EDDng video l\xE0 b\u1EAFt bu\u1ED9c",
  txt_logo_picture: "Logo",
  txt_canva_api_key_warning: "Vui l\xF2ng thay th\u1EBF REACT_APP_CANVA_API_KEY ho\u1EB7c n\xFAt Canva ch\u1EC9 ho\u1EA1t \u0111\u1ED9ng tr\xEAn localhost",
  txt_running: "\u0110ang ch\u1EA1y",
  txt_stop: "T\u1EA1m D\u1EEBng",
  txt_sign_in_to_getting_started: "\u0110\u0103ng nh\u1EADp \u0111\u1EC3 b\u1EAFt \u0111\u1EA7u.",
  txt_warning: "C\u1EA3nh b\xE1o",
  txt_successful: "Th\xE0nh c\xF4ng",
  txt_error: "L\u1ED7i",
  txt_forgot_password: "Qu\xEAn m\u1EADt kh\u1EA9u ?",
  txt_title_no_matching_results: "Kh\xF4ng c\xF3 k\u1EBFt qu\u1EA3 ph\xF9 h\u1EE3p",
  txt_text_no_matching_results: "Kh\xF4ng t\xECm th\u1EA5y d\u1EF1 \xE1n n\xE0o v\u1EDBi t\u1EEB kh\xF3a \u0111\xF3. Vui l\xF2ng th\u1EED t\u1EEB kh\xF3a kh\xE1c.",
  "txt_ maintenance": "B\u1EA3o tr\xEC",
  txt_all_dates: " T\u1EA5t c\u1EA3 c\xE1c ng\xE0y",
  txt_api_error: "\u0110\xE3 x\u1EA3y ra l\u1ED7i. Vui l\xF2ng th\u1EED l\u1EA1i sau.",
  txt_intro_chatgpt: "Xin ch\xE0o! T\xF4i c\xF3 th\u1EC3 h\u1ED7 tr\u1EE3 b\u1EA1n nh\u01B0 th\u1EBF n\xE0o h\xF4m nay?",
  txt_open_api_key_warning: "Vui l\xF2ng nh\u1EADp kh\xF3a API t\u1EEB https://platform.openai.com/account/api-keys",
  txt_connect_channel: "K\u1EBFt n\u1ED1i k\xEAnh",
  txt_create_content: "T\u1EA1o n\u1ED9i dung",
  txt_delete_folder: "Xo\u0301a th\u01B0 mu\u0323c",
  txt_are_you_sure: "Ba\u0323n ch\u0103\u0301c ch\u0103\u0301n mu\xF4\u0301n xo\u0301a?",
  txt_delete_assets_popup_desc: "B\u1EA1n c\xF3 ch\u1EAFc ch\u1EAFn mu\xF4\u0301n xo\u0301a m\u1EE5c n\xE0y?",
  txt_Cancel: "Hu\u0309y",
  txt_yes_delete: "\u0110\xF4\u0300ng y\u0301, xo\u0301a",
  txt_description_limmit: " M\xF4 t\u1EA3 v\u01B0\u1EE3t qu\xE1 s\u1ED1 k\xFD t\u1EF1 cho ph\xE9p ",
  txt_headline_limmit: " Ti\xEAu \u0111\u1EC1 v\u01B0\u1EE3t qu\xE1 s\u1ED1 k\xFD t\u1EF1 cho ph\xE9p ",
  txt_the_media_field_is_required: " Tr\u01B0\u1EDDng ph\u01B0\u01A1ng ti\u1EC7n l\xE0 b\u1EAFt bu\u1ED9c ",
  txt_the_image_field_is_required: " Tr\u01B0\u1EDDng h\xECnh \u1EA3nh l\xE0 b\u1EAFt bu\u1ED9c ",
  txt_create_new_post: "T\u1EA1o b\xE0i \u0111\u0103ng m\u1EDBi",
  txt_group_type: "Lo\u1EA1i nh\xF3m",
  txt_content: "N\u1ED9i dung",
  txt_disconnect: "Ng\u1EAFt k\u1EBFt n\u1ED1i",
  txt_failed: "Kh\xF4ng th\xE0nh c\xF4ng",
  txt_bulk_disconnect: "Ng\u1EAFt k\u1EBFt n\u1ED1i h\xE0ng lo\u1EA1t"
};

// src/translations/fr/common.json
var common_default8 = {
  txt_welcome_to: "Bienvenue \xE0 ",
  txt_sign_in_to_see_latest_updates: "Connectez-vous pour voir les derni\xE8res mises \xE0 jour.",
  txt_or_continue_with: "Ou Continuer avec",
  txt_email: "E-mail",
  txt_password: "Mot de passe",
  txt_remember_me: "Se souvenir de moi",
  txt_sign_in: "Connexion",
  txt_sign_in_with_sso: "Se connecter avec AesirX SSO",
  txt_sign_up: "S'inscrire",
  txt_do_not_have_an_account: "Vous n'avez pas de compte\xA0? Cr\xE9ez un compte gratuit",
  txt_welcome_to_our_aesirx: "Bienvenue dans notre AesirX - DMA.",
  txt_sign_up_to_getting_started: "Inscrivez-vous pour commencer.",
  txt_or_register_with: "Ou s'enregistrer avec",
  txt_username: "Nom d'utilisateur",
  txt_what_is_the_main_thing_you_want_to_manage: "Quelle est la chose principale que vous voulez g\xE9rer\xA0?",
  txt_you_agree_to_our: "En m'inscrivant, j'accepte le",
  txt_terms_of_service: "conditions d'utilisation",
  txt_privacy_policy: "politique de confidentialit\xE9",
  txt_and: "et",
  txt_already_have_an_account: "Vous avez d\xE9j\xE0 un compte\xA0? Connectez-vous",
  txt_email_already_exists: "L'e-mail existe d\xE9j\xE0",
  txt_already_exists: "Existe d\xE9j\xE0",
  txt_log_in: "Se connecter",
  txt_loading: "Chargement",
  txt_forgot_your_password: "Mot de passe oubli\xE9\xA0?",
  txt_well_email_you_instructions_on_how_to_reset_your_password: "Nous vous enverrons par e-mail des instructions pour r\xE9initialiser votre mot de passe",
  txt_reset_password: "R\xE9initialiser le mot de passe",
  txt_verify_your_email_address: "V\xE9rifiez votre adresse e-mail\xA0!",
  txt_a_verification_email_has_been_sent_to_your_email: "Un e-mail de v\xE9rification a \xE9t\xE9 envoy\xE9 \xE0 votre adresse e-mail",
  txt_Please_check_your_email: "Veuillez v\xE9rifier votre e-mail et v\xE9rifier le compte en cliquant sur le bouton {{characters}}Confirmer votre e-mail{{characters}} pour terminer l'enregistrement de votre compte.",
  txt_if_you_do_not_receive_the_email: "Si vous ne recevez pas l'e-mail, cliquez sur le bouton ci-dessous pour renvoyer l'e-mail de v\xE9rification.",
  txt_resend_email: "Renvoyer l'e-mail",
  txt_search_for_something: "Rechercher quelque chose...",
  txt_recent: "R\xE9cent",
  txt_view_all_projects: "Afficher tous les projets",
  txt_search_projects: "Rechercher des projets ...",
  txt_create_a_new_project: "Cr\xE9er un nouveau projet",
  txt_all_projects: "Tous les projets",
  txt_help_center: "centre d'aide",
  txt_sign_out: "D\xE9connexion",
  txt_notifications: "Notifications",
  txt_mark_all_as_read: "Marquer tout comme lu",
  txt_internal: "Interne",
  txt_client: "Client",
  txt_task_complete: "T\xE2che termin\xE9e",
  txt_more: "Plus",
  txt_projects: "Projets",
  txt_assigned_to_me: "Attribu\xE9 \xE0 moi",
  txt_campaigns: "Campagnes",
  txt_marketing_automation_made_easy: "L'automatisation du marketing, simplifi\xE9e\xA0!",
  txt_we_have_created_a_step_by_step_process: "Nous avons cr\xE9\xE9 un processus \xE9tape par \xE9tape qui vous guidera sur la fa\xE7on de commencer.",
  txt_social: "M\xE9dias sociaux",
  txt_cms: "CMS",
  txt_mail: "Courrier",
  txt_title_calendar: "Planifier",
  txt_title_projects: "Projets",
  txt_title_campaigns: "Liste des campagnes",
  txt_title_content: "Liste du projet",
  txt_title_channels: "Connecter une cha\xEEne",
  txt_title_digital_assets: "Actifs num\xE9riques",
  txt_title_new_calendar: "Nouvel horaire",
  txt_menu_calendar: "Calendrier",
  txt_menu_projects: "Projets",
  txt_menu_campaigns: "Campagnes",
  txt_menu_content: "Contenu",
  txt_menu_channels: "Cha\xEEnes",
  txt_menu_digital_assets: "Actifs num\xE9riques",
  txt_today: "Aujourd'hui",
  txt_general_information: "Informations g\xE9n\xE9rales",
  txt_projectpage_password: "Mot de passe",
  txt_profile_picture: "Photo de profil",
  txt_list_post: "Liste des messages",
  txt_list_campaigns: "Liste des campagnes",
  txt_connect_a_channel: "Connecter un canal",
  txt_back_to_dashboard: "Retour au tableau de bord",
  txt_menu_profile: "Profil",
  txt_Username: "Nom d'utilisateur",
  txt_Email: "E-mail",
  txt_Status: "Statut",
  txt_Birthday: "Anniversaire",
  txt_Phone: "T\xE9l\xE9phone",
  txt_Address_1: "Adresse 1",
  txt_Address_2: "Adresse 2",
  txt_State: "\xC9tat",
  txt_Country: "Pays",
  txt_Zipcode: "Code postal",
  txt_Fullname: "Nom complet",
  txt_City: "Ville",
  txt_current_password: "Mot de passe actuel",
  txt_confirm_password: "Confirmer le nouveau mot de passe",
  txt_new_password: "Nouveau mot de passe",
  txt_update: "Mettre \xE0 jour",
  delete: "Supprimer",
  choose_an_action: "Choisissez une action",
  txt_create_project: "Cr\xE9er un projet",
  txt_create_post: "Cr\xE9er un article",
  txt_create_campaigns: "Cr\xE9er des campagnes",
  create_a_new_persona: "Cr\xE9er un nouveau personnage",
  txt_coming_soon: "Bient\xF4t disponible",
  txt_filter: "Filtre",
  txt_clear_all: "Tout effacer",
  txt_select_all: "Tout s\xE9lectionner",
  txt_deselect_all: "D\xE9s\xE9lectionner tout",
  txt_columns: "Colonnes",
  txt_list: "Liste",
  txt_thumb: "Pouce",
  search_your_project: "Rechercher votre projet",
  search_your_post: "Rechercher votre message",
  search_your_campaign: "Recherchez votre campagne",
  search_your_personas: "Cherchez vos personas",
  txt_project_name: "Nom du projet",
  txt_lead: "Prospect",
  txt_peter_stanbridge: "Peter Stanbridge",
  txt_show_more: "Montre plus",
  start_date: "Date de d\xE9but",
  end_date: "Date de fin",
  created_date: "Date de cr\xE9ation",
  txt_short_description: "Description courte",
  txt_short_description_about_project: "Br\xE8ve description du projet",
  txt_project_logo: "Logo du projet",
  txt_edit_project: "Modifier le projet",
  txt_save_project: "Enregistrer le projet",
  txt_budget: "Budget",
  txt_campaign_name: "Nom de la campagne",
  create_campaign: "Cr\xE9er une campagne",
  edit_campaign: "Modifier la campagne",
  save_campaign: "Enregistrer la campagne",
  txt_status: "Statut",
  create_your_1st_campaigns: "Cr\xE9ez vos 1\xE8res campagnes",
  create_your_1st_project: "Cr\xE9ez votre 1er projet",
  create_your_1st_persona: "cr\xE9ez votre 1er personnage",
  txt_name_personas: "Nom",
  updated_date: "Date de mise \xE0 jour",
  connected_channels: "Cha\xEEnes connect\xE9es",
  txt_title: "Titre",
  txt_channels: "Cha\xEEnes",
  txt_date: "Date",
  txt_edit: "Modifier",
  txt_general: "G\xE9n\xE9ral",
  txt_choose_the_project: "Choisissez le projet",
  txt_choose_the_campaign: "Choisissez la campagne",
  txt_headline: "Titre",
  txt_add_channels: "Ajouter des cha\xEEnes",
  txt_save: "Enregistrer",
  txt_connected_channels: "Cha\xEEnes connect\xE9es",
  content_description: "Description du contenu",
  txt_advance_mode: "Mode avanc\xE9",
  txt_back: "Retour",
  txt_next: "Suivant",
  txt_connecting: "Connexion",
  txt_connect: "Se connecter",
  txt_enable: "Activer",
  txt_remove: "Supprimer",
  txt_type: "Type",
  txt_action: "Action",
  txt_profile: "Profil",
  txt_days: "\xA0jours",
  txt_apply: "Appliquer",
  txt_0_days: "0 jours",
  txt_week: "semaine",
  txt_month: "mois",
  txt_agenda: "ordre du jour",
  txt_nopost_agenda: "Il n'y a aucun \xE9v\xE9nement dans cette plage.",
  txt_video: "Vid\xE9o",
  txt_digital_asset_management: "Gestion des actifs num\xE9riques",
  txt_digital_assets: "Actif num\xE9rique",
  txt_publish: "Publier",
  "txt_when_to_publish_this?": "Quand publier ceci ?",
  txt_save_as_draft: "Enregistrer comme brouillon",
  txt_choose_file: "Choisir un fichier",
  "txt_select...": "S\xE9lectionner...",
  txt_project: "Projet",
  txt_post_now: "Publier maintenant",
  txt_schedule: "Planifier",
  "txt_publish_date/time": "Date/heure de publication",
  txt_update_successfully: "Mise \xE0 jour r\xE9ussie",
  txt_update_unsuccessfully: "\xC9chec de la mise \xE0 jour",
  txt_Please_upgrade_account: "Veuillez mettre \xE0 jour le compte",
  txt_Please_connect_a_channel: "Veuillez connecter un canal",
  txt_the_video_field_is_required: "Le champ vid\xE9o est obligatoire",
  txt_logo_picture: "Logo",
  txt_canva_api_key_warning: "Veuillez remplacer REACT_APP_CANVA_API_KEY ou le bouton Canva ne fonctionne que sur l'h\xF4te local",
  txt_running: "En cours d'ex\xE9cution",
  txt_stop: "Arr\xEAter",
  txt_sign_in_to_getting_started: "Connectez-vous pour commencer.",
  txt_warning: "Attention",
  txt_successful: "R\xE9ussi",
  txt_error: "Erreur",
  txt_forgot_password: "Mot de passe oubli\xE9\xA0?",
  txt_title_no_matching_results: "Aucun r\xE9sultat correspondant",
  txt_text_no_matching_results: "Impossible de trouver un projet avec ce mot-cl\xE9. Veuillez essayer un autre mot-cl\xE9.",
  txt_maintenance: "Maintenance",
  txt_all_dates: " Toutes les dates",
  txt_api_error: "Une erreur s'est produite. Veuillez r\xE9essayer plus tard.",
  txt_intro_chatgpt: "Bonjour\xA0! Comment puis-je vous aider aujourd'hui\xA0?",
  txt_open_api_key_warning: "Veuillez saisir les cl\xE9s API de https://platform.openai.com/account/api-keys",
  txt_connect_channel: "Connecter le canal",
  txt_create_content: "Cr\xE9er du contenu",
  txt_delete_folder: "Supprimer un dossier",
  txt_are_you_sure: "\xCAtes-vous certain.e?",
  txt_delete_assets_popup_desc: "\xCAtes-vous s\xFBr de vouloir supprimer cet actif?",
  txt_Cancel: "Abandonner",
  txt_yes_delete: "Oui, supprimez",
  txt_description_limit: " La description d\xE9passe les caract\xE8res autoris\xE9s ",
  txt_headline_limit: " Le titre d\xE9passe les caract\xE8res autoris\xE9s ",
  txt_the_media_field_is_required: " Le champ m\xE9dia est obligatoire ",
  txt_the_image_field_is_required: " Le champ image est obligatoire ",
  txt_create_new_post: "Cr\xE9er un nouveau message",
  txt_group_type: "Type de groupe",
  txt_content: "Contenu",
  txt_disconnect: "D\xE9connecter",
  txt_failed: "\xC9chec",
  txt_bulk_disconnect: "D\xE9connexion group\xE9e"
};

// src/translations/index.js
var appLanguages = {
  en: common_default2,
  da: common_default,
  vi: common_default7,
  th: common_default5,
  hr: common_default4,
  uk: common_default6,
  es: common_default3,
  fr: common_default8
};
export {
  appLanguages,
  integrationMenu,
  integrationRoutes
};
