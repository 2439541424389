import {
  PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY
} from "./chunk-V7IZ3LDX.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Pim/PimPropertyValue/Model.ts
var PropertyValueModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new PropertyValueItemModel(element);
      });
    }
  }
};
var PropertyValueItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.title = null;
    this.published = 0;
    this.featured = 0;
    this.created_user_name = null;
    this.modified_user_name = null;
    this.publish_up = null;
    this.modified_time = null;
    this.created_time = null;
    this.custom_fields = null;
    this.properties = null;
    this.toObject = () => {
      return {};
    };
    this.isJsonString = (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.ID]: this.id,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.TITLE]: this.title,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.PUBLISHED]: this.published,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.FEATURED]: this.featured,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CREATED_TIME]: this.created_time,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CREATED_USER_NAME]: this.created_user_name,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.MODIFIED_USER_NAME]: this.modified_user_name,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.PUBLISH_UP]: this.publish_up,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.MODIFIED_TIME]: this.modified_time,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]: this.custom_fields,
        [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.PROPERTIES]: this.properties
      };
    };
    if (entity) {
      this.id = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.ID] ?? "";
      this.title = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.TITLE] ?? "";
      this.published = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.PUBLISHED] ?? 0;
      this.featured = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.FEATURED] ?? 0;
      this.created_time = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.created_user_name = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CREATED_USER_NAME] ?? "";
      this.modified_user_name = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.MODIFIED_USER_NAME] ?? "";
      this.publish_up = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.PUBLISH_UP] ?? "";
      this.modified_time = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.MODIFIED_TIME] ?? "";
      this.custom_fields = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] ?? "";
      this.properties = entity[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.PROPERTIES] ?? "";
    }
  }
};
PropertyValueItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [
    PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.ID,
    PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS
  ];
  Object.keys(PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]) && data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]]) {
      formData.append(
        PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index],
        data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]]
      );
    }
  });
  if (data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] && Object.keys(data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
    Object.keys(data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).forEach(function(key) {
      if (Array.isArray(data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key])) {
        data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key].map(
          (field, index) => {
            if (typeof field === "object" && field !== null && !Array.isArray(field)) {
              Object.keys(field).forEach(function(fieldKey) {
                return formData.append(
                  [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] + `[${key}][${index}][${fieldKey}]`,
                  field[fieldKey]
                );
              });
            } else {
              return formData.append(
                [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] + "[" + key + "][" + index + "]",
                field
              );
            }
          }
        );
      } else {
        formData.append(
          [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] + "[" + key + "]",
          data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key]
        );
      }
    });
  }
  return formData;
};
PropertyValueItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS];
  Object.keys(PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]) && data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]]) {
      formData[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]] = data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] && Object.keys(data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
    formData[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS] = {};
    Object.keys(data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).forEach(function(key) {
      formData[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key] = data[PIM_PROPERTY_VALUE_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key];
    });
  }
  return formData;
};

export {
  PropertyValueModel,
  PropertyValueItemModel
};
