// src/components/Form/FormCheckbox/index.tsx
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
var FormCheckbox = ({ field }) => {
  const [selectedValue, setSelectedValue] = useState(field.getValueSelected?.value ?? []);
  useEffect(() => {
    if (field.getValueSelected?.value) {
      setSelectedValue(field.getValueSelected?.value);
    }
  }, [field.getValueSelected?.value]);
  useEffect(() => {
    field.handleChange(selectedValue);
  }, [selectedValue]);
  const handleChange = (data) => {
    if (data.target.checked) {
      setSelectedValue((current) => [...current, data.target.value]);
    } else {
      setSelectedValue((current) => current.filter((item) => item !== data.target.value));
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center w-100 flex-wrap" }, field.getDataSelectOptions?.map(
    (option, key) => option.label && /* @__PURE__ */ React.createElement(
      Form.Check,
      {
        key: field.key + key,
        className: `mb-0 ${option.className}`,
        inline: true,
        label: option.label,
        value: option.value,
        name: field.key,
        type: "checkbox",
        id: `inline-radio-${field.key}-${option.value}`,
        onChange: handleChange,
        onBlur: field?.blurred,
        checked: selectedValue?.includes(option.value)
      }
    )
  ), field?.isCheckAll && /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center w-100 mt-2" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "success",
      className: "mx-1 py-1",
      onClick: () => {
        setSelectedValue(field.getDataSelectOptions.map((item) => item?.value));
      }
    },
    "Check All"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "danger",
      className: "mx-1 py-1",
      onClick: () => {
        setSelectedValue("");
      }
    },
    "Clear All"
  )));
};

export {
  FormCheckbox
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
