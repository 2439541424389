// src/routes/routes.js
import React, { lazy } from "react";
import { LoginPage, ProfilePage, DigitalAssetsPage, history } from "aesirx-uikit";
var CalendarPage = lazy(() => import("./CalendarPage-NN242ROX.js"));
var Projects = lazy(() => import("./ProjectsPage-E2D5ACXO.js"));
var CampaignsPage = lazy(() => import("./CampaignsPage-XVMGATAX.js"));
var ContentPage = lazy(() => import("./ContentPage-J74VRNAJ.js"));
var WizardPage = lazy(() => import("./WizardPage-74NFIEE6.js"));
var ChannelsPage = lazy(() => import("./ChannelsPage-2I4Y5U6G.js"));
var WelcomePage = lazy(() => import("./WelcomePage-MXCFI6FM.js"));
var mainRoutes = [
  {
    path: "/",
    exact: true,
    main: () => /* @__PURE__ */ React.createElement(CalendarPage, null)
  },
  {
    path: [
      "/channel",
      "/wizard/createproject",
      "/wizard/project/:id",
      "/wizard/project/:id/content",
      "/wizard/:id/content",
      "/wizard/content"
    ],
    exact: true,
    main: ({ match, location }) => /* @__PURE__ */ React.createElement(WizardPage, { match, location })
  },
  {
    path: "/projects",
    exact: false,
    main: ({ match, location }) => /* @__PURE__ */ React.createElement(Projects, { match, location })
  },
  {
    path: "/campaigns",
    exact: false,
    main: ({ match, location }) => /* @__PURE__ */ React.createElement(CampaignsPage, { match, location })
  },
  {
    path: "/channels",
    exact: false,
    main: ({ match, location }) => /* @__PURE__ */ React.createElement(ChannelsPage, { match, location })
  },
  {
    path: "/calendar",
    exact: false,
    main: ({ match, location }) => /* @__PURE__ */ React.createElement(CalendarPage, { match, location })
  },
  {
    path: ["/content", "/content/create", "/content-edit/:categoryId", "/content/:id"],
    exact: true,
    main: ({ match, location }) => /* @__PURE__ */ React.createElement(ContentPage, { match, location })
  },
  {
    path: "/digital-assets",
    exact: false,
    main: () => /* @__PURE__ */ React.createElement(DigitalAssetsPage, null)
  }
];
var integrationRoutes = () => mainRoutes.filter((item) => item.path !== "/digital-assets").map((item) => {
  if (Array.isArray(item.path)) {
    item.path = item.path.map((path) => "/dma" + path);
  } else {
    item.path = "/dma" + item.path;
  }
  return item;
});
var historyPush = (link) => {
  return history.push((process.env.REACT_APP_INTERGRATION ? "/dma" : "") + link);
};
var linkPush = (link) => {
  return (process.env.REACT_APP_INTERGRATION ? "/dma" : "") + link;
};

export {
  integrationRoutes,
  historyPush,
  linkPush
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
