import {
  RoleDetailViewModel_default
} from "./chunk-KAEI2J4I.js";
import {
  RoleListViewModel_default
} from "./chunk-KNY25XFD.js";
import {
  __publicField
} from "./chunk-G2CEMTG6.js";

// src/pages/Roles/RoleViewModel/RoleViewModel.ts
var RoleViewModel = class {
  constructor(roleStore) {
    __publicField(this, "roleDetailViewModel", {});
    __publicField(this, "roleListViewModel", {});
    __publicField(this, "getRoleDetailViewModel", () => this.roleDetailViewModel);
    __publicField(this, "getRoleListViewModel", () => this.roleListViewModel);
    if (roleStore) {
      this.roleDetailViewModel = new RoleDetailViewModel_default(roleStore);
      this.roleListViewModel = new RoleListViewModel_default(roleStore);
    }
  }
};
var RoleViewModel_default = RoleViewModel;

export {
  RoleViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
