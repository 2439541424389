import {
  ListMember_default
} from "./chunk-7GL2QW37.js";
import {
  MemberViewModelContextProvider
} from "./chunk-JLI5XK4P.js";

// src/pages/Members/index.tsx
import React from "react";
import { observer } from "mobx-react";
var MembersPage = observer(
  class MembersPage2 extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return /* @__PURE__ */ React.createElement("div", { className: "px-3 py-4" }, /* @__PURE__ */ React.createElement(MemberViewModelContextProvider, null, /* @__PURE__ */ React.createElement(ListMember_default, null)));
    }
  }
);

export {
  MembersPage
};
