// src/constants/PageStatus.js
var PAGE_STATUS = {
  LOADING: 1,
  READY: 2,
  ERROR: 3
};
var PageStatus_default = PAGE_STATUS;

export {
  PageStatus_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
