import {
  BrowsersModel,
  CitiesModel,
  ConsentsDateModel,
  ConsentsListModel,
  ConsentsTierModel,
  CountriesModel,
  DevicesModel,
  DomainModel,
  EventsModel,
  FlowItemModel,
  LanguagesModel,
  MetricsModel,
  PagesModel,
  RefererModel,
  SummaryModel,
  VisitorModel,
  VisitorsModel,
  VisitsModel,
  WoocommerceProductChartModel,
  WoocommerceProductModel,
  WoocommerceStatisticChartModel,
  WoocommerceStatisticModel
} from "./chunk-3LFBGLUG.js";
import {
  BiRoute_default
} from "./chunk-VP2EK2LB.js";

// src/Bi/Bi.ts
import axios from "axios";
var AesirxBiApiService = class {
  constructor() {
    this.route = null;
    this.getFlowDetail = async (flowId, dataFilter) => {
      try {
        const data = await this.route.getFlowDetail(flowId, dataFilter);
        let results = null;
        if (data) {
          results = new FlowItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getAttribute = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getAttribute(dataFilter, dateFilter);
        if (data?.collection) {
          return data.collection;
        } else {
          return null;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getAttributeDate = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getAttributeDate(dataFilter, dateFilter);
        if (data?.collection) {
          return data.collection;
        } else {
          return null;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getListDomain = async (dataFilter, listDomains) => {
      try {
        const data = await this.route.getListDomain(dataFilter, listDomains);
        let results = null;
        if (data) {
          results = new DomainModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getVisitors = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getVisitors(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new VisitorsModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getVisitor = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getVisitor(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new VisitorModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getVisits = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getVisits(dataFilter, dateFilter);
        let results = null;
        if (data) {
          results = new VisitsModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getSummary = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getSummary(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new SummaryModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getMetrics = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getMetrics(dataFilter, dateFilter);
        let results = null;
        if (data) {
          results = new MetricsModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getDevices = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getDevices(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new DevicesModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getCountries = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getCountries(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new CountriesModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getCities = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getCities(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data?.collection) {
          results = new CitiesModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getBrowsers = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getBrowsers(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data?.collection) {
          results = new BrowsersModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getLanguages = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getLanguages(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data?.collection) {
          results = new LanguagesModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getPages = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getPages(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data?.collection) {
          results = new PagesModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (true) {
          return error;
        }
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getEvents = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getEvents(dataFilter, dateFilter);
        let results = null;
        if (data) {
          results = new EventsModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getWoocommerceStatistic = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getWoocommerceStatistic(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new WoocommerceStatisticModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getWoocommerceStatisticChart = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getWoocommerceStatisticChart(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new WoocommerceStatisticChartModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getWoocommerceProduct = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getWoocommerceProduct(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new WoocommerceProductModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getWoocommerceProductChart = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getWoocommerceProductChart(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new WoocommerceProductChartModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getConsentsList = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getConsentsList(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new ConsentsListModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getConsentsDate = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getConsentsDate(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new ConsentsDateModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getConsentsTier = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getConsentsTier(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new ConsentsTierModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.getReferer = async (dataFilter, dateFilter) => {
      try {
        const data = await this.route.getReferer(dataFilter, dateFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new RefererModel(data);
          pagination = results.getBiPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results,
          pagination
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancle" };
        } else
          throw error;
      }
    };
    this.route = new BiRoute_default();
  }
};

export {
  AesirxBiApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
