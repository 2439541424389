import {
  PlanningFilterModel,
  PlanningModel
} from "./chunk-H3QK77FB.js";
import {
  PlanningRoute_default
} from "./chunk-AVA466LE.js";

// src/Planning/Planning.ts
var AesirxPlanningApiService = class {
  constructor() {
    this.route = null;
    this.route = new PlanningRoute_default();
  }
  /**
   * Get 20 first campaign are sorted by ID
   *  */
  async getPlannings(page = 1, limit = 20, returnAsJSON = true) {
    try {
      const data = await this.route.getPlanningListRequest(page, limit);
      let results = null;
      let pagination = null;
      if (data) {
        results = new PlanningModel(data);
        pagination = results.getPagination();
      }
      if (results && returnAsJSON) {
        results = results.toJSON();
      }
      return {
        list: results,
        pagination
      };
    } catch (error) {
      return null;
    }
  }
  /**
   * Search Planning
   */
  async searchPlanning(dataFilter = {}, page = 1, limit = 20, returnAsJSON = true) {
    try {
      const data = await this.route.searchPlanningRequest(dataFilter, page, limit);
      let results = null;
      let pagination = null;
      if (data) {
        results = new PlanningFilterModel(data);
        pagination = results.getPagination();
      }
      if (results && returnAsJSON) {
        results = results.toJSON();
      }
      return {
        list: results,
        pagination
      };
    } catch (error) {
      return null;
    }
  }
};

export {
  AesirxPlanningApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
