// src/utils/index.tsx
import axios from "axios";
var shortenString = (str, first = 6, last = 4) => {
  return str?.substring(0, first) + "..." + str?.substring(str.length - last);
};
var getClientApp = () => {
  console.log("process.env", process.env);
  const endpoint = window["aesirxEndpoint"] ? window["aesirxEndpoint"] : process.env.REACT_APP_ENDPOINT_URL || process.env.NEXT_PUBLIC_ENDPOINT_URL || "https://api.aesirx.io";
  const client_id = window["aesirxClientID"] ? window["aesirxClientID"] : process.env.REACT_APP_SSO_CLIENT_ID || process.env.NEXT_PUBLIC_SSO_CLIENT_ID || "";
  const client_secret = window["aesirxClientSecret"] ? window["aesirxClientSecret"] : process.env.REACT_APP_SSO_CLIENT_SECRET || process.env.NEXT_PUBLIC_SSO_CLIENT_SECRET || "";
  const network = process.env.REACT_APP_SSO_CONCORDIUM_NETWORK || process.env.NEXT_PUBLIC_CONCORDIUM_NETWORK || "mainnet";
  const web3Endpoint = window["web3Endpoint"] ? window["web3Endpoint"] : process.env.REACT_APP_WEB3_API_ENDPOINT || process.env.NEXT_PUBLIC_WEB3_API_ENDPOINT || "https://web3id.backend.aesirx.io:8001";
  const dappEndpoint = window["dappEndpoint"] ? window["dappEndpoint"] : process.env.REACT_APP_WEB3_DAPP_URL || process.env.NEXT_PUBLIC_DAPP_URL || "https://dapp.shield.aesirx.io";
  const partnerEndpoint = window["partnerEndpoint"] ? window["partnerEndpoint"] : process.env.REACT_APP_PARTNERS_URL || process.env.NEXT_PUBLIC_PARTNERS_URL || "https://partners.aesirx.io";
  const registerForm = {
    username: window["registerUsername"] ? window["registerUsername"] : process.env.REACT_APP_USERNAME || process.env.NEXT_PUBLIC_USERNAME || 55,
    first_name: window["registerFirstname"] ? window["registerFirstname"] : process.env.REACT_APP_FIRSTNAME || process.env.NEXT_PUBLIC_FIRSTNAME || 53,
    last_name: window["registerLastname"] ? window["registerLastname"] : process.env.REACT_APP_LASTNAME || process.env.NEXT_PUBLIC_LASTNAME || 66,
    product: window["registerProduct"] ? window["registerProduct"] : process.env.REACT_APP_PRODUCT || process.env.NEXT_PUBLIC_PRODUCT || 54,
    email: window["registerEmail"] ? window["registerEmail"] : process.env.REACT_APP_EMAIL || process.env.NEXT_PUBLIC_EMAIL || 56,
    organization: window["registerOrganization"] ? window["registerOrganization"] : process.env.REACT_APP_ORGANIZATION || process.env.NEXT_PUBLIC_ORGANIZATION || 57,
    message: window["registerMessage"] ? window["registerMessage"] : process.env.REACT_APP_MESSAGE || process.env.NEXT_PUBLIC_MESSAGE || 58,
    order_id: window["registerOrderid"] ? window["registerOrderid"] : process.env.REACT_APP_ORDER_ID || process.env.NEXT_PUBLIC_ORDER_ID || 64,
    code: window["registerCode"] ? window["registerCode"] : process.env.REACT_APP_CODE || process.env.NEXT_PUBLIC_CODE || 65
  };
  return {
    endpoint,
    client_id,
    client_secret,
    network,
    web3Endpoint,
    dappEndpoint,
    registerForm,
    partnerEndpoint
  };
};
var getChallenge = async (walletAccount) => {
  const { web3Endpoint } = getClientApp();
  try {
    return (await axios.get(`${web3Endpoint}/challenge?account=${walletAccount}`)).data?.challenge;
  } catch (error) {
    console.log("getChallenge", error);
    throw error;
  }
};
var getStatement = async () => {
  const { web3Endpoint } = getClientApp();
  try {
    return (await axios.get(`${web3Endpoint}/statement`)).data;
  } catch (error) {
    console.log("getChallenge", error);
    throw error;
  }
};
var verifyProof = async (challenge, proof) => {
  const { web3Endpoint } = getClientApp();
  try {
    return (await axios.post(
      `${web3Endpoint}/prove`,
      {
        challenge,
        proof
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )).data?.result;
  } catch (error) {
    console.log("getChallenge", error);
    throw error;
  }
};
var validateWeb3Id = async (id) => {
  const { web3Endpoint } = getClientApp();
  try {
    return !(await axios.get(`${web3Endpoint}/preregistration/checkid/${id}`)).data.result;
  } catch (error) {
    return false;
  }
};
var validateEmail = async (email) => {
  const { endpoint, web3Endpoint } = getClientApp();
  try {
    const [validateOnWeb3id, validateOnAesirx] = await Promise.all([
      !(await axios.get(`${web3Endpoint}/preregistration/checkemail/${email}`)).data.result,
      !(await axios.post(
        `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&task=checkEmailIsUsed&api=hal`,
        {
          email
        }
      )).data.result
    ]);
    return validateOnWeb3id && validateOnAesirx;
  } catch (error) {
    return false;
  }
};
var createMember = async (bodyData) => {
  const { endpoint } = getClientApp();
  try {
    const url = `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&api=hal`;
    const rs = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyData)
    });
    console.log("createMember", bodyData);
    if (rs?.ok === false) {
      throw await rs.json();
    }
    return await rs.json();
  } catch (error) {
    console.log("createMember", error);
    throw error;
  }
};
var login = async (username, password) => {
  const { partnerEndpoint } = getClientApp();
  try {
    const url = `${partnerEndpoint}/api/auth/login`;
    const rs = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "same-origin",
      body: JSON.stringify({ username, password })
    });
    const data = await rs.json();
    return data;
  } catch (error) {
    console.log("login error", error);
    throw error;
  }
};
var autoRegisterWeb3id = async (data, clientJwt, signedNonce, walletAccount, isLinkWallet) => {
  const { dappEndpoint } = getClientApp();
  try {
    return await axios.post(`${dappEndpoint}/api/autocreate`, {
      data,
      clientJwt,
      signedNonce,
      walletAccount,
      isLinkWallet
    });
  } catch (error) {
    console.log("autoRegisterWeb3id", error);
    throw error;
  }
};
var mintWeb3ID = async (jwt) => {
  const { web3Endpoint } = getClientApp();
  try {
    return await axios.post(
      `${web3Endpoint}/aesirx/account/mint`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt
        }
      }
    );
  } catch (error) {
    console.log("mintWeb3ID", error);
    throw error;
  }
};
var linkAesirXAccount = async (web3id, jwt) => {
  const { web3Endpoint } = getClientApp();
  await axios.put(
    `${web3Endpoint}/preregistration/aesirx/${web3id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    }
  );
};
var checkWalletAccount = async (account, wallet) => {
  const { web3Endpoint } = getClientApp();
  try {
    return await axios.get(
      `${web3Endpoint}/preregistration/checkaccount/${account}?wallet=${wallet}`
    );
  } catch (error) {
    console.log("checkWalletAccount", error);
    throw error;
  }
};
var getPreregistration = async (jwt) => {
  const { web3Endpoint } = getClientApp();
  return await axios.get(`${web3Endpoint}/preregistration/aesirx`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt
    }
  });
};
var getMember = async (accessToken) => {
  const { endpoint } = getClientApp();
  try {
    const member = await axios.get(
      `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=persona&api=hal&task=getTokenByUser`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      }
    );
    if (member?.data?.result?.member_id) {
      const data = await axios.get(
        `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&api=hal&id=${member?.data?.result?.member_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      );
      return data?.data;
    }
  } catch (error) {
    console.log("getMember", error);
    throw error;
  }
};
var updateMember = async (bodyData, accessToken) => {
  const { endpoint } = getClientApp();
  try {
    const response = await axios.put(
      `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&api=hal`,
      bodyData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
var connectWallet = async (address, walletType, accessToken, userName) => {
  try {
    const { endpoint } = getClientApp();
    const response = await axios.post(
      `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&task=setWallet&api=hal`,
      {
        wallet: walletType,
        publicAddress: address,
        username: userName
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      }
    );
    return response?.data;
  } catch (error) {
    console.log("connectWalletError", error);
    throw error;
  }
};
var removeWallet = async (address, walletType, accessToken, userName) => {
  try {
    const { endpoint } = getClientApp();
    const response = await axios.post(
      `${endpoint}/index.php?webserviceClient=site&webserviceVersion=1.0.0&option=member&task=deleteWallet&api=hal`,
      {
        wallet: walletType,
        publicAddress: address,
        username: userName
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      }
    );
    return response?.data;
  } catch (error) {
    console.log("removeWalletError", error);
    throw error;
  }
};

// src/SSOButton/modal.tsx
import React, { Suspense } from "react";
import { CloseButton, Modal, ModalBody } from "react-bootstrap";
if (typeof window !== "undefined") {
  window.process = { env: "" };
}
var SSOModalContext = React.createContext(void 0);
var SSOProviders = React.lazy(() => import("./Providers-7KUOBLTA.js"));
var SSOModal = ({
  onGetData,
  show,
  toggle,
  demoUser,
  demoPassword,
  noCreateAccount,
  isSignUpForm = false
}) => {
  const handleOnData = (data) => {
    onGetData(data);
    toggle();
  };
  return /* @__PURE__ */ React.createElement("div", { className: "aesirxsso" }, /* @__PURE__ */ React.createElement(
    Modal,
    {
      centered: true,
      fade: false,
      show,
      onHide: () => {
        toggle();
      },
      size: "xl",
      className: "aesirxsso"
    },
    /* @__PURE__ */ React.createElement(CloseButton, { onClick: toggle }),
    /* @__PURE__ */ React.createElement(ModalBody, { className: "p-0 bg-white rounded-3" }, /* @__PURE__ */ React.createElement(Suspense, { fallback: /* @__PURE__ */ React.createElement(React.Fragment, null, "Loading...") }, /* @__PURE__ */ React.createElement(
      SSOModalContext.Provider,
      {
        value: {
          handleOnData,
          toggle,
          demoUser,
          demoPassword,
          noCreateAccount,
          isSignUpForm
        }
      },
      /* @__PURE__ */ React.createElement(SSOProviders, null)
    )))
  ));
};
var modal_default = SSOModal;

export {
  shortenString,
  getClientApp,
  getChallenge,
  getStatement,
  verifyProof,
  validateWeb3Id,
  validateEmail,
  createMember,
  login,
  autoRegisterWeb3id,
  mintWeb3ID,
  linkAesirXAccount,
  checkWalletAccount,
  getPreregistration,
  getMember,
  updateMember,
  connectWallet,
  removeWallet,
  SSOModalContext,
  modal_default
};
