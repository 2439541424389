import {
  ProfileGeneral
} from "./chunk-K55PHESA.js";
import {
  ProfileContextProvider
} from "./chunk-DCIK3QWR.js";

// src/pages/Profile/index.tsx
import React from "react";
import { useTranslation } from "react-i18next";
var ProfilePage = () => {
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement(ProfileContextProvider, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "d-flex flex-row justify-content-between py-4 px-3" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", { className: "text-body" }, t("txt_title_profile_setting")), /* @__PURE__ */ React.createElement("span", { className: "text-body" }, t("txt_title_set_information_name")))), /* @__PURE__ */ React.createElement("div", { className: "py-4 px-3 bg-white mx-3 rounded-3" }, /* @__PURE__ */ React.createElement("div", { className: "w-80-percent" }, /* @__PURE__ */ React.createElement("h2", { className: "text-body mb-3" }, t("txt_general_information")), /* @__PURE__ */ React.createElement(ProfileGeneral, null)))));
};

export {
  ProfilePage
};
