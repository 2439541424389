import {
  PIM_DASH_BOARD_DETAIL_FIELD_KEY
} from "./chunk-V7IZ3LDX.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Pim/PimDashboard/PimDashboardModel.ts
var DashboardModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.total_product = null;
    this.total_categories = null;
    this.percent_new_product = null;
    this.percent_new_categories = null;
    this.percent_product_publish = null;
    this.percent_product_unPublish = null;
    this.percent_product_draft = null;
    this.percent_product_Archived = null;
    this.percent_product_trash = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.TOTAL_PRODUCT]: this.total_product,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.TOTAL_CATEGORIES]: this.total_categories,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_NEW_PRODUCT]: this.percent_new_product,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_NEW_CATEGORIES]: this.percent_new_categories,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_PUBLISH]: this.percent_product_publish,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_UNPUBLISH]: this.percent_product_unPublish,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_DRAFT]: this.percent_product_draft,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_ARCHIVED]: this.percent_product_Archived,
        [PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_TRASH]: this.percent_product_trash
      };
    };
    if (entity) {
      this.total_product = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.TOTAL_PRODUCT] ?? "";
      this.total_categories = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.TOTAL_CATEGORIES] ?? "";
      this.percent_new_product = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_NEW_PRODUCT] ?? "";
      this.percent_new_categories = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_NEW_CATEGORIES] ?? "";
      this.percent_product_publish = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_PUBLISH] ? parseInt(entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_PUBLISH]) : "";
      this.percent_product_unPublish = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_UNPUBLISH] ? parseInt(entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_UNPUBLISH]) : "";
      this.percent_product_draft = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_DRAFT] ? parseInt(entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_DRAFT]) : "";
      this.percent_product_Archived = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_ARCHIVED] ? parseInt(entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_ARCHIVED]) : "";
      this.percent_product_trash = entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_TRASH] ? parseInt(entity[PIM_DASH_BOARD_DETAIL_FIELD_KEY.PERCENT_PRODUCT_TRASH]) : "";
    }
  }
};

export {
  DashboardModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
