import {
  MemberRoleInformation_default
} from "./chunk-GRQL7BDQ.js";
import {
  withMemberRoleViewModel
} from "./chunk-CYJ5V2E6.js";
import {
  EditHeader
} from "./chunk-ZVOJGXKE.js";
import {
  A as A2
} from "./chunk-CCC3TLEH.js";
import {
  A
} from "./chunk-TSTKR7BL.js";
import {
  Spinner
} from "./chunk-POLRV4GK.js";
import {
  A as A3
} from "./chunk-NNGRNXS6.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";
import {
  __publicField
} from "./chunk-G2CEMTG6.js";

// src/pages/MemberRole/MemberRoleEdit/index.tsx
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { MEMBER_ROLE_FIELD } from "aesirx-lib";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";
var EditMemberRole = observer(
  class EditMemberRole2 extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "memberRoleDetailViewModel", null);
      __publicField(this, "validator");
      __publicField(this, "formPropsData", { [MEMBER_ROLE_FIELD.CUSTOM_FIELDS]: {}, [MEMBER_ROLE_FIELD.STATE]: 1 });
      __publicField(this, "isEdit", false);
      __publicField(this, "debouncedChangeHandler", _.debounce((value) => {
        this.memberRoleDetailViewModel.handleAliasChange(value);
      }, 300));
      this.state = {};
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.memberRoleDetailViewModel = props.model?.memberRoleDetailViewModel ? props.model?.memberRoleDetailViewModel : null;
      this.memberRoleDetailViewModel.setForm(this);
      this.isEdit = props.match.params?.id ? true : false;
    }
    async componentDidMount() {
      const { match } = this.props;
      if (this.isEdit) {
        this.formPropsData[MEMBER_ROLE_FIELD.ID] = match.params?.id;
        await this.memberRoleDetailViewModel.initializeData();
      }
    }
    handleValidateForm() {
      if (this.validator.fields["MemberRole Name"] === true) {
        this.setState((prevState) => {
          return {
            ...prevState,
            requiredField: Math.random()
          };
        });
      }
      this.validator.showMessages();
    }
    render() {
      const { t, history } = this.props;
      return /* @__PURE__ */ React.createElement("div", { className: "py-4 px-3 h-100 d-flex flex-column" }, this.memberRoleDetailViewModel.formStatus === PAGE_STATUS.LOADING && /* @__PURE__ */ React.createElement(Spinner, { className: "spinner-overlay" }), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center justify-content-between mb-24 flex-wrap" }, /* @__PURE__ */ React.createElement(
        EditHeader,
        {
          props: this.props,
          title: t("txt_member_role"),
          isEdit: this.isEdit,
          redirectUrl: "/member-role"
        }
      ), /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement(
        A3,
        {
          buttons: [
            {
              title: t("txt_cancel"),
              handle: async () => {
                history.push(`/member-role`);
              },
              icon: "/assets/images/cancel.svg"
            },
            {
              title: t("txt_save_close"),
              handle: async () => {
                if (this.validator.allValid()) {
                  const result = this.isEdit ? await this.memberRoleDetailViewModel.update() : await this.memberRoleDetailViewModel.create();
                  if (!result?.error) {
                    history.push(`/member-role`);
                  }
                } else {
                  this.handleValidateForm();
                }
              }
            },
            {
              title: t("txt_save"),
              validator: this.validator,
              handle: async () => {
                if (this.validator.allValid()) {
                  if (this.isEdit) {
                    await this.memberRoleDetailViewModel.update();
                    await this.memberRoleDetailViewModel.initializeData();
                    this.forceUpdate();
                  } else {
                    const result = await this.memberRoleDetailViewModel.create();
                    if (!result?.error) {
                      history.push(`/member-role/edit/${result?.response}`);
                    }
                  }
                } else {
                  this.handleValidateForm();
                }
              },
              icon: "/assets/images/save.svg",
              variant: "success"
            }
          ]
        }
      ))), /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(Row, { className: "gx-24 mb-24" }, /* @__PURE__ */ React.createElement(Col, { lg: 9 }, /* @__PURE__ */ React.createElement(Form.Group, { className: `mb-24` }, /* @__PURE__ */ React.createElement(
        A,
        {
          field: {
            getValueSelected: this.memberRoleDetailViewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.NAME],
            classNameInput: "py-10 fs-4",
            placeholder: t("txt_add_member_role_name"),
            handleChange: (event) => {
              this.memberRoleDetailViewModel.handleFormPropsData(
                MEMBER_ROLE_FIELD.NAME,
                event.target.value
              );
            },
            required: true,
            validation: "required",
            blurred: () => {
              this.validator.showMessageFor("MemberRole Name");
            }
          }
        }
      ), this.validator.message(
        "MemberRole Name",
        this.memberRoleDetailViewModel.memberRoleDetailViewModel.formPropsData[MEMBER_ROLE_FIELD.NAME],
        "required",
        {
          className: "text-danger mt-8px"
        }
      )), /* @__PURE__ */ React.createElement(
        MemberRoleInformation_default,
        {
          validator: this.validator,
          messagesShown: this.validator.messagesShown,
          isEdit: this.isEdit,
          formPropsData: this.memberRoleDetailViewModel.memberRoleDetailViewModel.formPropsData,
          ...this.props
        }
      )), /* @__PURE__ */ React.createElement(Col, { lg: 3 }, /* @__PURE__ */ React.createElement(
        A2,
        {
          detailViewModal: this.memberRoleDetailViewModel,
          formPropsData: this.memberRoleDetailViewModel.memberRoleDetailViewModel.formPropsData,
          isEdit: this.isEdit,
          isFeatured: false,
          isPublishedSimple: true
        }
      )))));
    }
  }
);
var MemberRoleEdit_default = withTranslation()(withRouter(withMemberRoleViewModel(EditMemberRole)));

export {
  MemberRoleEdit_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
