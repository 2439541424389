import {
  AesirXDamFormModel_default
} from "./chunk-LEHBBMBV.js";
import {
  Dropzone_default,
  index_module_default,
  withDamViewModel
} from "./chunk-JIKNAPG2.js";
import {
  __publicField
} from "./chunk-KMAXXKJT.js";

// src/integration/lib/AesirXDamForm/AesirXDamActionBar.jsx
import React3, { Component } from "react";
import { faFolder as faFolder2 } from "@fortawesome/free-regular-svg-icons/faFolder";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { DAM_ASSETS_API_FIELD_KEY, DAM_COLLECTION_API_RESPONSE_FIELD_KEY } from "aesirx-lib";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

// src/components/Breadcrumbs/index.jsx
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React2, { useCallback, useEffect, useState } from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { useTranslation } from "react-i18next";

// src/svg/Dot.jsx
import React from "react";
var Dot = (props) => {
  return /* @__PURE__ */ React.createElement(
    "svg",
    {
      ...props,
      width: "24px",
      height: "24px",
      viewBox: "0 0 24 24",
      focusable: "false",
      fill: "currentColor",
      ...props
    },
    /* @__PURE__ */ React.createElement("path", { d: "M0 0h24v24H0z", fill: "none" }),
    /* @__PURE__ */ React.createElement("path", { d: "M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" })
  );
};
var Dot_default = Dot;

// src/components/Breadcrumbs/index.jsx
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
var BreadCrumbs = ({ data = [], handleLink }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  if (!data) {
    return;
  }
  const handleClickOutside = useCallback(
    (e) => {
      const checkBreadCrumbClick = e.target.closest(".breadcrumb_item");
      if (!checkBreadCrumbClick && show) {
        setShow(false);
      }
    },
    [show]
  );
  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);
  return /* @__PURE__ */ React2.createElement("div", { className: `text-gray-900 fs-2 mb-0 fw-medium d-flex flex-wrap align-items-center` }, /* @__PURE__ */ React2.createElement("span", { className: "text-body mb-0 cursor-pointer", onClick: () => handleLink("/root") }, t("txt_my_assets")), data.length <= 2 ? data.map((_breadcrumb, index) => {
    if (index < 2) {
      return _breadcrumb?.name && /* @__PURE__ */ React2.createElement("span", { key: _breadcrumb?.id }, /* @__PURE__ */ React2.createElement(FontAwesomeIcon, { size: "2xs", className: "text-green px-3", icon: faAngleRight }), /* @__PURE__ */ React2.createElement(
        "span",
        {
          className: "text-body cursor-pointer",
          onClick: () => {
            handleLink(_breadcrumb?.id);
          }
        },
        _breadcrumb.name
      ));
    }
    return /* @__PURE__ */ React2.createElement("span", { key: index, className: index_module_default.dot });
  }) : data.length >= 3 ? /* @__PURE__ */ React2.createElement(React2.Fragment, null, /* @__PURE__ */ React2.createElement("span", null, /* @__PURE__ */ React2.createElement(FontAwesomeIcon, { size: "2xs", className: "text-green px-3", icon: faAngleRight }), /* @__PURE__ */ React2.createElement("span", { className: "text-body cursor-pointer", onClick: () => handleLink(data[0]?.id) }, data[0].name), /* @__PURE__ */ React2.createElement(FontAwesomeIcon, { size: "2xs", className: "text-green px-3", icon: faAngleRight })), /* @__PURE__ */ React2.createElement("div", { className: `position-relative  cursor-pointer ` }, /* @__PURE__ */ React2.createElement(
    "span",
    {
      className: `fs-5 d-flex breadcrumb_item align-items-center rounded-circle ${index_module_default.dot} ${show && index_module_default.active} ${show ? "bg-success text-white" : "bg-transparent text-body"}`,
      onClick: () => {
        setShow((prevState) => !prevState);
      }
    },
    /* @__PURE__ */ React2.createElement(Dot_default, { className: `pe-none` })
  ), show ? /* @__PURE__ */ React2.createElement(
    "div",
    {
      className: `pt-3 w-max position-absolute start-0 top-100 zindex-5 ${index_module_default.list}`
    },
    /* @__PURE__ */ React2.createElement("div", { className: `w-max mt-2 start-0 shadow-sm bg-white rounded-3 border zindex-5` }, data.map((_breadcrumb, index) => {
      if (index === 0 || index === data.length - 1) {
        return;
      } else {
        return _breadcrumb?.name && /* @__PURE__ */ React2.createElement(
          "span",
          {
            key: index,
            className: `text-body py-1 m-0 btn border-bottom px-2 d-flex align-items-center cursor-pointer breadcrumb_item ${index_module_default.h_40}`,
            onClick: () => {
              handleLink(_breadcrumb?.id);
              setShow(false);
            }
          },
          /* @__PURE__ */ React2.createElement(React2.Fragment, null, /* @__PURE__ */ React2.createElement(FontAwesomeIcon, { className: "text-body px-3 pe-none", icon: faFolder }), /* @__PURE__ */ React2.createElement("span", { className: "text-body" }, _breadcrumb.name))
        );
      }
    }))
  ) : null), /* @__PURE__ */ React2.createElement("span", null, /* @__PURE__ */ React2.createElement(FontAwesomeIcon, { size: "2xs", className: "text-green px-3", icon: faAngleRight }), /* @__PURE__ */ React2.createElement(
    "span",
    {
      className: "text-body cursor-pointer",
      onClick: () => handleLink(data[data.length - 1]?.id)
    },
    data[data.length - 1].name
  ))) : null);
};
var Breadcrumbs_default = BreadCrumbs;

// src/integration/lib/AesirXDamForm/AesirXDamActionBar.jsx
import { ButtonNormal } from "aesirx-uikit";
var AesirXDamActionBar = observer(
  class AesirXDamActionBar2 extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "damFormModalViewModel", null);
      __publicField(this, "damListViewModel", null);
      __publicField(this, "openModal", false);
      __publicField(this, "accept", "*");
      __publicField(this, "handleCreateFolder", () => {
        const { t } = this.props;
        const collectionId = this.damListViewModel.damLinkFolder.split("/");
        const currentCollection = !isNaN(collectionId[collectionId.length - 1]) ? collectionId[collectionId.length - 1] : 0;
        this.damListViewModel.createCollections({
          [DAM_COLLECTION_API_RESPONSE_FIELD_KEY.NAME]: t("txt_new_folder"),
          [DAM_COLLECTION_API_RESPONSE_FIELD_KEY.PARENT_ID]: currentCollection
        });
      });
      __publicField(this, "handleCreateAssets", (data) => {
        if (data) {
          const collectionId = this.damListViewModel.damLinkFolder.split("/");
          const currentCollection = !isNaN(collectionId[collectionId.length - 1]) ? collectionId[collectionId.length - 1] : 0;
          this.damListViewModel.createAssets({
            [DAM_ASSETS_API_FIELD_KEY.NAME]: data?.name ?? "",
            [DAM_ASSETS_API_FIELD_KEY.FILE_NAME]: data?.name ?? "",
            [DAM_ASSETS_API_FIELD_KEY.COLLECTION_ID]: currentCollection,
            [DAM_ASSETS_API_FIELD_KEY.FILE]: data
          });
        }
      });
      __publicField(this, "handleLinkBreadCrumb", (link = "/root") => {
        if (link === "root") {
          this.damListViewModel.setDamLinkFolder(link);
        } else {
          const currentLink = this.damListViewModel.damLinkFolder.split("/");
          const currentLinkIndexMap = currentLink.findIndex((a) => +a === link);
          const linkFolder = currentLink.splice(0, currentLinkIndexMap + 1).join("/");
          if (linkFolder !== this.damListViewModel.damLinkFolder) {
            this.damListViewModel.setDamLinkFolder(linkFolder);
          }
        }
      });
      const { viewModel, accept } = props;
      this.damListViewModel = viewModel ? viewModel.getDamListViewModel() : null;
      this.damFormModalViewModal = viewModel ? viewModel.getDamFormViewModel() : null;
      this.accept = accept ?? "*";
    }
    componentDidMount() {
    }
    render() {
      const { t } = this.props;
      const collectionId = this.damListViewModel.damLinkFolder.split("/");
      const breadcrumb = collectionId.map((id, index) => {
        if (!isNaN(id) && index !== 0) {
          return this.damListViewModel.collections.find((collection) => +collection.id === +id);
        }
      }).filter((item) => item ? true : false) ?? [];
      return /* @__PURE__ */ React3.createElement(React3.Fragment, null, /* @__PURE__ */ React3.createElement(Breadcrumbs_default, { handleLink: this.handleLinkBreadCrumb, data: breadcrumb }), /* @__PURE__ */ React3.createElement("div", { className: "d-flex justify-content-end col-auto" }, /* @__PURE__ */ React3.createElement(
        ButtonNormal,
        {
          onClick: this.handleCreateFolder,
          iconStart: faFolder2,
          text: "txt_create_folder",
          className: "btn-outline-gray-300 bg-white text-body border-1 me-3"
        }
      ), /* @__PURE__ */ React3.createElement(Dropzone_default, { noDrag: true, createAssets: this.handleCreateAssets }, /* @__PURE__ */ React3.createElement(
        ButtonNormal,
        {
          onClick: () => {
          },
          iconStart: faPlus,
          text: t("txt_upload_file"),
          className: " btn-success"
        }
      )), /* @__PURE__ */ React3.createElement(AesirXDamFormModel_default, null)));
    }
  }
);
var AesirXDamActionBar_default = withTranslation()(withDamViewModel(AesirXDamActionBar));

export {
  AesirXDamActionBar_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
