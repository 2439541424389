import {
  AesirxAuthenticationApiService
} from "./chunk-ACBX6SY4.js";
import {
  Storage
} from "./chunk-GYE2SV7H.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";
import {
  AUTHORIZATION_KEY,
  AXIOS_CONFIGS
} from "./chunk-W5LWBRT6.js";

// src/Gateway/InstanceJWT.ts
import axios from "axios";
import queryString from "query-string";
import createAuthRefreshInterceptor from "axios-auth-refresh";
var AUTHORIZED_CODE_URL = BaseRoute_default.__createRequestURL(
  {
    option: "token",
    api: "oauth2"
  },
  false
);
var clientID = AXIOS_CONFIGS.CLIENT_ID;
var clientSecret = AXIOS_CONFIGS.CLIENT_SECRET;
var AesirXApiInstance = axios.create({
  baseURL: AXIOS_CONFIGS.BASE_ENDPOINT_URL,
  timeout: 100 * 1e4
});
var refreshToken = (failedRequest) => {
  let refresh_token;
  if (false) {
    refresh_token = process.env.refreshToken;
  } else {
    refresh_token = Storage.getItem(AUTHORIZATION_KEY.REFRESH_TOKEN) ?? "";
  }
  const refreshTokenFormData = new URLSearchParams();
  refreshTokenFormData.append("grant_type", "refresh_token");
  refreshTokenFormData.append("client_id", clientID);
  refreshTokenFormData.append("client_secret", clientSecret);
  refreshTokenFormData.append("refresh_token", refresh_token);
  const key = {
    [AUTHORIZATION_KEY.ACCESS_TOKEN]: [AUTHORIZATION_KEY.ACCESS_TOKEN],
    [AUTHORIZATION_KEY.TOKEN_TYPE]: [AUTHORIZATION_KEY.TOKEN_TYPE],
    [AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER]: [AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER],
    [AUTHORIZATION_KEY.REFRESH_TOKEN]: [AUTHORIZATION_KEY.REFRESH_TOKEN],
    [AUTHORIZATION_KEY.JWT]: [AUTHORIZATION_KEY.JWT]
  };
  const request = new AesirxAuthenticationApiService();
  request.refreshToken(failedRequest, AUTHORIZED_CODE_URL, refreshTokenFormData, key);
};
var refreshAuthLogic = (failedRequest) => refreshToken(failedRequest);
createAuthRefreshInterceptor(AesirXApiInstance, refreshAuthLogic, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: true
});
var pending = {};
var CancelToken = axios.CancelToken;
var removePending = (config, f) => {
  if (config) {
    const url = config.url.replace(config.baseURL, "/");
    const flagUrl = url + "&" + config.method + "&" + queryString.stringify(config.params);
    if (flagUrl in pending) {
      if (f) {
        f();
      } else {
        delete pending[flagUrl];
      }
    } else {
      if (f) {
        pending[flagUrl] = f;
      }
    }
  }
};
AesirXApiInstance.interceptors.request.use(
  function(config) {
    let accessToken = "";
    let jwt = "";
    if (false) {
      accessToken = process.env.accessToken;
    } else {
      accessToken = Storage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN);
      jwt = Storage.getItem(AUTHORIZATION_KEY.JWT);
    }
    if (config.method === "post" || config.method === "put") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    if (jwt) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + jwt
      };
    }
    config.params = config.params || {};
    config.params["time"] = Math.floor(Date.now() / 1e3);
    config.cancelToken = new CancelToken((c) => {
      removePending(config, c);
    });
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
AesirXApiInstance.interceptors.response.use(
  (response) => {
    removePending(response.config, "");
    return response.data;
  },
  (error) => {
    removePending(error.config, "");
    return Promise.reject(error);
  }
);
var InstanceJWT_default = AesirXApiInstance;

export {
  InstanceJWT_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
