import {
  PageStatus_default
} from "./chunk-P2IY2IBV.js";
import {
  BiStoreProvider,
  useBiViewModel
} from "./chunk-6Q3QPLH5.js";
import {
  __publicField
} from "./chunk-XXPGZHWZ.js";

// src/integration/lib/MainLayoutIntegration.jsx
import React4, { lazy as lazy2, Suspense } from "react";

// src/store/BiStore/BiListViewModel.js
import { notify, history } from "aesirx-uikit";
import { env } from "aesirx-lib";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import queryString from "query-string";
var BiListViewModel = class {
  constructor() {
    __publicField(this, "paginationCollections", null);
    __publicField(this, "status", PageStatus_default.READY);
    __publicField(this, "data", []);
    __publicField(this, "listDomain", JSON.parse(env.REACT_APP_DATA_STREAM) ?? []);
    __publicField(this, "tableRowHeader", null);
    __publicField(this, "dateFilter", {
      date_start: moment().subtract(7, "d").format("YYYY-MM-DD"),
      date_end: moment().endOf("day").format("YYYY-MM-DD")
    });
    __publicField(this, "dataFilter", {});
    __publicField(this, "pageSize", 5);
    __publicField(this, "isList", false);
    __publicField(this, "activeDomain", env.REACT_APP_DATA_STREAM && JSON.parse(env.REACT_APP_DATA_STREAM)[0].domain);
    __publicField(this, "isSearch", false);
    __publicField(this, "integrationLink", "dashboard");
    __publicField(this, "setActiveDomain", (domain) => {
      const location2 = history.location;
      if (location2.pathname === "/wp-admin/admin.php" || location2.pathname === "/administrator/index.php") {
        const search = {
          ...queryString.parse(location2.search),
          ...{ domain }
        };
        history.push({
          ...location2,
          ...{ search: queryString.stringify(search) }
        });
      } else {
        const search = {
          ...queryString.parse(location2.search),
          ...{ domain }
        };
        history.push({
          ...location2,
          ...{ search: queryString.stringify(search) }
        });
      }
      this.activeDomain = domain;
    });
    __publicField(this, "setIntegrationLink", (link) => {
      if (location.pathname === "/wp-admin/admin.php" || location.pathname === "/administrator/index.php") {
        const search = {
          ...queryString.parse(location.search),
          ...{ menu: link }
        };
        history.push({
          ...location,
          ...{ search: queryString.stringify(search) }
        });
      }
      this.integrationLink = link;
    });
    __publicField(this, "setDateFilter", (date_start, date_end) => {
      this.dateFilter = {
        ...this.dateFilter,
        date_start: moment(date_start).format("YYYY-MM-DD"),
        date_end: date_end ? moment(date_end).format("YYYY-MM-DD") : moment(date_start).format("YYYY-MM-DD")
      };
      const search = {
        ...queryString.parse(location.search),
        ...{
          date_start: moment(date_start).format("YYYY-MM-DD"),
          date_end: date_end ? moment(date_end).format("YYYY-MM-DD") : moment(date_start).format("YYYY-MM-DD")
        }
      };
      history.push({
        ...location,
        ...{ search: queryString.stringify(search) }
      });
    });
    __publicField(this, "resetObservableProperties", () => {
    });
    __publicField(this, "callbackOnErrorHandler", (error) => {
      if (error.message === "isCancle") {
        this.status = PageStatus_default.READY;
      } else
        notify(error.message, "error");
    });
    __publicField(this, "callbackOnDataSuccessHandler", (data) => {
      if (data) {
        this.status = PageStatus_default.READY;
        this.data = data;
      } else {
        this.status = PageStatus_default.ERROR;
      }
    });
    makeAutoObservable(this);
    const checkPage = queryString.parse(location.search);
    this.activeDomain = checkPage?.domain ? checkPage?.domain : env.REACT_APP_DATA_STREAM && JSON.parse(env.REACT_APP_DATA_STREAM)[0].domain;
    this.integrationLink = checkPage?.menu ? checkPage?.menu : "dashboard";
    const date_start = checkPage?.date_start;
    const date_end = checkPage?.date_end;
    if (date_start || date_end) {
      this.dateFilter = {
        date_start,
        date_end
      };
    }
  }
};
var BiListViewModel_default = BiListViewModel;

// src/store/BiStore/BiViewModel.js
var BiViewModel = class {
  constructor() {
    __publicField(this, "biListViewModel", null);
    __publicField(this, "getBiListViewModel", () => this.biListViewModel);
    this.biListViewModel = new BiListViewModel_default();
  }
};

// src/scss/app.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
:root,
[data-bs-theme=light] {
  --aesirxui-blue: #0a5aff;
  --aesirxui-indigo: #6610f2;
  --aesirxui-purple: #6a3e73;
  --aesirxui-pink: #d63384;
  --aesirxui-red: #cb222c;
  --aesirxui-orange: #f78464;
  --aesirxui-yellow: #ff9e01;
  --aesirxui-green: #1ab394;
  --aesirxui-green-light: #d1fae5;
  --aesirxui-teal: #20c997;
  --aesirxui-cyan: #1a73e8;
  --aesirxui-white: #fff;
  --aesirxui-gray: #8e8e8e;
  --aesirxui-gray-dark: #e9e9e9;
  --aesirxui-gray-100: #f6f8fa;
  --aesirxui-gray-200: #f9f9f9;
  --aesirxui-gray-300: #ebebeb;
  --aesirxui-gray-400: #e3e3e3;
  --aesirxui-gray-500: #d2d2d2;
  --aesirxui-gray-600: #8e8e8e;
  --aesirxui-gray-700: #c4c4c4;
  --aesirxui-gray-800: #e9e9e9;
  --aesirxui-gray-900: #222328;
  --aesirxui-primary: #0a083b;
  --aesirxui-secondary: #004d6c;
  --aesirxui-success: #1ab394;
  --aesirxui-info: #3b7ab5;
  --aesirxui-warning: #f07e25;
  --aesirxui-danger: #c8192e;
  --aesirxui-light: #f6f8fa;
  --aesirxui-dark: #222328;
  --aesirxui-noti-error: #c8192e;
  --aesirxui-noti-warn: #946300;
  --aesirxui-noti-bg-error: #fee2e2;
  --aesirxui-noti-bg-warn: #fff8e9;
  --aesirxui-noti-bg-success: #d1fae5;
  --aesirxui-custom-color: #712cf9;
  --aesirxui-purple: #6a3e73;
  --aesirxui-secondary-25: #f0f3fb;
  --aesirxui-secondary-50: #1d55a9;
  --aesirxui-secondary-100: #0d588a;
  --aesirxui-success-dark: #1c9678;
  --aesirxui-success-darken: #0f674c;
  --aesirxui-warning-50: #fff8e9;
  --aesirxui-warning-100: #946300;
  --aesirxui-warning-200: #ffe6b2;
  --aesirxui-gray-100: #f6f8fa;
  --aesirxui-gray-400: #e3e3e3;
  --aesirxui-gray-500: #d2d2d2;
  --aesirxui-gray-600: #8e8e8e;
  --aesirxui-gray-700: #c4c4c4;
  --aesirxui-gray-800: #e9e9e9;
  --aesirxui-gray-900: #222328;
  --aesirxui-gray-stroke-1: #f3f3f3;
  --aesirxui-gray-stroke-2: #dedede;
  --aesirxui-gray-light: #97979e;
  --aesirxui-gray-heading: #5f5e70;
  --aesirxui-blue: #0a5aff;
  --aesirxui-blue-light: #edf8fd;
  --aesirxui-color-blue-6: #dce6eb;
  --aesirxui-color-blue-9: #f0f3fb;
  --aesirxui-green-light: #d1fae5;
  --aesirxui-telegram: #039be5;
  --aesirxui-facebook: #3a559f;
  --aesirxui-twitter: #26a6d1;
  --aesirxui-linkedin: #007ab9;
  --aesirxui-instagram: #b334af;
  --aesirxui-chatgpt: #00a481;
  --aesirxui-dark-blue: #3e545e;
  --aesirxui-button-color: white;
  --aesirxui-body-color: #0a083b;
  --aesirxui-primary-rgb: 10, 8, 59;
  --aesirxui-secondary-rgb: 0, 77, 108;
  --aesirxui-success-rgb: 26, 179, 148;
  --aesirxui-info-rgb: 59, 122, 181;
  --aesirxui-warning-rgb: 240, 126, 37;
  --aesirxui-danger-rgb: 200, 25, 46;
  --aesirxui-light-rgb: 246, 248, 250;
  --aesirxui-dark-rgb: 34, 35, 40;
  --aesirxui-noti-error-rgb: 200, 25, 46;
  --aesirxui-noti-warn-rgb: 148, 99, 0;
  --aesirxui-noti-bg-error-rgb: 254, 226, 226;
  --aesirxui-noti-bg-warn-rgb: 255, 248, 233;
  --aesirxui-noti-bg-success-rgb: 209, 250, 229;
  --aesirxui-custom-color-rgb: 113, 44, 249;
  --aesirxui-purple-rgb: 106, 62, 115;
  --aesirxui-secondary-25-rgb: 240, 243, 251;
  --aesirxui-secondary-50-rgb: 29, 85, 169;
  --aesirxui-secondary-100-rgb: 13, 88, 138;
  --aesirxui-success-dark-rgb: 28, 150, 120;
  --aesirxui-success-darken-rgb: 15, 103, 76;
  --aesirxui-warning-50-rgb: 255, 248, 233;
  --aesirxui-warning-100-rgb: 148, 99, 0;
  --aesirxui-warning-200-rgb: 255, 230, 178;
  --aesirxui-gray-100-rgb: 246, 248, 250;
  --aesirxui-gray-400-rgb: 227, 227, 227;
  --aesirxui-gray-500-rgb: 210, 210, 210;
  --aesirxui-gray-600-rgb: 142, 142, 142;
  --aesirxui-gray-700-rgb: 196, 196, 196;
  --aesirxui-gray-800-rgb: 233, 233, 233;
  --aesirxui-gray-900-rgb: 34, 35, 40;
  --aesirxui-gray-stroke-1-rgb: 243, 243, 243;
  --aesirxui-gray-stroke-2-rgb: 222, 222, 222;
  --aesirxui-gray-light-rgb: 151, 151, 158;
  --aesirxui-gray-heading-rgb: 95, 94, 112;
  --aesirxui-blue-rgb: 10, 90, 255;
  --aesirxui-blue-light-rgb: 237, 248, 253;
  --aesirxui-color-blue-6-rgb: 220, 230, 235;
  --aesirxui-color-blue-9-rgb: 240, 243, 251;
  --aesirxui-green-light-rgb: 209, 250, 229;
  --aesirxui-telegram-rgb: 3, 155, 229;
  --aesirxui-facebook-rgb: 58, 85, 159;
  --aesirxui-twitter-rgb: 38, 166, 209;
  --aesirxui-linkedin-rgb: 0, 122, 185;
  --aesirxui-instagram-rgb: 179, 52, 175;
  --aesirxui-chatgpt-rgb: 0, 164, 129;
  --aesirxui-dark-blue-rgb: 62, 84, 94;
  --aesirxui-button-color-rgb: 255, 255, 255;
  --aesirxui-body-color-rgb: 10, 8, 59;
  --aesirxui-primary-text-emphasis: #040318;
  --aesirxui-secondary-text-emphasis: #001f2b;
  --aesirxui-success-text-emphasis: #0a483b;
  --aesirxui-info-text-emphasis: #183148;
  --aesirxui-warning-text-emphasis: #60320f;
  --aesirxui-danger-text-emphasis: #500a12;
  --aesirxui-light-text-emphasis: #c4c4c4;
  --aesirxui-dark-text-emphasis: #c4c4c4;
  --aesirxui-primary-bg-subtle: #ceced8;
  --aesirxui-secondary-bg-subtle: #ccdbe2;
  --aesirxui-success-bg-subtle: #d1f0ea;
  --aesirxui-info-bg-subtle: #d8e4f0;
  --aesirxui-warning-bg-subtle: #fce5d3;
  --aesirxui-danger-bg-subtle: #f4d1d5;
  --aesirxui-light-bg-subtle: #fbfcfd;
  --aesirxui-dark-bg-subtle: #e3e3e3;
  --aesirxui-primary-border-subtle: #9d9cb1;
  --aesirxui-secondary-border-subtle: #99b8c4;
  --aesirxui-success-border-subtle: #a3e1d4;
  --aesirxui-info-border-subtle: #b1cae1;
  --aesirxui-warning-border-subtle: #f9cba8;
  --aesirxui-danger-border-subtle: #e9a3ab;
  --aesirxui-light-border-subtle: #f9f9f9;
  --aesirxui-dark-border-subtle: #d2d2d2;
  --aesirxui-white-rgb: 255, 255, 255;
  --aesirxui-black-rgb: 0, 0, 0;
  --aesirxui-font-sans-serif: "Inter", sans-serif;
  --aesirxui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --aesirxui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --aesirxui-root-font-size: 16px;
  --aesirxui-body-font-family: var(--aesirxui-font-sans-serif);
  --aesirxui-body-font-size: 1rem;
  --aesirxui-body-font-weight: 400;
  --aesirxui-body-line-height: 1.5;
  --aesirxui-body-color: #0a083b;
  --aesirxui-body-color-rgb: 10, 8, 59;
  --aesirxui-body-bg: #f8f9fa;
  --aesirxui-body-bg-rgb: 248, 249, 250;
  --aesirxui-emphasis-color: #000;
  --aesirxui-emphasis-color-rgb: 0, 0, 0;
  --aesirxui-secondary-color: rgba(10, 8, 59, 0.75);
  --aesirxui-secondary-color-rgb: 10, 8, 59;
  --aesirxui-secondary-bg: #f9f9f9;
  --aesirxui-secondary-bg-rgb: 249, 249, 249;
  --aesirxui-tertiary-color: rgba(10, 8, 59, 0.5);
  --aesirxui-tertiary-color-rgb: 10, 8, 59;
  --aesirxui-tertiary-bg: #f6f8fa;
  --aesirxui-tertiary-bg-rgb: 246, 248, 250;
  --aesirxui-heading-color: inherit;
  --aesirxui-link-color: #0a083b;
  --aesirxui-link-color-rgb: 10, 8, 59;
  --aesirxui-link-decoration: none;
  --aesirxui-link-hover-color: #08062f;
  --aesirxui-link-hover-color-rgb: 8, 6, 47;
  --aesirxui-link-hover-decoration: underline;
  --aesirxui-code-color: #d63384;
  --aesirxui-highlight-bg: #ffeccc;
  --aesirxui-border-width: 1px;
  --aesirxui-border-style: solid;
  --aesirxui-border-color: #d5d5d5;
  --aesirxui-border-color-translucent: rgba(0, 0, 0, 0.175);
  --aesirxui-border-radius: 0.25rem;
  --aesirxui-border-radius-sm: 5px;
  --aesirxui-border-radius-lg: 10px;
  --aesirxui-border-radius-xl: 1rem;
  --aesirxui-border-radius-xxl: 2rem;
  --aesirxui-border-radius-2xl: var(--aesirxui-border-radius-xxl);
  --aesirxui-border-radius-pill: 50rem;
  --aesirxui-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --aesirxui-box-shadow-sm: 0px 0px 5px 0px rgba(34, 35, 40, 0.0509803922);
  --aesirxui-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --aesirxui-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --aesirxui-focus-ring-width: 0.25rem;
  --aesirxui-focus-ring-opacity: 0.25;
  --aesirxui-focus-ring-color: rgba(10, 8, 59, 0.25);
  --aesirxui-form-valid-color: #1ab394;
  --aesirxui-form-valid-border-color: #1ab394;
  --aesirxui-form-invalid-color: #c8192e;
  --aesirxui-form-invalid-border-color: #c8192e;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --aesirxui-body-color: #ebebeb;
  --aesirxui-body-color-rgb: 235, 235, 235;
  --aesirxui-body-bg: #101529;
  --aesirxui-body-bg-rgb: 16, 21, 41;
  --aesirxui-emphasis-color: #fff;
  --aesirxui-emphasis-color-rgb: 255, 255, 255;
  --aesirxui-secondary-color: rgba(235, 235, 235, 0.75);
  --aesirxui-secondary-color-rgb: 235, 235, 235;
  --aesirxui-secondary-bg: #e9e9e9;
  --aesirxui-secondary-bg-rgb: 233, 233, 233;
  --aesirxui-tertiary-color: rgba(235, 235, 235, 0.5);
  --aesirxui-tertiary-color-rgb: 235, 235, 235;
  --aesirxui-tertiary-bg: #868689;
  --aesirxui-tertiary-bg-rgb: 134, 134, 137;
  --aesirxui-primary-text-emphasis: #fff;
  --aesirxui-secondary-text-emphasis: #fff;
  --aesirxui-success-text-emphasis: #76d1bf;
  --aesirxui-info-text-emphasis: #89afd3;
  --aesirxui-warning-text-emphasis: #f6b27c;
  --aesirxui-danger-text-emphasis: #de7582;
  --aesirxui-light-text-emphasis: #fff;
  --aesirxui-dark-text-emphasis: #ebebeb;
  --aesirxui-primary-bg-subtle: #fff;
  --aesirxui-secondary-bg-subtle: #000f16;
  --aesirxui-success-bg-subtle: #05241e;
  --aesirxui-info-bg-subtle: #0c1824;
  --aesirxui-warning-bg-subtle: #301907;
  --aesirxui-danger-bg-subtle: #280509;
  --aesirxui-light-bg-subtle: #0c1124;
  --aesirxui-dark-bg-subtle: #757575;
  --aesirxui-primary-border-subtle: #060523;
  --aesirxui-secondary-border-subtle: #002e41;
  --aesirxui-success-border-subtle: #106b59;
  --aesirxui-info-border-subtle: #23496d;
  --aesirxui-warning-border-subtle: #904c16;
  --aesirxui-danger-border-subtle: #780f1c;
  --aesirxui-light-border-subtle: #c4c4c4;
  --aesirxui-dark-border-subtle: #e9e9e9;
  --aesirxui-heading-color: inherit;
  --aesirxui-link-color: #fff;
  --aesirxui-link-hover-color: white;
  --aesirxui-link-color-rgb: 255, 255, 255;
  --aesirxui-link-hover-color-rgb: 255, 255, 255;
  --aesirxui-code-color: #e685b5;
  --aesirxui-border-color: #c4c4c4;
  --aesirxui-border-color-translucent: rgba(255, 255, 255, 0.15);
  --aesirxui-form-valid-color: #76d1bf;
  --aesirxui-form-valid-border-color: #76d1bf;
  --aesirxui-form-invalid-color: #e07a80;
  --aesirxui-form-invalid-border-color: #e07a80;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--aesirxui-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--aesirxui-body-font-family);
  font-size: var(--aesirxui-body-font-size);
  font-weight: var(--aesirxui-body-font-weight);
  line-height: var(--aesirxui-body-line-height);
  color: var(--aesirxui-body-color);
  text-align: var(--aesirxui-body-text-align);
  background-color: var(--aesirxui-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--aesirxui-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--aesirxui-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--aesirxui-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--aesirxui-link-color-rgb), var(--aesirxui-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --aesirxui-link-color-rgb: var(--aesirxui-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--aesirxui-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--aesirxui-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--aesirxui-body-bg);
  background-color: var(--aesirxui-body-color);
  border-radius: 5px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--aesirxui-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

@media print {
  @page {
    size: 400mm 400mm;
  }
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  div {
    page-break-inside: avoid;
  }
  .scroll-container {
    overflow: visible;
    height: fit-content;
  }
}
.printButton {
  position: absolute;
  top: 31px;
  right: 355px;
}

.daterange-picker-wrapper .form-control {
  width: 285px;
}

#tooltipTable {
  max-width: 400px;
  text-align: center;
}

.aesirxui .nav-pills.nav-custom .nav-link.active {
  color: #0f674c;
}
.aesirxui #biapp .table {
  --aesirxui-table-bg: #fff;
}
.aesirxui #biapp .table td {
  border: 0;
}

[data-bs-theme=dark] .aesirxui .text-gray-900 {
  color: #fff;
}
[data-bs-theme=dark] .aesirxui #biapp .table {
  --aesirxui-table-bg: var(--aesirxui-color-blue-6);
}
[data-bs-theme=dark] .aesirxui .nav-pills .nav-link.active {
  background-color: transparent;
}

#bi-app .daterange-picker-wrapper .form-control {
  min-width: 330px;
}
#bi-app .daterange-picker-wrapper .form-control.en {
  min-width: 330px;
}
#bi-app .daterange-picker-wrapper .form-control.vi {
  min-width: 330px;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/integration/lib/MainLayoutIntegration.jsx
import { observer as observer3 } from "mobx-react";
import { Spinner } from "aesirx-uikit";
import { AppProvider } from "aesirx-uikit";

// src/routes/routes.js
import React, { lazy } from "react";
var LoginPage = lazy(() => import("./LoginPage-FTF6BD7O.js"));
var DashboardPage = lazy(() => import("./Dashboard-UC22RCHP.js"));
var AudiencePage = lazy(() => import("./AudiencePage-IPZ5LZH2.js"));
var RevenuePage = lazy(() => import("./RevenuePage-QSGGOMCR.js"));
var UTMTrackingPage = lazy(() => import("./UTMTrackingPage-LHJWADAT.js"));
var EventsPage = lazy(() => import("./EventsPage-22ZQNZD7.js"));
var WoocommercePage = lazy(() => import("./WoocommercePage-UZ72JYMP.js"));
var RegionCountryPage = lazy(() => import("./RegionCountryPage-DESIGVKM.js"));
var FlowPage = lazy(() => import("./FlowDetailPage-T7CTAVUK.js"));
var authRoutes = [
  {
    path: "/login",
    exact: true,
    main: () => /* @__PURE__ */ React.createElement(LoginPage, null)
  }
];
var mainRoutes = [
  {
    path: ["/"],
    exact: true,
    page: ["dashboard"],
    main: () => /* @__PURE__ */ React.createElement(DashboardPage, null)
  },
  {
    path: ["/audience/overview", "/audience/behavior"],
    exact: true,
    page: ["audience-overview", "audience-behavior"],
    main: () => /* @__PURE__ */ React.createElement(AudiencePage, null)
  },
  { path: "/revenue", exact: true, main: () => /* @__PURE__ */ React.createElement(RevenuePage, null) },
  {
    path: ["/utm-tracking", "/utm-tracking/generator"],
    page: ["utm-tracking", "utm-tracking-generator"],
    exact: true,
    main: () => /* @__PURE__ */ React.createElement(UTMTrackingPage, null)
  },
  {
    path: ["/events", "/events/generator"],
    page: ["events", "events-generator"],
    exact: true,
    main: () => /* @__PURE__ */ React.createElement(EventsPage, null)
  },
  {
    path: ["/woocommerce"],
    page: ["woocommerce"],
    exact: true,
    main: () => /* @__PURE__ */ React.createElement(WoocommercePage, null)
  },
  {
    path: ["/flow/:uuid"],
    exact: true,
    page: ["flow/:uuid"],
    main: () => /* @__PURE__ */ React.createElement(FlowPage, null)
  },
  {
    path: "/region-country",
    exact: true,
    main: () => /* @__PURE__ */ React.createElement(RegionCountryPage, null)
  }
];
var integrationRoutes = () => mainRoutes.map((item) => {
  if (Array.isArray(item.path)) {
    item.path = item.path.map((path) => "/bi" + path);
  } else {
    item.path = "/bi" + item.path;
  }
  return item;
});

// src/components/DataStream/index.jsx
import React2, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
var DataStream = observer(() => {
  const [isOpenCollapse, setIsOpenCollapse] = useState("default");
  const { t } = useTranslation();
  const biStore = useBiViewModel();
  const handleChangeDataStream = (value) => {
    handleOpen("");
    biStore.biListViewModel.setActiveDomain(value);
  };
  const handleOpen = (clickedIndex, parentIndex) => {
    if (isOpenCollapse === clickedIndex.toString()) {
      if (parentIndex) {
        setIsOpenCollapse(parentIndex.toString());
      } else {
        setIsOpenCollapse(null);
      }
    } else {
      if (isOpenCollapse?.includes(clickedIndex.toString())) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex.toString());
      }
    }
  };
  return /* @__PURE__ */ React2.createElement("div", { className: "data-stream position-relative item_menu m-0 h-100 z-index-100" }, /* @__PURE__ */ React2.createElement(
    Button,
    {
      variant: "",
      onClick: () => handleOpen("data-stream"),
      className: `d-flex align-items-center justify-content-start rounded-2 link_menu text-decoration-none text-break p-0 px-1 w-100 h-100 shadow-none ${isOpenCollapse === "data-stream" ? "active" : ""}`,
      "aria-controls": "wr_list_submenu",
      "aria-expanded": isOpenCollapse === "data-stream"
    },
    /* @__PURE__ */ React2.createElement("p", { className: "overflow-hidden text-start m-0" }, /* @__PURE__ */ React2.createElement("span", { className: "mb-sm fs-sm" }, t("txt_menu_data_stream")), /* @__PURE__ */ React2.createElement("br", null), /* @__PURE__ */ React2.createElement("span", { className: "text-body fw-bold text-white mb-0 fs-4 text-start" }, biStore.biListViewModel?.listDomain?.find(
      (x) => x.domain === biStore.biListViewModel?.activeDomain
    )?.name, " ", "(", biStore.biListViewModel?.activeDomain, ")")),
    biStore.biListViewModel?.listDomain.length > 1 && /* @__PURE__ */ React2.createElement("i", { className: "ps-1 icons text-green" }, /* @__PURE__ */ React2.createElement(FontAwesomeIcon, { icon: faChevronDown }))
  ), /* @__PURE__ */ React2.createElement(Collapse, { className: "position-relative", in: isOpenCollapse === "data-stream" }, /* @__PURE__ */ React2.createElement("ul", { className: "px-16 position-absolute bg-white shadow-lg rounded-1 w-100 top-100 start-0 list-unstyled mb-0 mh-80vh overflow-auto" }, biStore.biListViewModel?.listDomain.map((item, index) => {
    return item.domain !== biStore.biListViewModel?.activeDomain && /* @__PURE__ */ React2.createElement(
      "li",
      {
        key: index,
        className: `item_menu cursor-pointer`,
        onClick: () => handleChangeDataStream(item.domain)
      },
      /* @__PURE__ */ React2.createElement("div", { className: `text-decoration-none` }, /* @__PURE__ */ React2.createElement(
        "span",
        {
          className: `d-block py-16 link_menu text-decoration-none  ${biStore.biListViewModel?.listDomain.length - 1 === index ? "" : "border-bottom-1 border-gray-800"}`
        },
        item.name
      ))
    );
  }))));
});

// src/translations/dk/common.json
var common_default = {
  txt_bi: "BI",
  txt_menu_visitors: "Bes\xF8gende",
  txt_menu_overview: "Oversigt",
  txt_menu_behavior: "Adf\xE6rd",
  txt_menu_revenue: "Oms\xE6tning",
  txt_menu_subscription: "Abonnement",
  txt_menu_member_roles: "Medlemsroler",
  txt_menu_data_stream: "Data Stream",
  txt_menu_region: "Region / Land",
  txt_menu_click_anchor: "Klick & Ankertekst",
  txt_menu_utm_tracking: "UTM Tracking",
  txt_menu_events: "Event",
  txt_menu_member: "Medlemmer",
  txt_menu_import_export: "Importer/Exporter",
  txt_menu_colection_transfer: "Overf\xF8rsel af indsamling",
  txt_menu_setting: "Indstillinger",
  txt_menu_help_center: "Hj\xE6lp",
  txt_dashboard: "Dashboard",
  txt_dashboard_below: "Her er dine analytiske detaljer",
  txt_select_date: "V\xE6lg dato",
  txt_visitors: "Bes\xF8gende",
  txt_total_revenue: "Total oms\xE6tning",
  txt_sessions: "Sessioner",
  txt_conversion_rate: "Omvendelsesrate",
  txt_revenue_by_subscribers: "Oms\xE6tning fra abonnenter",
  txt_new_registered_users: "Nye registerede brugere",
  txt_continent: "Kontinent",
  txt_view_more: "Se mere",
  txt_view_detail: "Se detaljer",
  txt_views: "Visninger",
  txt_behavior: "Adf\xE6rd",
  txt_behavior_clicking: "Klicka adf\xE6rd",
  txt_most_clingking_pages: "Mest klikkede sider",
  txt_gender: "K\xF8n",
  txt_name: "Navn",
  txt_size: "St\xF8rrelse",
  txt_owner: "Ejer",
  txt_last_modified: "Senest \xE6ndret",
  txt_image: "Billede",
  txt_document: "Dokument",
  txt_audio: "Audio",
  txt_preview: "Forh\xE5ndsvisning",
  txt_move_to_folder: "Flyt til mappe",
  txt_download: "Download",
  txt_delete: "Slet",
  txt_sort_by: "Sorter efter",
  txt_date_create: "Oprettelsesdato",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_trash: "Skrald",
  txt_title_profilfe_setting: "Profilindstillinger",
  txt_title_set_information_name: "Inds\xE6t dit navn og andre offentligt tilg\xE6nglige oplysninger",
  txt_cancel: "Slet",
  txt_your_avatar: "Din Avatar",
  txt_upload_file: "Opload Fil",
  txt_create_folder: "Opret mappe",
  txt_main_menu: "Hovedmenu",
  txt_set_up: "S\xE6t op",
  txt_my_assets: "Mine aktiver",
  txt_download_folder: "Download mappe",
  txt_delete_folder: "Slet mappe",
  txt_are_you_sure: "Er du sikker?",
  txt_cancle: "Fortryd",
  txt_yes_delete: "Ja, slet",
  txt_file: "FILER",
  txt_storage: "Opbevaring",
  txt_upgrade: "Opgradering",
  txt_configuration_storage: "Konfigurationsopbevaring",
  txt_configuration_desc: "St\xF8rrelsen angivet nedenunder er den maximale st\xF8rrelse i pixel som kan bruge, n\xE5r et billede tilf\xF8jes til Medie-biblioteket",
  txt_save_setting: "Gem indstillinger",
  txt_client_id: "Klient ID",
  txt_client_secret: "Klient hemmlig",
  txt_region: "Region",
  txt_bucket: "Spand",
  txt_drop_files_anywhere_to_upload: "Slip filerne hvor som helst for at oploade",
  txt_or: "Or",
  txt_select_file: "V\xE6lg fil",
  txt_save_update: "Gem opdatering",
  txt_delete_assets_popup_desc: "Er du sikker p\xE5 du vil slette dette aktiv ?",
  txt_delete_collections_popup_desc: "Er du sikker p\xE5, at du vil slette denne mappe (inkluderer undermapper og aktiver) ?",
  tx_forgot_password: "Glemt adgangskode?",
  txt_file_size: "St\xF8rrelse",
  txt_url: "URL",
  txt_file_type: "Type",
  txt_sign_in_to_getting_started: "Log ind for at komme i gang.",
  txt_folders: "MAPPER",
  txt_of: "Fra",
  txt_used: "brugt",
  txt_audience: "Publikum",
  txt_analytic_details: "Her er dine analytiske detaljer",
  txt_value: "V\xE6rdi",
  txt_hours: "Timer",
  txt_days: "Dage",
  txt_weeks: "Uger",
  txt_months: "M\xE5neder",
  txt_page_views: "Sidevisninger",
  txt_unique_page_views: "Unikke sidevisninger",
  txt_acg_session_duration: "Gennemsnitlig sessionsl\xE6ngde",
  txt_bounce_rate: "Afvisningsprocent",
  txt_page_session: "Side/Session",
  txt_in_total: "I alt",
  txt_description: "Beskrivelse",
  txt_search: "S\xF8g",
  txt_date_range: "Datointerval",
  txt_devices: "Enheder",
  txt_choose_file: "V\xE6lg fil",
  "txt_select...": "V\xE6lg...",
  txt_all_users: "Alle brugere",
  txt_all: "Alle",
  txt_unique: "Enest\xE5ende",
  txt_no_data: "Ingen data",
  txt_something_went_wrong_from_server_response: "Noget gik galt fra serversvaret",
  txt_warning: "Advarsel",
  txt_successful: "Vellykket",
  txt_error: "Fejl",
  txt_number: "Antal",
  txt_visitor_flow: "Bes\xF8gende flow",
  txt_domain: "Dom\xE6ne",
  txt_location: "Beliggenhed",
  txt_duration: "Varighed",
  txt_ip: "IP",
  txt_device: "Enhed",
  txt_browser: "Browser",
  txt_language: "Sprog",
  txt_page: "Side",
  txt_page_title: "Sidetitel",
  txt_time_on_page: "Tid p\xE5 side",
  txt_menu_generator: "Generator",
  txt_utm_tracking_generator: "UTM Tracking Generator",
  txt_events_generator: "Events Generator",
  txt_export_pdf: "Eksporter PDF",
  txt_tooltip_visitors: "Antal bes\xF8gende",
  txt_tooltip_page_views: "Sidevisninger er det samlede antal viste sider. Gentagne visninger af en enkelt side t\xE6lles med.",
  txt_tooltip_unique_page_views: "Unikke sidevisninger er antallet af sessioner, hvor den angivne side blev set mindst \xE9n gang. En unik sidevisning t\xE6lles for hver kombination af side-URL + sidetitel.",
  txt_tooltip_bounce_rate: "Procentdelen af enkeltsidessessioner, hvor der ikke var nogen interaktion med siden. En afvist session har en varighed p\xE5 0 sekunder.",
  txt_tooltip_page_session: "Det gennemsnitlige antal sider, der er set i l\xF8bet af en session. Gentagne visninger af en enkelt side t\xE6lles med.",
  txt_tooltip_avg_session_duration: "The average length of a Session."
};

// src/translations/en/common.json
var common_default2 = {
  txt_bi: "BI",
  txt_menu_visitors: "Visitors",
  txt_menu_overview: "Overview",
  txt_menu_behavior: "Behavior",
  txt_menu_revenue: "Revenue",
  txt_menu_subscription: "Subscription",
  txt_menu_member_roles: "Member Roles",
  txt_menu_data_stream: "Data Stream",
  txt_menu_region: "Region / Country",
  txt_menu_click_anchor: "Click & Anchor",
  txt_menu_utm_tracking: "UTM Tracking",
  txt_menu_events: "Event",
  txt_menu_member: "Members",
  txt_menu_import_export: "Import/Export",
  txt_menu_colection_transfer: "Collection Transfer",
  txt_menu_setting: "Setting",
  txt_menu_help_center: "Help center",
  txt_dashboard: "Dashboard",
  txt_dashboard_below: "Here are your analytics details",
  txt_select_date: "Select Date",
  txt_visitors: "Visitors",
  txt_total_revenue: "Total Revenue",
  txt_sessions: "Sessions",
  txt_conversion_rate: "Conversion Rate",
  txt_revenue_by_subscribers: "Revenue by subscribers",
  txt_new_registered_users: "New Registered Users",
  txt_continent: "Continent",
  txt_view_more: "View more",
  txt_view_detail: "View detail",
  txt_views: "Views",
  txt_behavior: "Behavior",
  txt_behavior_clicking: "Clicking Behavior",
  txt_most_clingking_pages: "Most Clicking Pages",
  txt_gender: "Gender",
  txt_name: "Name",
  txt_size: "Size",
  txt_owner: "Owner",
  txt_last_modified: "Last Modified",
  txt_image: "Image",
  txt_document: "Document",
  txt_audio: "Audio",
  txt_preview: "Preview",
  txt_move_to_folder: "Move to folder",
  txt_download: "Download",
  txt_delete: "Delete",
  txt_sort_by: "Sort By",
  txt_date_create: "Date create",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_trash: "Trash",
  txt_title_profilfe_setting: "Profile Setting",
  txt_title_set_information_name: "Set your name and other public-facing information",
  txt_cancel: "Cancel",
  txt_your_avatar: "Your Avatar",
  txt_upload_file: "Upload File",
  txt_create_folder: "Create Folder",
  txt_main_menu: "Main Menu",
  txt_set_up: "Set up",
  txt_my_assets: "My Assets",
  txt_download_folder: "Download Folder",
  txt_delete_folder: "Delete Folder",
  txt_are_you_sure: "Are you sure?",
  txt_cancle: "Cancle",
  txt_yes_delete: "Yes, Delete",
  txt_file: "FILES",
  txt_storage: "Storage",
  txt_upgrade: "Upgrade",
  txt_configuration_storage: "Configuration Storage",
  txt_configuration_desc: "The size listed below determine the maximun size in pixels to use when adding an image to the Media Library",
  txt_save_setting: "Save setting",
  txt_client_id: "Client ID",
  txt_client_secret: "Client Secret",
  txt_region: "Region",
  txt_bucket: "Bucket",
  txt_drop_files_anywhere_to_upload: "Drop files anywhere to upload",
  txt_or: "Or",
  txt_select_file: "Select file",
  txt_save_update: "Save Update",
  txt_delete_assets_popup_desc: "Are you sure you want to delete this asset ?",
  txt_delete_collections_popup_desc: "Are you sure you want to delete this folder (including children and assets) ?",
  tx_forgot_password: "Forgot Password?",
  txt_file_size: "Size",
  txt_url: "URL",
  txt_file_type: "Type",
  txt_sign_in_to_getting_started: "Sign In to getting started.",
  txt_folders: "FOLDERS",
  txt_of: "of",
  txt_used: "used",
  txt_audience: "Audience",
  txt_analytic_details: "Here are your analytics details",
  txt_value: "Value",
  txt_hours: "Hours",
  txt_days: "Days",
  txt_weeks: "Weeks",
  txt_months: "Months",
  txt_page_views: "Pageviews",
  txt_unique_page_views: "Unique Page Views",
  txt_acg_session_duration: "Avg. Session Duration",
  txt_bounce_rate: "Bounce Rate",
  txt_page_session: "Page/Session",
  txt_in_total: "In Total",
  txt_description: "Description",
  txt_search: "Search",
  txt_date_range: "Date range",
  txt_devices: "Devices",
  txt_choose_file: "Choose file",
  "txt_select...": "Select...",
  txt_all_users: "All Users",
  txt_all: "All",
  txt_unique: "Unique",
  txt_no_data: "No data",
  txt_something_went_wrong_from_server_response: "Something went wrong from Server response",
  txt_warning: "Warning",
  txt_successful: "Successful",
  txt_error: "Error",
  txt_number: "Quantity",
  txt_visitor_flow: "Visitor Flow",
  txt_domain: "Domain",
  txt_location: "Location",
  txt_duration: "Duration",
  txt_ip: "IP",
  txt_device: "Device",
  txt_browser: "Browser",
  txt_language: "Language",
  txt_page: "Page",
  txt_page_title: "Page Title",
  txt_time_on_page: "Time On Page",
  txt_menu_generator: "Generator",
  txt_utm_tracking_generator: "UTM Tracking Generator",
  txt_events_generator: "Events Generator",
  txt_export_pdf: "Export PDF",
  txt_tooltip_visitors: "Number of visitors",
  txt_tooltip_page_views: "Pageviews is the total number of pages viewed. Repeated views of a single page are counted.",
  txt_tooltip_unique_page_views: "Unique Pageviews is the number of sessions during which the specified page was viewed at least once. A unique pageview is counted for each page URL + page Title combination.",
  txt_tooltip_bounce_rate: "The percentage of single-page sessions in which there was no interaction with the page. A bounced session has a duration of 0 seconds.",
  txt_tooltip_page_session: "The average number of pages viewed during a session. Repeated views of a single page are counted.",
  txt_tooltip_avg_session_duration: "The average length of a Session.",
  txt_menu_woocommerce: "Woocommerce",
  txt_add_to_cart: "Add To Cart",
  txt_search_product: "Search Product",
  txt_order: "Order",
  txt_product_name: "Product Name",
  txt_keywords: "Keywords",
  txt_count: "Count",
  txt_total: "Total",
  txt_current_visitors: "Current visitors",
  txt_unique_visitors: "Unique visitors",
  txt_locations: "Locations",
  txt_top_pages: "Top Pages",
  txt_map: "Map",
  txt_countries: "Countries",
  txt_os: "OS",
  txt_types: "Types"
};

// src/translations/es/common.json
var common_default3 = {
  txt_welcome_to: "Bienvenido a ",
  txt_email: "correo electr\xF3nico",
  txt_sign_in: "Registrarse",
  txt_sign_in_with_sso: "Regitrarse con SSO",
  txt_sign_up: "Inscribirse",
  txt_login_text_1: "Bienvenido a",
  txt_bi: "BI",
  txt_login_text_2: "Inicie sesi\xF3n para comenzar.",
  txt_do_not_have_an_account: "\xBFNo tiene una cuenta? Crea una cuenta gratis",
  txt_username: "Nombbre de usuario",
  txt_search_for_something: "busca algo...",
  txt_search_all_content: "Buscar todo el contenido",
  txt_help_center: "centro de ayuda",
  txt_sign_out: "Desconectar",
  txt_notifications: "Notificaciones",
  txt_client: "Cliente",
  txt_more: "M\xE1s",
  txt_menu_digital_assets: "Recursos digitales",
  txt_today: "Hoy",
  txt_general_information: "Informaci\xF3n general",
  txt_projectpage_password: "contrase\xF1a",
  txt_profile_picture: "Foto de perfil",
  txt_back_to_dashboard: "Volver al panel",
  txt_menu_profile: "Perfil",
  txt_Username: "Nombre de usuario",
  txt_Email: "correo electr\xF3nico",
  txt_Status: "Estado",
  txt_Birthday: "Cumplea\xF1os",
  txt_Phone: "Tel\xE9fono",
  txt_Address_1: "Direcci\xF3n 1",
  txt_Address_2: "Direcci\xF3n 2",
  txt_State: "Estado",
  txt_Country: "Pa\xEDs",
  txt_Countries: "Pa\xEDses",
  txt_Zipcode: "C\xF3digo postal",
  txt_Fullname: "Nombre completo",
  txt_City: "Ciudad",
  txt_Cities: "Ciudades",
  txt_current_password: "contrase\xF1a actual",
  txt_confirm_password: "Confirmar nueva contrase\xF1a",
  txt_new_password: "nueva contrase\xF1a",
  txt_update: "Actualizar",
  delete: "Borrar",
  choose_an_action: "Elige una acci\xF3n",
  txt_list: "lista",
  txt_thumb: "Miniatura",
  txt_show_more: "Mostrar m\xE1s",
  start_date: "Fecha inicial",
  end_date: "Fecha final",
  created_date: "Fecha de creaci\xF3n",
  txt_short_description: "Descripci\xF3n Breve",
  txt_short_description_about_project: "Breve descripci\xF3n sobre el proyecto.",
  txt_budget: "Presupuesto",
  txt_status: "Estado",
  txt_name_personas: "Nombre",
  updated_date: "Fecha actualizada",
  txt_title: "T\xEDtulo",
  txt_channels: "Canales",
  txt_date: "Fecha",
  txt_edit: "Editar",
  txt_general: "General",
  txt_save: "Gaurdar",
  txt_back: "Regresar",
  txt_next: "Siguiente",
  txt_connecting: "Conectando",
  txt_connect: "Conectar",
  txt_enable: "Habilitar",
  txt_remove: "Eliminar",
  txt_type: "Tipo",
  txt_action: "Acci\xF3n",
  txt_profile: "Perfil",
  txt_apply: "Aplicar",
  txt_video: "Video",
  txt_menu_dashboard: "Tablero",
  txt_menu_visitors: "Visitantes",
  txt_menu_overview: "Descripci\xF3n general",
  txt_menu_behavior: "Compartamiento",
  txt_menu_revenue: "Ingresos",
  txt_menu_subscription: "Subscripci\xF3n",
  txt_menu_member_roles: "Funciones de los miembros",
  txt_menu_data_stream: "Flujo de datos",
  txt_menu_region: "Regi\xF3n / Pa\xEDs",
  txt_menu_click_anchor: "Clic y ancla",
  txt_menu_utm_tracking: "Seguimiento UTM",
  txt_menu_events: "Event",
  txt_menu_member: "Miembros",
  txt_menu_import_export: "Importaci\xF3n / exportaci\xF3n",
  txt_menu_colection_transfer: "Transferencia de colecci\xF3n",
  txt_menu_setting: "Ajustes",
  txt_menu_help_center: "Centro de ayuda",
  txt_dashboard: "Tablero",
  txt_dashboard_below: "Aqu\xED est\xE1n sus detalles anal\xEDticos",
  txt_select_date: "Seleccionar fecha",
  txt_visitors: "Visitantes",
  txt_total_revenue: "Los ingresos totales",
  txt_sessions: "Sesiones",
  txt_conversion_rate: "Tasa de conversi\xF3n",
  txt_revenue_by_subscribers: "Ingresos por suscriptores",
  txt_new_registered_users: "Nuevos usuarios registrados",
  txt_continent: "Continente",
  txt_view_more: "Ver m\xE1s",
  txt_view_detail: "Ver Detalle",
  txt_views: "Vistas",
  txt_behavior: "Comportamiento",
  txt_behavior_clicking: "Comportamiento al hacer clic",
  txt_most_clingking_pages: "P\xE1ginas con m\xE1s clics",
  txt_gender: "G\xE9nero",
  txt_name: "Nombre",
  txt_size: "Tama\xF1o",
  txt_owner: "Propietario",
  txt_last_modified: "\xDAltima modificaci\xF3n",
  txt_image: "Imagen",
  txt_document: "Documento",
  txt_audio: "Audio",
  txt_preview: "vista previa",
  txt_move_to_folder: "Mover a carpeta",
  txt_download: "Descargar",
  txt_delete: "Borrar",
  txt_sort_by: "Ordenar por",
  txt_date_create: "Fecha de creaci\xF3n",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_trash: "Basura",
  txt_title_profilfe_setting: "Ajustes de perfil",
  txt_title_set_information_name: "Establezca su nombre y otra informaci\xF3n p\xFAblica",
  txt_cancel: "Cancelar",
  txt_your_avatar: "Su Avatar",
  txt_upload_file: "Subir archivo",
  txt_create_folder: "Crear carpeta",
  txt_main_menu: "Men\xFA principal",
  txt_set_up: "Configurar",
  txt_my_assets: "Mis activos",
  txt_download_folder: "Carpeta de descargas",
  txt_delete_folder: "Borrar carpeta",
  txt_are_you_sure: "\xBFEst\xE1 seguro?",
  txt_cancle: "Cancle",
  txt_yes_delete: "S\xED, Eliminar",
  txt_file: "ARCHIVOS",
  txt_storage: "Almacenamiento",
  txt_upgrade: "Potenciar",
  txt_configuration_storage: "Almacenamiento de configuraci\xF3n",
  txt_configuration_desc: "El tama\xF1o que se indica a continuaci\xF3n determina el tama\xF1o m\xE1ximo en p\xEDxeles que se utilizar\xE1 al agregar una imagen a la biblioteca multimedia.",
  txt_save_setting: "Guardar configuraci\xF3n",
  txt_client_id: "Identificaci\xF3n del cliente",
  txt_client_secret: "Secreto del cliente",
  txt_region: "Region",
  txt_bucket: "Contenedor",
  txt_drop_files_anywhere_to_upload: "Coloque los archivos en cualquier lugar para cargarlos",
  txt_or: "O",
  txt_select_file: "Seleccione Archivo",
  txt_save_update: "Guardar actualizaci\xF3n",
  txt_delete_assets_popup_desc: "\xBFEst\xE1 seguro de que desea eliminar este activo?",
  txt_delete_collections_popup_desc: "\xBFEst\xE1 seguro de que desea eliminar esta carpeta (incluidos los elementos secundarios y los activos)?",
  tx_forgot_password: "\xBFHa olvidado su contrase\xF1a?",
  txt_file_size: "Tama\xF1o",
  txt_url: "URL",
  txt_file_type: "Tipo",
  txt_sign_in_to_getting_started: "Inicie sesi\xF3n para comenzar.",
  txt_folders: "CARPETAS",
  txt_of: "de",
  txt_used: "us\xF3",
  txt_audience: "Audiencia",
  txt_analytic_details: "Aqu\xED est\xE1n sus detalles anal\xEDticos",
  txt_value: "Valor",
  txt_hours: "Horas",
  txt_days: "D\xEDas",
  txt_weeks: "Semanas",
  txt_months: "Meses",
  txt_page_views: "Vistas de p\xE1gina",
  txt_unique_page_views: "Vistas de p\xE1gina \xFAnicas",
  txt_acg_session_duration: "Promedio Duraci\xF3n de la sesi\xF3n",
  txt_bounce_rate: "Porcentaje de rebote",
  txt_page_session: "P\xE1gina/Sesi\xF3n",
  txt_in_total: "En total",
  txt_description: "Descripci\xF3n",
  txt_search: "Buscar",
  txt_date_range: "Rango de fechas",
  txt_devices: "Dispositivos",
  txt_choose_file: "Elija el archivo",
  "txt_select...": "Seleccione...",
  txt_all_users: "Todas las usuarias",
  txt_all: "Todas",
  txt_unique: "\xDAnica",
  txt_no_data: "Sin datos",
  txt_something_went_wrong_from_server_response: "Algo sali\xF3 mal en la respuesta del servidor",
  txt_warning: "Advertencia",
  txt_successful: "Exitoso",
  txt_error: "Error",
  txt_number: "Cantidad",
  txt_visitor_flow: "Flujo de visitantes",
  txt_domain: "Dominio",
  txt_location: "Ubicaci\xF3n",
  txt_duration: "Duraci\xF3n",
  txt_ip: "IP",
  txt_device: "Dispositivo",
  txt_browser: "Navegador",
  txt_language: "Linguagem",
  txt_page: "P\xE1gina",
  txt_page_title: "T\xEDtulo da p\xE1gina",
  txt_time_on_page: "Tempo na p\xE1gina",
  txt_menu_generator: "Generador",
  txt_utm_tracking_generator: "Generador de seguimiento UTM",
  txt_events_generator: "Generador de eventos",
  txt_export_pdf: "Exportar PDF",
  txt_tooltip_visitors: "N\xFAmero de visitantes",
  txt_tooltip_page_views: "Pageviews es el n\xFAmero total de p\xE1ginas vistas. Se cuentan las vistas repetidas de una sola p\xE1gina.",
  txt_tooltip_unique_page_views: "Vistas de p\xE1gina \xFAnicas es la cantidad de sesiones durante las cuales la p\xE1gina especificada se vio al menos una vez. Se cuenta una vista de p\xE1gina \xFAnica para cada combinaci\xF3n de URL de p\xE1gina + t\xEDtulo de p\xE1gina.",
  txt_tooltip_bounce_rate: "El porcentaje de sesiones de una sola p\xE1gina en las que no hubo interacci\xF3n con la p\xE1gina. Una sesi\xF3n rebotada tiene una duraci\xF3n de 0 segundos.",
  txt_tooltip_page_session: "El n\xFAmero promedio de p\xE1ginas vistas durante una sesi\xF3n. Se cuentan las vistas repetidas de una sola p\xE1gina.",
  txt_tooltip_avg_session_duration: "La duraci\xF3n promedio de una sesi\xF3n."
};

// src/translations/hr/common.json
var common_default4 = {
  txt_welcome_to: "Dobrodo\u0161li ",
  txt_email: "Email",
  txt_sign_in: "Prijavi se",
  txt_sign_in_with_sso: "Prijavi se sa SSO",
  txt_sign_up: "Registriraj se",
  txt_login_text_1: "Dobrodo\u0161li",
  txt_bi: "BI",
  txt_login_text_2: "Prijavite se da bi ste zapo\u010Deli.",
  txt_do_not_have_an_account: "Jo\u0161 nema\u0161 ra\u010Dun? Napravi besplatni ra\u010Dun",
  txt_username: "Korisni\u010Dlo ime",
  txt_search_for_something: "Pretra\u017Ei ...",
  txt_search_all_content: "Pretra\u017Ei sav sadr\u017Eaj",
  txt_help_center: "Centar za pomo\u0107",
  txt_sign_out: "Odjavi se",
  txt_notifications: "Obavijesti",
  txt_client: "Klijent",
  txt_more: "Vi\u0161e",
  txt_menu_digital_assets: "Digitalni materijali",
  txt_today: "Danas",
  txt_general_information: "Op\u0107e informacije",
  txt_projectpage_password: "Zaporka",
  txt_profile_picture: "Slika profila",
  txt_back_to_dashboard: "Natrag na nadzornu plo\u010Du",
  txt_menu_profile: "Profil",
  txt_Username: "Korisni\u010Dko ime",
  txt_Email: "Email",
  txt_Status: "Status",
  txt_Birthday: "Datum ro\u0111enja",
  txt_Phone: "Telefon",
  txt_Address_1: "Addresa 1",
  txt_Address_2: "Addresa 2",
  txt_State: "Pokrajna",
  txt_Country: "Dr\u017Eava",
  txt_Countries: "Zemlje",
  txt_Zipcode: "Po\u0161tanski broj",
  txt_Fullname: "Puno ime",
  txt_City: "Grad",
  txt_Cities: "Gradovi",
  txt_current_password: "Trenutna zaporka",
  txt_confirm_password: "Potvrdi novu zaporku",
  txt_new_password: "Nova zaporka",
  txt_update: "A\u017Euriraj",
  delete: "Izbri\u0161i",
  choose_an_action: "Izaberi akciju",
  txt_list: "Lista",
  txt_thumb: "Palac",
  txt_show_more: "Prika\u017Ei vi\u0161e",
  start_date: "Po\u010Detni datum",
  end_date: "Zavr\u0161ni datum",
  created_date: "Datum kreiranja",
  txt_short_description: "Kratki opis",
  txt_short_description_about_project: "Kratki opis o projektu",
  txt_budget: "Prora\u010Dun",
  txt_status: "Status",
  txt_name_personas: "Ime",
  updated_date: "Datum a\u017Euriraja",
  txt_title: "Naslov",
  txt_channels: "Kanali",
  txt_date: "Datum",
  txt_edit: "Uredi",
  txt_general: "Op\u0107e",
  txt_save: "Spremi",
  txt_back: "Natrag",
  txt_next: "Sljede\u0107e",
  txt_connecting: "Spajanje",
  txt_connect: "Spojen",
  txt_enable: "Omogu\u0107i",
  txt_remove: "Ukloni",
  txt_type: "Vrsta",
  txt_action: "Akcija",
  txt_profile: "Profil",
  txt_apply: "Primjeni",
  txt_video: "Video",
  txt_menu_dashboard: "Nadzorna plo\u010Da",
  txt_menu_visitors: "Posjetitelji",
  txt_menu_overview: "Pregled",
  txt_menu_behavior: "Pona\u010Danje",
  txt_menu_revenue: "Prihod",
  txt_menu_subscription: "Pretplata",
  txt_menu_member_roles: "Uloge \u010Dlanova",
  txt_menu_data_stream: "Tok podataka",
  txt_menu_region: "Pokrajna / Dr\u017Eava",
  txt_menu_click_anchor: "Klikni i usidri",
  txt_menu_utm_tracking: "UTM Pra\u0107enje",
  txt_menu_events: "Event",
  txt_menu_member: "\u010Clanovi",
  txt_menu_import_export: "Uvezi/Izvezi",
  txt_menu_colection_transfer: "Prijenos kolekcije",
  txt_menu_setting: "Postavke",
  txt_menu_help_center: "Centar za pomo\u0107",
  txt_dashboard: "Nadzorna plo\u0107a",
  txt_dashboard_below: "Ovdje su va\u0161i analiti\u010Dki detalji",
  txt_select_date: "Izaberi datum",
  txt_visitors: "Posjetitelji",
  txt_total_revenue: "Ukupni prihod",
  txt_sessions: "Sessions",
  txt_conversion_rate: "Stopa pretvorbe",
  txt_revenue_by_subscribers: "Prihodi po pretplatniku",
  txt_new_registered_users: "Novi registrirani korisnici",
  txt_continent: "Kontinent",
  txt_view_more: "Vidi vi\u0161e",
  txt_view_detail: "Vidi detalje",
  txt_views: "Pregledi",
  txt_behavior: "Pona\u0161anje",
  txt_behavior_clicking: "Pona\u0161anje klikanjem",
  txt_most_clingking_pages: "Najvi\u0161e klikanih stranica",
  txt_gender: "Spol",
  txt_name: "Ime",
  txt_size: "Veli\u010Dina",
  txt_owner: "Vlasnik",
  txt_last_modified: "Zadnje promjenjeno",
  txt_image: "Slika",
  txt_document: "Dokument",
  txt_audio: "Zvuk",
  txt_preview: "Pretpregled",
  txt_move_to_folder: "Premjesti u mapu",
  txt_download: "Preuzmi",
  txt_delete: "Izbri\u0161i",
  txt_sort_by: "Sortiraj prema",
  txt_date_create: "Datum kreiranja",
  txt_a_z: "A - \u017D",
  txt_z_a: "\u017D - A",
  txt_menu_trash: "Sme\u0107e",
  txt_title_profilfe_setting: "Potavke profila",
  txt_title_set_information_name: "Postavite svoje ime i druge podatke za javnost",
  txt_cancel: "Otka\u017Ei",
  txt_your_avatar: "Va\u0161 Avatar",
  txt_upload_file: "Prenesi datoteku",
  txt_create_folder: "Kreiraj mapu",
  txt_main_menu: "Glavni izbornik",
  txt_set_up: "Postavi se",
  txt_my_assets: "Moji materijali",
  txt_download_folder: "Mapa za preuzimanje",
  txt_delete_folder: "Izbri\u0161i mapu",
  txt_are_you_sure: "Da li ste sigurni?",
  txt_cancle: "Otka\u017Ei",
  txt_yes_delete: "Da, izbri\u0161i.",
  txt_file: "DATOTEKE",
  txt_storage: "Spremi\u0161te",
  txt_upgrade: "Nadogradi",
  txt_configuration_storage: "Konfiguracija spremi\u0161ta",
  txt_configuration_desc: "Veli\u010Dina navedena u nastavku odre\u0111uje maksimalnu veli\u010Dinu u pikselima koja se koristi prilikom dodavanja slike u biblioteku medija",
  txt_save_setting: "Spremi postavke",
  txt_client_id: "ID Klijenta",
  txt_client_secret: "Tajna Klijenta",
  txt_region: "Regija",
  txt_bucket: "Kanta",
  txt_drop_files_anywhere_to_upload: "Ispustite datoteke bilo gdje za prijenos",
  txt_or: "Ili",
  txt_select_file: "Izaberi datoteku",
  txt_save_update: "Spremi a\u017Euriranje",
  txt_delete_assets_popup_desc: "Jeste li sigurni da \u017Eelite izbrisati ovaj materijal ?",
  txt_delete_collections_popup_desc: "Jeste li sigurni da \u017Eelite izbrisati ovu mapu (uklju\u010Duju\u0107i podre\u0111ene mape i materijale) ?",
  tx_forgot_password: "Zaboravili ste zaporku?",
  txt_file_size: "Veli\u010Dina",
  txt_url: "URL",
  txt_file_type: "Vrsta",
  txt_sign_in_to_getting_started: "Prijavite se da bi ste zapo\u010Deli.",
  txt_folders: "MAPE",
  txt_of: "od",
  txt_used: "koristi",
  txt_audience: "Publika",
  txt_analytic_details: "Ovdje su va\u0161i analiti\u010Dki detalji",
  txt_value: "Vrijednost",
  txt_hours: "Sati",
  txt_days: "Dani",
  txt_weeks: "Tjedni",
  txt_months: "Mjeseci",
  txt_page_views: "Pregledi stranice",
  txt_unique_page_views: "Jedinstveni prikazi stranica",
  txt_acg_session_duration: "Prosje\u010Dna duljina posjete",
  txt_bounce_rate: "Stopa napu\u0161tanja po\u010Detne stranice",
  txt_page_session: "Stranica/sesija",
  txt_in_total: "Ukupno",
  txt_description: "Opis",
  txt_search: "Tra\u017Eiti",
  txt_date_range: "Raspon datuma",
  txt_devices: "Ure\u0111aji",
  txt_choose_file: "Izaberi datoteku",
  "txt_select...": "Odaberi...",
  txt_all_users: "Svi korisnici",
  txt_all: "Svi",
  txt_unique: "Jedinstvena",
  txt_no_data: "Nema podataka",
  txt_something_went_wrong_from_server_response: "Ne\u0161to nije u redu s odgovorom poslu\u017Eitelja",
  txt_warning: "Upozorenje",
  txt_successful: "Uspje\u0161no",
  txt_error: "Gre\u0161ka",
  txt_number: "Koli\u010Dina",
  txt_visitor_flow: "Tijek posjetitelja",
  txt_domain: "Domena",
  txt_location: "Mjesto",
  txt_duration: "Trajanje",
  txt_ip: "IP",
  txt_device: "Ure\u0111aj",
  txt_browser: "preglednik",
  txt_language: "Jezik",
  txt_page: "Stranica",
  txt_page_title: "Naslov stranice",
  txt_time_on_page: "Vrijeme na stranici",
  txt_menu_generator: "Generador",
  txt_utm_tracking_generator: "UTM generator pra\u0107enja",
  txt_events_generator: "Generator doga\u0111aja",
  txt_export_pdf: "Izvezi PDF",
  txt_tooltip_visitors: "Broj posjetitelja",
  txt_tooltip_page_views: "Pageviews je ukupan broj pregledanih stranica. Broje se ponovljeni prikazi jedne stranice.",
  txt_tooltip_unique_page_views: "Jedinstveni prikazi stranice broj su sesija tijekom kojih je odre\u0111ena stranica pogledana barem jednom. Jedinstveni prikaz stranice broji se za svaku kombinaciju URL stranice + naslov stranice.",
  txt_tooltip_bounce_rate: "Postotak sesija s jednom stranicom u kojima nije bilo interakcije sa stranicom. Odsko\u010Dna sesija traje 0 sekundi.",
  txt_tooltip_page_session: "Prosje\u010Dan broj pregledanih stranica tijekom sesije. Broje se ponovljeni prikazi jedne stranice.",
  txt_tooltip_avg_session_duration: "Prosje\u010Dna duljina sesije."
};

// src/translations/th/common.json
var common_default5 = {
  txt_welcome_to: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E19\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48 ",
  txt_email: "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  txt_sign_in: "\u0E40\u0E02\u0E49\u0E32\u0E23\u0E30\u0E1A\u0E1A",
  txt_sign_in_with_sso: "\u0E40\u0E02\u0E49\u0E32\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22 SSO",
  txt_sign_up: "\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19",
  txt_login_text_1: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E07\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48",
  txt_bi: "BI",
  txt_login_text_2: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_do_not_have_an_account: "\u0E22\u0E31\u0E07\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49? \u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E44\u0E14\u0E49\u0E1F\u0E23\u0E35",
  txt_username: "\u0E0A\u0E37\u0E48\u0E2D\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49",
  txt_search_for_something: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E1A\u0E32\u0E07\u0E2D\u0E22\u0E48\u0E32\u0E07 ...",
  txt_search_all_content: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E43\u0E19\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_help_center: "\u0E28\u0E39\u0E19\u0E22\u0E4C\u0E43\u0E2B\u0E49\u0E04\u0E27\u0E32\u0E21\u0E0A\u0E48\u0E27\u0E22\u0E40\u0E2B\u0E25\u0E37\u0E2D",
  txt_sign_out: "\u0E2D\u0E2D\u0E01\u0E08\u0E32\u0E01\u0E23\u0E30\u0E1A\u0E1A",
  txt_notifications: "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  txt_client: "\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32",
  txt_more: "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E40\u0E15\u0E34\u0E21",
  txt_menu_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_today: "\u0E27\u0E31\u0E19\u0E19\u0E35\u0E49",
  txt_general_information: "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E17\u0E31\u0E48\u0E27\u0E44\u0E1B",
  txt_projectpage_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_profile_picture: "\u0E23\u0E39\u0E1B\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_back_to_dashboard: "\u0E01\u0E25\u0E31\u0E1A\u0E44\u0E1B\u0E22\u0E31\u0E07\u0E41\u0E1C\u0E07\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21",
  txt_menu_profile: "\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_Username: "\u0E0A\u0E37\u0E48\u0E2D\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49",
  txt_Email: "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  txt_Status: "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  txt_Birthday: "\u0E27\u0E31\u0E19\u0E40\u0E01\u0E34\u0E14",
  txt_Phone: "\u0E40\u0E1A\u0E2D\u0E23\u0E4C\u0E42\u0E17\u0E23",
  txt_Address_1: "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 1",
  txt_Address_2: "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 2",
  txt_State: "\u0E08\u0E31\u0E07\u0E2B\u0E27\u0E31\u0E14",
  txt_Country: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  txt_Countries: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  txt_Zipcode: "\u0E23\u0E2B\u0E31\u0E2A\u0E44\u0E1B\u0E23\u0E29\u0E13\u0E35\u0E22\u0E4C",
  txt_Fullname: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E15\u0E47\u0E21",
  txt_City: "\u0E40\u0E02\u0E15/\u0E2D\u0E33\u0E40\u0E20\u0E2D",
  txt_Cities: "\u0E40\u0E02\u0E15/\u0E2D\u0E33\u0E40\u0E20\u0E2D",
  txt_current_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19",
  txt_confirm_password: "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19 \u0E23\u0E2B\u0E31\u0E2A\u0E43\u0E2B\u0E21\u0E48",
  txt_new_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E43\u0E2B\u0E21\u0E48",
  txt_update: "\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15",
  delete: "\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19",
  choose_an_action: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23",
  txt_list: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23",
  txt_thumb: "Thumb",
  txt_show_more: "\u0E41\u0E2A\u0E14\u0E07\u0E40\u0E1E\u0E34\u0E48\u0E21",
  start_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E40\u0E23\u0E34\u0E48\u0E21",
  end_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E34\u0E49\u0E19\u0E2A\u0E38\u0E14",
  created_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E23\u0E49\u0E32\u0E07",
  txt_short_description: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E22\u0E48\u0E2D",
  txt_short_description_about_project: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E22\u0E48\u0E2D\u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E01\u0E31\u0E1A\u0E42\u0E1B\u0E23\u0E40\u0E08\u0E04",
  txt_budget: "\u0E07\u0E1A\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13",
  txt_status: "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  txt_name_personas: "\u0E0A\u0E37\u0E48\u0E2D",
  updated_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07",
  txt_title: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E37\u0E48\u0E2D\u0E07",
  txt_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  txt_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48",
  txt_edit: "\u0E41\u0E01\u0E49\u0E44\u0E02",
  txt_general: "\u0E17\u0E31\u0E48\u0E27\u0E44\u0E1B",
  txt_save: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01",
  txt_back: "\u0E01\u0E25\u0E31\u0E1A",
  txt_next: "\u0E15\u0E48\u0E2D\u0E44\u0E1B",
  txt_connecting: "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_connect: "\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21",
  txt_enable: "\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_remove: "\u0E40\u0E2D\u0E32\u0E2D\u0E2D\u0E01",
  txt_type: "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17",
  txt_action: "\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23",
  txt_profile: "\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_apply: "\u0E19\u0E33\u0E44\u0E1B\u0E43\u0E0A\u0E49",
  txt_video: "\u0E27\u0E35\u0E14\u0E35\u0E42\u0E2D",
  txt_menu_dashboard: "\u0E41\u0E1C\u0E07\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21",
  txt_menu_visitors: "\u0E1C\u0E39\u0E49\u0E40\u0E22\u0E35\u0E48\u0E22\u0E21\u0E0A\u0E21",
  txt_menu_overview: "\u0E20\u0E32\u0E1E\u0E23\u0E27\u0E21",
  txt_menu_behavior: "\u0E1E\u0E24\u0E15\u0E34\u0E01\u0E23\u0E23\u0E21",
  txt_menu_revenue: "\u0E23\u0E32\u0E22\u0E44\u0E14\u0E49",
  txt_menu_subscription: "\u0E01\u0E32\u0E23\u0E2A\u0E21\u0E31\u0E04\u0E23\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_menu_member_roles: "\u0E1A\u0E17\u0E1A\u0E32\u0E17\u0E02\u0E2D\u0E07\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_menu_data_stream: "\u0E01\u0E23\u0E30\u0E41\u0E2A\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25",
  txt_menu_region: "\u0E20\u0E39\u0E21\u0E34\u0E20\u0E32\u0E04/\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  txt_menu_click_anchor: "\u0E04\u0E25\u0E34\u0E01 & \u0E1B\u0E31\u0E01\u0E2B\u0E21\u0E38\u0E14",
  txt_menu_utm_tracking: "UTM Tracking",
  txt_menu_events: "Event",
  txt_menu_member: "\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_menu_import_export: "\u0E19\u0E33\u0E40\u0E02\u0E49\u0E32/\u0E2A\u0E48\u0E07\u0E2D\u0E2D\u0E01",
  txt_menu_colection_transfer: "\u0E01\u0E32\u0E23\u0E42\u0E2D\u0E19\u0E04\u0E2D\u0E25\u0E40\u0E25\u0E01\u0E0A\u0E31\u0E19",
  txt_menu_setting: "\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32",
  txt_menu_help_center: "\u0E28\u0E39\u0E19\u0E22\u0E4C\u0E43\u0E2B\u0E49\u0E04\u0E27\u0E32\u0E21\u0E0A\u0E48\u0E27\u0E22\u0E40\u0E2B\u0E25\u0E37\u0E2D",
  txt_dashboard: "\u0E41\u0E1C\u0E07\u0E04\u0E27\u0E32\u0E21\u0E04\u0E38\u0E21",
  txt_dashboard_below: "\u0E19\u0E35\u0E48\u0E04\u0E37\u0E2D\u0E23\u0E32\u0E22\u0E25\u0E30\u0E40\u0E2D\u0E35\u0E22\u0E14\u0E01\u0E32\u0E23\u0E27\u0E34\u0E40\u0E04\u0E23\u0E32\u0E30\u0E2B\u0E4C\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_select_date: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48",
  txt_visitors: "\u0E1C\u0E39\u0E49\u0E40\u0E22\u0E35\u0E48\u0E22\u0E21\u0E0A\u0E21",
  txt_total_revenue: "\u0E23\u0E32\u0E22\u0E44\u0E14\u0E49\u0E23\u0E27\u0E21",
  txt_sessions: "\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E48\u0E19",
  txt_conversion_rate: "\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E01\u0E32\u0E23\u0E41\u0E1B\u0E25\u0E07",
  txt_revenue_by_subscribers: "\u0E23\u0E32\u0E22\u0E44\u0E14\u0E49\u0E15\u0E32\u0E21\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_new_registered_users: "\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E17\u0E35\u0E48\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19\u0E43\u0E2B\u0E21\u0E48",
  txt_continent: "\u0E17\u0E27\u0E35\u0E1B",
  txt_view_more: "\u0E14\u0E39\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E40\u0E15\u0E34\u0E21",
  txt_view_detail: "\u0E14\u0E39\u0E23\u0E32\u0E22\u0E25\u0E30\u0E40\u0E2D\u0E35\u0E22\u0E14",
  txt_views: "\u0E14\u0E39",
  txt_behavior: "\u0E1E\u0E24\u0E15\u0E34\u0E01\u0E23\u0E23\u0E21",
  txt_behavior_clicking: "\u0E1E\u0E24\u0E15\u0E34\u0E01\u0E23\u0E23\u0E21\u0E01\u0E32\u0E23\u0E04\u0E25\u0E34\u0E01",
  txt_most_clingking_pages: "\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E27\u0E47\u0E1A\u0E17\u0E35\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E04\u0E25\u0E34\u0E01\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14",
  txt_gender: "\u0E40\u0E1E\u0E28",
  txt_name: "\u0E0A\u0E37\u0E48\u0E2D",
  txt_size: "\u0E02\u0E19\u0E32\u0E14",
  txt_owner: "\u0E40\u0E08\u0E49\u0E32\u0E02\u0E2D\u0E07",
  txt_last_modified: "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E25\u0E48\u0E32\u0E2A\u0E38\u0E14",
  txt_image: "\u0E20\u0E32\u0E1E",
  txt_document: "\u0E40\u0E2D\u0E01\u0E2A\u0E32\u0E23",
  txt_audio: "\u0E44\u0E1F\u0E25\u0E4C\u0E40\u0E2A\u0E35\u0E22\u0E07",
  txt_preview: "\u0E14\u0E39\u0E15\u0E31\u0E27\u0E2D\u0E22\u0E48\u0E32\u0E07",
  txt_move_to_folder: "\u0E22\u0E49\u0E32\u0E22\u0E44\u0E1B\u0E22\u0E31\u0E07\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_download: "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14",
  txt_delete: "\u0E25\u0E1A",
  txt_sort_by: "\u0E40\u0E23\u0E35\u0E22\u0E07\u0E15\u0E32\u0E21",
  txt_date_create: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E23\u0E49\u0E32\u0E07",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_trash: "\u0E16\u0E31\u0E07\u0E02\u0E22\u0E30",
  txt_title_profilfe_setting: "\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_title_set_information_name: "\u0E15\u0E31\u0E49\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E41\u0E25\u0E30\u0E40\u0E1B\u0E34\u0E14\u0E40\u0E1C\u0E22\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2D\u0E37\u0E48\u0E19\u0E46 \u0E15\u0E48\u0E2D\u0E2A\u0E32\u0E18\u0E32\u0E23\u0E13\u0E30",
  txt_cancel: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
  txt_your_avatar: "\u0E2D\u0E27\u0E15\u0E32\u0E23\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_upload_file: "\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C",
  txt_create_folder: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_main_menu: "\u0E40\u0E21\u0E19\u0E39\u0E2B\u0E25\u0E31\u0E01",
  txt_set_up: "\u0E15\u0E34\u0E14\u0E15\u0E31\u0E49\u0E07",
  txt_my_assets: "\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E2A\u0E34\u0E19\u0E02\u0E2D\u0E07\u0E09\u0E31\u0E19",
  txt_download_folder: "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_delete_folder: "\u0E25\u0E1A\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_are_you_sure: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E19\u0E30?",
  txt_cancle: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
  txt_yes_delete: "\u0E43\u0E0A\u0E48, \u0E25\u0E1A",
  txt_file: "\u0E44\u0E1F\u0E25\u0E4C",
  txt_storage: "\u0E1E\u0E37\u0E49\u0E19\u0E17\u0E35\u0E48\u0E08\u0E31\u0E14\u0E40\u0E01\u0E47\u0E1A",
  txt_upgrade: "\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14",
  txt_configuration_storage: "\u0E17\u0E35\u0E48\u0E40\u0E01\u0E47\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32",
  txt_configuration_desc: "\u0E02\u0E19\u0E32\u0E14\u0E17\u0E35\u0E48\u0E41\u0E2A\u0E14\u0E07\u0E14\u0E49\u0E32\u0E19\u0E25\u0E48\u0E32\u0E07\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E02\u0E19\u0E32\u0E14\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E40\u0E1B\u0E47\u0E19\u0E1E\u0E34\u0E01\u0E40\u0E0B\u0E25\u0E17\u0E35\u0E48\u0E08\u0E30\u0E43\u0E0A\u0E49\u0E40\u0E21\u0E37\u0E48\u0E2D\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E\u0E25\u0E07\u0E43\u0E19\u0E44\u0E25\u0E1A\u0E23\u0E32\u0E23\u0E35\u0E2A\u0E37\u0E48\u0E2D",
  txt_save_setting: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01 \u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32",
  txt_client_id: "Client ID",
  txt_client_secret: "Client Secret",
  txt_region: "\u0E20\u0E39\u0E21\u0E34\u0E20\u0E32\u0E04",
  txt_bucket: "\u0E16\u0E31\u0E07",
  txt_drop_files_anywhere_to_upload: "\u0E27\u0E32\u0E07\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E43\u0E14\u0E01\u0E47\u0E44\u0E14\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14",
  txt_or: "\u0E2B\u0E23\u0E37\u0E2D",
  txt_select_file: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E1F\u0E25\u0E4C",
  txt_save_update: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07",
  txt_delete_assets_popup_desc: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E19\u0E35\u0E49 ?",
  txt_delete_collections_popup_desc: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C\u0E19\u0E35\u0E49 (\u0E23\u0E27\u0E21\u0E16\u0E36\u0E07\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E22\u0E48\u0E2D\u0E22\u0E41\u0E25\u0E30\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32) ?",
  tx_forgot_password: "\u0E25\u0E37\u0E21\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19?",
  txt_file_size: "\u0E02\u0E19\u0E32\u0E14",
  txt_url: "URL",
  txt_file_type: "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17",
  txt_sign_in_to_getting_started: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_folders: "\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_of: "\u0E02\u0E2D\u0E07",
  txt_used: "\u0E43\u0E0A\u0E49",
  txt_audience: "\u0E1C\u0E39\u0E49\u0E0A\u0E21",
  txt_analytic_details: "\u0E19\u0E35\u0E48\u0E04\u0E37\u0E2D\u0E23\u0E32\u0E22\u0E25\u0E30\u0E40\u0E2D\u0E35\u0E22\u0E14\u0E01\u0E32\u0E23\u0E27\u0E34\u0E40\u0E04\u0E23\u0E32\u0E30\u0E2B\u0E4C\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_value: "\u0E04\u0E48\u0E32",
  txt_hours: "\u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07",
  txt_days: "\u0E27\u0E31\u0E19",
  txt_weeks: "\u0E2A\u0E31\u0E1B\u0E14\u0E32\u0E2B\u0E4C",
  txt_months: "\u0E40\u0E14\u0E37\u0E2D\u0E19",
  txt_page_views: "\u0E40\u0E1E\u0E08\u0E27\u0E34\u0E27",
  txt_unique_page_views: "\u0E01\u0E32\u0E23\u0E14\u0E39\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E27\u0E47\u0E1A\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E0B\u0E49\u0E33",
  txt_acg_session_duration: "\u0E40\u0E09\u0E25\u0E35\u0E48\u0E22 \u0E23\u0E30\u0E22\u0E30\u0E40\u0E27\u0E25\u0E32\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E19",
  txt_bounce_rate: "\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E15\u0E35\u0E01\u0E25\u0E31\u0E1A",
  txt_page_session: "\u0E40\u0E1E\u0E08/\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E48\u0E19",
  txt_in_total: "\u0E08\u0E32\u0E01\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_description: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22",
  txt_search: "\u0E04\u0E49\u0E19\u0E2B\u0E32",
  txt_date_range: "\u0E0A\u0E48\u0E27\u0E07\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48",
  txt_devices: "\u0E2D\u0E38\u0E1B\u0E01\u0E23\u0E13\u0E4C",
  txt_choose_file: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E1F\u0E25\u0E4C",
  "txt_select...": "\u0E40\u0E25\u0E37\u0E2D\u0E01...",
  txt_all_users: "\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_all: "\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_unique: "\u0E21\u0E35\u0E40\u0E2D\u0E01\u0E25\u0E31\u0E01\u0E29\u0E13\u0E4C",
  txt_no_data: "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25",
  txt_something_went_wrong_from_server_response: "\u0E21\u0E35\u0E1A\u0E32\u0E07\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14\u0E08\u0E32\u0E01\u0E01\u0E32\u0E23\u0E15\u0E2D\u0E1A\u0E2A\u0E19\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E40\u0E0B\u0E34\u0E23\u0E4C\u0E1F\u0E40\u0E27\u0E2D\u0E23\u0E4C",
  txt_warning: "\u0E04\u0E33\u0E40\u0E15\u0E37\u0E2D\u0E19",
  txt_successful: "\u0E1B\u0E23\u0E30\u0E2A\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  txt_error: "\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14",
  txt_number: "\u0E1B\u0E23\u0E34\u0E21\u0E32\u0E13",
  txt_visitor_flow: "\u0E01\u0E32\u0E23\u0E44\u0E2B\u0E25\u0E02\u0E2D\u0E07\u0E1C\u0E39\u0E49\u0E40\u0E02\u0E49\u0E32\u0E0A\u0E21",
  txt_domain: "\u0E42\u0E14\u0E40\u0E21\u0E19",
  txt_location: "\u0E17\u0E35\u0E48\u0E15\u0E31\u0E49\u0E07",
  txt_duration: "\u0E23\u0E30\u0E22\u0E30\u0E40\u0E27\u0E25\u0E32",
  txt_ip: "IP",
  txt_device: "\u0E2D\u0E38\u0E1B\u0E01\u0E23\u0E13\u0E4C",
  txt_browser: "\u0E40\u0E1A\u0E23\u0E32\u0E27\u0E4C\u0E40\u0E0B\u0E2D\u0E23\u0E4C",
  txt_language: "\u0E20\u0E32\u0E29\u0E32",
  txt_page: "\u0E2B\u0E19\u0E49\u0E32\u0E2B\u0E19\u0E31\u0E07\u0E2A\u0E37\u0E2D",
  txt_page_title: "\u0E0A\u0E37\u0E48\u0E2D\u0E2B\u0E19\u0E49\u0E32",
  txt_time_on_page: "\u0E40\u0E27\u0E25\u0E32\u0E1A\u0E19\u0E2B\u0E19\u0E49\u0E32",
  txt_menu_generator: "\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E01\u0E33\u0E40\u0E19\u0E34\u0E14\u0E23\u0E2B\u0E31\u0E2A",
  txt_utm_tracking_generator: "\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E21\u0E37\u0E2D\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E01\u0E32\u0E23\u0E15\u0E34\u0E14\u0E15\u0E32\u0E21 UTM",
  txt_events_generator: "\u0E15\u0E31\u0E27\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E2B\u0E15\u0E38\u0E01\u0E32\u0E23\u0E13\u0E4C",
  txt_export_pdf: "\u0E2A\u0E48\u0E07\u0E2D\u0E2D\u0E01 PDF",
  txt_tooltip_visitors: "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E1C\u0E39\u0E49\u0E40\u0E02\u0E49\u0E32\u0E0A\u0E21",
  txt_tooltip_page_views: "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E2B\u0E19\u0E49\u0E32\u0E17\u0E35\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E40\u0E1B\u0E34\u0E14\u0E04\u0E37\u0E2D\u0E08\u0E33\u0E19\u0E27\u0E19\u0E2B\u0E19\u0E49\u0E32\u0E17\u0E35\u0E48\u0E14\u0E39\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14 \u0E01\u0E32\u0E23\u0E14\u0E39\u0E0B\u0E49\u0E33\u0E02\u0E2D\u0E07\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E14\u0E35\u0E22\u0E27\u0E08\u0E30\u0E16\u0E39\u0E01\u0E19\u0E31\u0E1A",
  txt_tooltip_unique_page_views: "\u0E01\u0E32\u0E23\u0E40\u0E1B\u0E34\u0E14\u0E14\u0E39\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E27\u0E47\u0E1A\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E0B\u0E49\u0E33\u0E04\u0E37\u0E2D\u0E08\u0E33\u0E19\u0E27\u0E19\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E19\u0E17\u0E35\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E14\u0E39\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E27\u0E47\u0E1A\u0E17\u0E35\u0E48\u0E23\u0E30\u0E1A\u0E38\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E19\u0E49\u0E2D\u0E22\u0E2B\u0E19\u0E36\u0E48\u0E07\u0E04\u0E23\u0E31\u0E49\u0E07 \u0E01\u0E32\u0E23\u0E14\u0E39\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E27\u0E47\u0E1A\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E0B\u0E49\u0E33\u0E01\u0E31\u0E19\u0E08\u0E30\u0E16\u0E39\u0E01\u0E19\u0E31\u0E1A\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E0A\u0E38\u0E14\u0E04\u0E48\u0E32\u0E1C\u0E2A\u0E21\u0E02\u0E2D\u0E07 URL \u0E02\u0E2D\u0E07\u0E2B\u0E19\u0E49\u0E32 + \u0E0A\u0E37\u0E48\u0E2D\u0E2B\u0E19\u0E49\u0E32\u0E41\u0E15\u0E48\u0E25\u0E30\u0E0A\u0E38\u0E14",
  txt_tooltip_bounce_rate: "\u0E40\u0E1B\u0E2D\u0E23\u0E4C\u0E40\u0E0B\u0E47\u0E19\u0E15\u0E4C\u0E02\u0E2D\u0E07\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E19\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E14\u0E35\u0E22\u0E27\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E42\u0E15\u0E49\u0E15\u0E2D\u0E1A\u0E01\u0E31\u0E1A\u0E2B\u0E19\u0E49\u0E32\u0E19\u0E31\u0E49\u0E19 \u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E19\u0E17\u0E35\u0E48\u0E15\u0E35\u0E01\u0E25\u0E31\u0E1A\u0E21\u0E35\u0E23\u0E30\u0E22\u0E30\u0E40\u0E27\u0E25\u0E32 0 \u0E27\u0E34\u0E19\u0E32\u0E17\u0E35",
  txt_tooltip_page_session: "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E2B\u0E19\u0E49\u0E32\u0E17\u0E35\u0E48\u0E14\u0E39\u0E42\u0E14\u0E22\u0E40\u0E09\u0E25\u0E35\u0E48\u0E22\u0E23\u0E30\u0E2B\u0E27\u0E48\u0E32\u0E07\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E19 \u0E01\u0E32\u0E23\u0E14\u0E39\u0E0B\u0E49\u0E33\u0E02\u0E2D\u0E07\u0E2B\u0E19\u0E49\u0E32\u0E40\u0E14\u0E35\u0E22\u0E27\u0E08\u0E30\u0E16\u0E39\u0E01\u0E19\u0E31\u0E1A",
  txt_tooltip_avg_session_duration: "\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27\u0E40\u0E09\u0E25\u0E35\u0E48\u0E22\u0E02\u0E2D\u0E07\u0E40\u0E0B\u0E2A\u0E0A\u0E31\u0E19"
};

// src/translations/ua/common.json
var common_default6 = {
  txt_welcome_to: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0434\u043E ",
  txt_email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430",
  txt_sign_in: "\u0423\u0432\u0456\u0439\u0442\u0438",
  txt_sign_in_with_sso: "\u0423\u0432\u0456\u0439\u0442\u0438 \u0437\u0430 \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u043E\u044E SSO",
  txt_sign_up: "\u0417\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0432\u0430\u0442\u0438\u0441\u044F",
  txt_login_text_1: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0434\u043E",
  txt_bi: "BI",
  txt_login_text_2: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438 \u0440\u043E\u0431\u043E\u0442\u0443.",
  txt_do_not_have_an_account: "\u041D\u0435 \u043C\u0430\u0454\u0442\u0435 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u043E\u0433\u043E \u0437\u0430\u043F\u0438\u0441\u0443? \u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u0431\u0435\u0437\u043A\u043E\u0448\u0442\u043E\u0432\u043D\u0438\u0439 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441",
  txt_username: "\u0406\u043C'\u044F \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430",
  txt_search_for_something: "\u0428\u0443\u043A\u0430\u0442\u0438 \u0449\u043E\u0441\u044C ...",
  txt_search_all_content: "\u0428\u0443\u043A\u0430\u0442\u0438 \u0432\u0435\u0441\u044C \u0432\u043C\u0456\u0441\u0442",
  txt_help_center: "\u0434\u043E\u0432\u0456\u0434\u043A\u043E\u0432\u0438\u0439 \u0446\u0435\u043D\u0442\u0440",
  txt_sign_out: "\u0412\u0438\u0439\u0442\u0438",
  txt_notifications: "\u0421\u043F\u043E\u0432\u0456\u0449\u0435\u043D\u043D\u044F",
  txt_client: "\u041A\u043B\u0456\u0454\u043D\u0442",
  txt_more: "\u0411\u0456\u043B\u044C\u0448\u0435",
  txt_menu_digital_assets: "\u0426\u0438\u0444\u0440\u043E\u0432\u0456 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_today: "\u0421\u044C\u043E\u0433\u043E\u0434\u043D\u0456",
  txt_general_information: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0430 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044F",
  txt_projectpage_password: "\u041F\u0430\u0440\u043E\u043B\u044C",
  txt_profile_picture: "\u0417\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F \u043F\u0440\u043E\u0444\u0456\u043B\u044E",
  txt_back_to_dashboard: "\u041D\u0430\u0437\u0430\u0434 \u0434\u043E \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u043E\u0457 \u043F\u0430\u043D\u0435\u043B\u0456",
  txt_menu_profile: "\u041F\u0440\u043E\u0444\u0456\u043B\u044C",
  txt_Username: "\u0406\u043C'\u044F \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430",
  txt_Email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430",
  txt_Status: "\u0421\u0442\u0430\u043D",
  txt_Birthday: "\u0414\u0435\u043D\u044C \u043D\u0430\u0440\u043E\u0434\u0436\u0435\u043D\u043D\u044F",
  txt_Phone: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
  txt_Address_1: "\u0410\u0434\u0440\u0435\u0441\u0430 1",
  txt_Address_2: "\u0410\u0434\u0440\u0435\u0441\u0430 2",
  txt_State: "\u0421\u0442\u0430\u043D",
  txt_Country: "\u041A\u0440\u0430\u0457\u043D\u0430",
  txt_Countries: "\u041A\u0440\u0430\u0457\u043D\u0438",
  txt_Zipcode: "\u041F\u043E\u0448\u0442\u043E\u0432\u0438\u0439 \u0456\u043D\u0434\u0435\u043A\u0441",
  txt_Fullname: "\u041F\u043E\u0432\u043D\u0435 \u0456\u043C'\u044F",
  txt_City: "\u041C\u0456\u0441\u0442\u043E",
  txt_Cities: "\u041C\u0456\u0441\u0442\u0430",
  txt_current_password: "\u041F\u043E\u0442\u043E\u0447\u043D\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_confirm_password: "\u041F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0438 \u043D\u043E\u0432\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_new_password: "\u041D\u043E\u0432\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_update: "\u041E\u043D\u043E\u0432\u0438\u0442\u0438",
  delete: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  choose_an_action: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0434\u0456\u044E",
  txt_list: "\u0421\u043F\u0438\u0441\u043E\u043A",
  txt_thumb: "\u041A\u0430\u0440\u0442\u0438\u043D\u043A\u0430",
  txt_show_more: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0438 \u0431\u0456\u043B\u044C\u0448\u0435",
  start_date: "\u0414\u0430\u0442\u0430 \u043F\u043E\u0447\u0430\u0442\u043A\u0443",
  end_date: "\u0414\u0430\u0442\u0430 \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043D\u044F",
  created_date: "\u0414\u0430\u0442\u0430 \u0441\u0442\u0432\u043E\u0440\u0435\u043D\u043D\u044F",
  txt_short_description: "\u041A\u043E\u0440\u043E\u0442\u043A\u0438\u0439 \u043E\u043F\u0438\u0441",
  txt_short_description_about_project: "\u041A\u043E\u0440\u043E\u0442\u043A\u0438\u0439 \u043E\u043F\u0438\u0441 \u043F\u0440\u043E\u0435\u043A\u0442\u0443",
  txt_budget: "\u0411\u044E\u0434\u0436\u0435\u0442",
  txt_status: "\u0421\u0442\u0430\u043D",
  txt_name_personas: "\u0406\u043C'\u044F",
  updated_date: "\u041E\u043D\u043E\u0432\u043B\u0435\u043D\u0430 \u0434\u0430\u0442\u0430",
  txt_title: "\u041D\u0430\u0437\u0432\u0430",
  txt_channels: "\u041A\u0430\u043D\u0430\u043B\u0438",
  txt_date: "\u0414\u0430\u0442\u0430",
  txt_edit: "\u0420\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438",
  txt_general: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0456",
  txt_save: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438",
  txt_back: "\u041D\u0430\u0437\u0430\u0434",
  txt_next: "\u0414\u0430\u043B\u0456",
  txt_connecting: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044F",
  txt_connect: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438\u0441\u044F",
  txt_enable: "\u0423\u0432\u0456\u043C\u043A\u043D\u0443\u0442\u0438",
  txt_remove: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_type: "\u0422\u0438\u043F",
  txt_action: "\u0414\u0456\u044F",
  txt_profile: "\u041F\u0440\u043E\u0444\u0456\u043B\u044C",
  txt_apply: "\u0417\u0430\u0441\u0442\u043E\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_video: "\u0412\u0456\u0434\u0435\u043E",
  txt_menu_dashboard: "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0430 \u043F\u0430\u043D\u0435\u043B\u044C",
  txt_menu_visitors: "\u0412\u0456\u0434\u0432\u0456\u0434\u0443\u0432\u0430\u0447\u0456",
  txt_menu_overview: "\u041E\u0433\u043B\u044F\u0434",
  txt_menu_behavior: "\u041F\u043E\u0432\u0435\u0434\u0456\u043D\u043A\u0430",
  txt_menu_revenue: "\u0414\u043E\u0445\u0456\u0434",
  txt_menu_subscription: "\u041F\u0456\u0434\u043F\u0438\u0441\u043A\u0430",
  txt_menu_member_roles: "\u0420\u043E\u043B\u0456 \u0443\u0447\u0430\u0441\u043D\u0438\u043A\u0456\u0432",
  txt_menu_data_stream: "\u041F\u043E\u0442\u0456\u043A \u0434\u0430\u043D\u0438\u0445",
  txt_menu_region: "\u0420\u0435\u0433\u0456\u043E\u043D / \u041A\u0440\u0430\u0457\u043D\u0430",
  txt_menu_click_anchor: "\u041A\u043B\u0430\u0446\u043D\u0456\u0442\u044C \u0456 \u0437\u0430\u043A\u0440\u0456\u043F\u0456\u0442\u044C",
  txt_menu_utm_tracking: "\u0412\u0456\u0434\u0441\u0442\u0435\u0436\u0435\u043D\u043D\u044F UTM",
  txt_menu_events: "Event",
  txt_menu_member: "\u0423\u0447\u0430\u0441\u043D\u0438\u043A\u0438",
  txt_menu_import_export: "\u0406\u043C\u043F\u043E\u0440\u0442/\u0415\u043A\u0441\u043F\u043E\u0440\u0442",
  txt_menu_colection_transfer: "\u041F\u0435\u0440\u0435\u0434\u0430\u0447\u0430 \u043A\u043E\u043B\u0435\u043A\u0446\u0456\u0457",
  txt_menu_setting: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  txt_menu_help_center: "\u0414\u043E\u0432\u0456\u0434\u043A\u043E\u0432\u0438\u0439 \u0446\u0435\u043D\u0442\u0440",
  txt_dashboard: "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0430 \u043F\u0430\u043D\u0435\u043B\u044C",
  txt_dashboard_below: "\u041E\u0441\u044C \u0432\u0430\u0448\u0456 \u0430\u043D\u0430\u043B\u0456\u0442\u0438\u0447\u043D\u0456 \u0434\u0430\u043D\u0456",
  txt_select_date: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0434\u0430\u0442\u0443",
  txt_visitors: "\u0412\u0456\u0434\u0432\u0456\u0434\u0443\u0432\u0430\u0447\u0456",
  txt_total_revenue: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0438\u0439 \u0434\u043E\u0445\u0456\u0434",
  txt_sessions: "\u0421\u0435\u0430\u043D\u0441\u0438",
  txt_conversion_rate: "\u041A\u043E\u0435\u0444\u0456\u0446\u0456\u0454\u043D\u0442 \u043A\u043E\u043D\u0432\u0435\u0440\u0441\u0456\u0457",
  txt_revenue_by_subscribers: "\u0414\u043E\u0445\u0456\u0434 \u0437\u0430 \u043F\u0435\u0440\u0435\u0434\u043F\u043B\u0430\u0442\u043D\u0438\u043A\u0430\u043C\u0438",
  txt_new_registered_users: "\u041D\u043E\u0432\u0456 \u0437\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u043E\u0432\u0430\u043D\u0456 \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0456",
  txt_continent: "\u041A\u043E\u043D\u0442\u0438\u043D\u0435\u043D\u0442",
  txt_view_more: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438 \u0431\u0456\u043B\u044C\u0448\u0435",
  txt_view_detail: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438 \u0434\u0435\u0442\u0430\u043B\u0456",
  txt_views: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438",
  txt_behavior: "\u041F\u043E\u0432\u0435\u0434\u0456\u043D\u043A\u0430",
  txt_behavior_clicking: "\u041F\u043E\u0432\u0435\u0434\u0456\u043D\u043A\u0430 \u043A\u043B\u0456\u043A\u0456\u0432",
  txt_most_clingking_pages: "\u0421\u0442\u043E\u0440\u0456\u043D\u043A\u0438 \u0437 \u043D\u0430\u0439\u0431\u0456\u043B\u044C\u0448\u043E\u044E \u043A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044E \u043A\u043B\u0456\u043A\u0456\u0432",
  txt_gender: "\u0421\u0442\u0430\u0442\u044C",
  txt_name: "\u0406\u043C'\u044F",
  txt_size: "\u0420\u043E\u0437\u043C\u0456\u0440",
  txt_owner: "\u0412\u043B\u0430\u0441\u043D\u0438\u043A",
  txt_last_modified: "\u041E\u0441\u0442\u0430\u043D\u043D\u0454 \u0437\u043C\u0456\u043D\u0435\u043D\u043D\u044F",
  txt_image: "\u0417\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F",
  txt_document: "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442",
  txt_audio: "\u0410\u0443\u0434\u0456\u043E",
  txt_preview: "\u041F\u043E\u043F\u0435\u0440\u0435\u0434\u043D\u0456\u0439 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434",
  txt_move_to_folder: "\u041F\u0435\u0440\u0435\u043C\u0456\u0441\u0442\u0438\u0442\u0438 \u0434\u043E \u043F\u0430\u043F\u043A\u0438",
  txt_download: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438",
  txt_delete: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_sort_by: "\u0421\u043E\u0440\u0442\u0443\u0432\u0430\u0442\u0438 \u0437\u0430",
  txt_date_create: "\u0414\u0430\u0442\u0430 \u0441\u0442\u0432\u043E\u0440\u0435\u043D\u043D\u044F",
  txt_a_z: "\u0410 - \u042F",
  txt_z_a: "\u042F - \u0410",
  txt_menu_trash: "\u041A\u043E\u0448\u0438\u043A",
  txt_title_profilfe_setting: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F \u043F\u0440\u043E\u0444\u0456\u043B\u044E",
  txt_title_set_information_name: "\u0412\u0441\u0442\u0430\u043D\u043E\u0432\u0456\u0442\u044C \u0441\u0432\u043E\u0454 \u0456\u043C'\u044F \u0442\u0430 \u0456\u043D\u0448\u0443 \u0437\u0430\u0433\u0430\u043B\u044C\u043D\u043E\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0443 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044E",
  txt_cancel: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_your_avatar: "\u0412\u0430\u0448 \u0430\u0432\u0430\u0442\u0430\u0440",
  txt_upload_file: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438 \u0444\u0430\u0439\u043B",
  txt_create_folder: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043F\u0430\u043F\u043A\u0443",
  txt_main_menu: "\u0413\u043E\u043B\u043E\u0432\u043D\u0435 \u043C\u0435\u043D\u044E",
  txt_set_up: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u0442\u0438",
  txt_my_assets: "\u041C\u043E\u0457 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_download_folder: "\u041F\u0430\u043F\u043A\u0430 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F",
  txt_delete_folder: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u043F\u0430\u043F\u043A\u0443",
  txt_are_you_sure: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456?",
  txt_cancle: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_yes_delete: "\u0422\u0430\u043A, \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_file: "\u0424\u0410\u0419\u041B\u0418",
  txt_storage: "\u0421\u0445\u043E\u0432\u0438\u0449\u0435",
  txt_upgrade: "\u041E\u043D\u043E\u0432\u0438\u0442\u0438",
  txt_configuration_storage: "\u0421\u0445\u043E\u0432\u0438\u0449\u0435 \u043A\u043E\u043D\u0444\u0456\u0433\u0443\u0440\u0430\u0446\u0456\u0457",
  txt_configuration_desc: "\u041D\u0430\u0432\u0435\u0434\u0435\u043D\u0438\u0439 \u043D\u0438\u0436\u0447\u0435 \u0440\u043E\u0437\u043C\u0456\u0440 \u0432\u0438\u0437\u043D\u0430\u0447\u0430\u0454 \u043C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0438\u0439 \u0440\u043E\u0437\u043C\u0456\u0440 \u0443 \u043F\u0456\u043A\u0441\u0435\u043B\u044F\u0445 \u0434\u043B\u044F \u0432\u0438\u043A\u043E\u0440\u0438\u0441\u0442\u0430\u043D\u043D\u044F \u043F\u0456\u0434 \u0447\u0430\u0441 \u0434\u043E\u0434\u0430\u0432\u0430\u043D\u043D\u044F \u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F \u0434\u043E \u043C\u0435\u0434\u0456\u0430\u0442\u0435\u043A\u0438",
  txt_save_setting: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438",
  txt_client_id: "ID \u043A\u043B\u0456\u0454\u043D\u0442\u0430",
  txt_client_secret: "\u0421\u0435\u043A\u0440\u0435\u0442 \u043A\u043B\u0456\u0454\u043D\u0442\u0430",
  txt_region: "\u0420\u0435\u0433\u0456\u043E\u043D",
  txt_bucket: "\u0412\u0456\u0434\u0440\u043E",
  txt_drop_files_anywhere_to_upload: "\u041F\u0435\u0440\u0435\u0442\u044F\u0433\u043D\u0456\u0442\u044C \u0444\u0430\u0439\u043B\u0438 \u0431\u0443\u0434\u044C-\u0434\u0435, \u0449\u043E\u0431 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438",
  txt_or: "\u0410\u0431\u043E",
  txt_select_file: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0444\u0430\u0439\u043B",
  txt_save_update: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u043E\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F",
  txt_delete_assets_popup_desc: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456, \u0449\u043E \u0445\u043E\u0447\u0435\u0442\u0435 \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u0446\u0435\u0439 \u0440\u0435\u0441\u0443\u0440\u0441?",
  txt_delete_collections_popup_desc: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456, \u0449\u043E \u0431\u0430\u0436\u0430\u0454\u0442\u0435 \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u0446\u044E \u043F\u0430\u043F\u043A\u0443 (\u0432\u043A\u043B\u044E\u0447\u0430\u044E\u0447\u0438 \u0434\u043E\u0447\u0456\u0440\u043D\u0456 \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0438 \u0442\u0430 \u0440\u0435\u0441\u0443\u0440\u0441\u0438)?",
  tx_forgot_password: "\u0417\u0430\u0431\u0443\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?",
  txt_file_size: "\u0420\u043E\u0437\u043C\u0456\u0440",
  txt_url: "URL",
  txt_file_type: "\u0422\u0438\u043F",
  txt_sign_in_to_getting_started: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438.",
  txt_folders: "\u041F\u0410\u041F\u041A\u0418",
  txt_of: "\u0437",
  txt_used: "\u0432\u0438\u043A\u043E\u0440\u0438\u0441\u0442\u043E\u0432\u0443\u0454\u0442\u044C\u0441\u044F",
  txt_audience: "\u0410\u0443\u0434\u0438\u0442\u043E\u0440\u0456\u044F",
  txt_analytic_details: "\u041E\u0441\u044C \u0432\u0430\u0448\u0456 \u0430\u043D\u0430\u043B\u0456\u0442\u0438\u0447\u043D\u0456 \u0434\u0435\u0442\u0430\u043B\u0456",
  txt_value: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  txt_hours: "\u0433\u043E\u0434\u0438\u043D\u0438",
  txt_days: "\u0434\u043D\u0456\u0432",
  txt_weeks: "\u0442\u0438\u0436\u043D\u0456\u0432",
  txt_months: "\u041C\u0456\u0441\u044F\u0446\u0456",
  txt_page_views: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438 \u0441\u0442\u043E\u0440\u0456\u043D\u043E\u043A",
  txt_unique_page_views: "\u0423\u043D\u0456\u043A\u0430\u043B\u044C\u043D\u0456 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438 \u0441\u0442\u043E\u0440\u0456\u043D\u043E\u043A",
  txt_acg_session_duration: "\u0421\u0435\u0440\u0435\u0434\u043D\u0454 \u0422\u0440\u0438\u0432\u0430\u043B\u0456\u0441\u0442\u044C \u0441\u0435\u0430\u043D\u0441\u0443",
  txt_bounce_rate: "\u041F\u043E\u043A\u0430\u0437\u043D\u0438\u043A \u0432\u0456\u0434\u043C\u043E\u0432",
  txt_page_session: "\u0421\u0442\u043E\u0440\u0456\u043D\u043A\u0430/\u0441\u0435\u0441\u0456\u044F",
  txt_in_total: "\u0417\u0430\u0433\u0430\u043B\u043E\u043C",
  txt_description: "\u043E\u043F\u0438\u0441",
  txt_search: "\u041F\u043E\u0448\u0443\u043A",
  txt_date_range: "\u041F\u0440\u043E\u043C\u0456\u0436\u043E\u043A \u0447\u0430\u0441\u0443",
  txt_devices: "\u041F\u0440\u0438\u0441\u0442\u0440\u043E\u0457",
  txt_choose_file: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0444\u0430\u0439\u043B",
  "txt_select...": "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C...",
  txt_all_users: "\u0412\u0441\u0456 \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0456",
  txt_all: "\u0432\u0441\u0435",
  txt_unique: "\u0423\u043D\u0456\u043A\u0430\u043B\u044C\u043D\u0438\u0439",
  txt_no_data: "\u041D\u0435\u043C\u0430\u0454 \u0434\u0430\u043D\u0438\u0445",
  txt_something_went_wrong_from_server_response: "\u0429\u043E\u0441\u044C \u043F\u0456\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A \u0456\u0437 \u0432\u0456\u0434\u043F\u043E\u0432\u0456\u0434\u0434\u044E \u0441\u0435\u0440\u0432\u0435\u0440\u0430",
  txt_warning: "\u0423\u0412\u0410\u0413\u0410",
  txt_successful: "\u0423\u0441\u043F\u0456\u0448\u043D\u0438\u0439",
  txt_error: "\u041F\u043E\u043C\u0438\u043B\u043A\u0430",
  txt_number: "\u041A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C",
  txt_visitor_flow: "\u041F\u043E\u0442\u0456\u043A \u0432\u0456\u0434\u0432\u0456\u0434\u0443\u0432\u0430\u0447\u0456\u0432",
  txt_domain: "\u0414\u043E\u043C\u0435\u043D",
  txt_location: "\u041C\u0456\u0441\u0446\u0435\u0437\u043D\u0430\u0445\u043E\u0434\u0436\u0435\u043D\u043D\u044F",
  txt_duration: "\u0422\u0440\u0438\u0432\u0430\u043B\u0456\u0441\u0442\u044C",
  txt_ip: "IP",
  txt_device: "Device",
  txt_browser: "\u0411\u0440\u0430\u0443\u0437\u0435\u0440",
  txt_language: "\u041C\u043E\u0432\u0430",
  txt_page: "\u0421\u0442\u043E\u0440\u0456\u043D\u043A\u0430",
  txt_page_title: "\u041D\u0430\u0437\u0432\u0430 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438",
  txt_time_on_page: "\u0427\u0430\u0441 \u043D\u0430 \u0441\u0442\u043E\u0440\u0456\u043D\u0446\u0456",
  txt_menu_generator: "\u0413\u0435\u043D\u0435\u0440\u0430\u0442\u043E\u0440 \u043A\u043E\u0434\u0443",
  txt_utm_tracking_generator: "\u0413\u0435\u043D\u0435\u0440\u0430\u0442\u043E\u0440 \u0432\u0456\u0434\u0441\u0442\u0435\u0436\u0435\u043D\u043D\u044F UTM",
  txt_events_generator: "\u0413\u0435\u043D\u0435\u0440\u0430\u0442\u043E\u0440 \u043F\u043E\u0434\u0456\u0439",
  txt_export_pdf: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442 PDF",
  txt_tooltip_visitors: "\u041A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u0432\u0456\u0434\u0432\u0456\u0434\u0443\u0432\u0430\u0447\u0456\u0432",
  txt_tooltip_page_views: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438 \u0441\u0442\u043E\u0440\u0456\u043D\u043E\u043A \u2013 \u0446\u0435 \u0437\u0430\u0433\u0430\u043B\u044C\u043D\u0430 \u043A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438\u0445 \u0441\u0442\u043E\u0440\u0456\u043D\u043E\u043A. \u0412\u0440\u0430\u0445\u043E\u0432\u0443\u044E\u0442\u044C\u0441\u044F \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u0456 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438 \u043E\u0434\u043D\u0456\u0454\u0457 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438.",
  txt_tooltip_unique_page_views: "\u0423\u043D\u0456\u043A\u0430\u043B\u044C\u043D\u0456 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438 \u2014 \u0446\u0435 \u043A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u0441\u0435\u0430\u043D\u0441\u0456\u0432, \u043F\u0456\u0434 \u0447\u0430\u0441 \u044F\u043A\u0438\u0445 \u0437\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0443 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0443 \u0431\u0443\u043B\u043E \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u043E \u0445\u043E\u0447\u0430 \u0431 \u043E\u0434\u0438\u043D \u0440\u0430\u0437. \u0423\u043D\u0456\u043A\u0430\u043B\u044C\u043D\u0438\u0439 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438 \u0437\u0430\u0440\u0430\u0445\u043E\u0432\u0443\u0454\u0442\u044C\u0441\u044F \u0434\u043B\u044F \u043A\u043E\u0436\u043D\u043E\u0457 \u043A\u043E\u043C\u0431\u0456\u043D\u0430\u0446\u0456\u0457 URL \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438 + \u0437\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438.",
  txt_tooltip_bounce_rate: "\u0412\u0456\u0434\u0441\u043E\u0442\u043E\u043A \u0441\u0435\u0430\u043D\u0441\u0456\u0432 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0443 \u043E\u0434\u043D\u0456\u0454\u0457 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438, \u043F\u0456\u0434 \u0447\u0430\u0441 \u044F\u043A\u0438\u0445 \u043D\u0435 \u0431\u0443\u043B\u043E \u0432\u0437\u0430\u0454\u043C\u043E\u0434\u0456\u0457 \u0437\u0456 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u043E\u044E. \u0422\u0440\u0438\u0432\u0430\u043B\u0456\u0441\u0442\u044C \u0441\u0435\u0430\u043D\u0441\u0443 \u0437 \u043F\u043E\u0432\u0435\u0440\u043D\u0435\u043D\u043D\u044F\u043C \u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C 0 \u0441\u0435\u043A\u0443\u043D\u0434.",
  txt_tooltip_page_session: "\u0421\u0435\u0440\u0435\u0434\u043D\u044F \u043A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u0441\u0442\u043E\u0440\u0456\u043D\u043E\u043A, \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438\u0445 \u043F\u0440\u043E\u0442\u044F\u0433\u043E\u043C \u0441\u0435\u0430\u043D\u0441\u0443. \u0412\u0440\u0430\u0445\u043E\u0432\u0443\u044E\u0442\u044C\u0441\u044F \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u0456 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0438 \u043E\u0434\u043D\u0456\u0454\u0457 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438.",
  txt_tooltip_avg_session_duration: "\u0421\u0435\u0440\u0435\u0434\u043D\u044F \u0442\u0440\u0438\u0432\u0430\u043B\u0456\u0441\u0442\u044C \u0441\u0435\u0441\u0456\u0457."
};

// src/translations/vi/common.json
var common_default7 = {
  txt_welcome_to: "Ch\xE0o m\u1EEBng \u0111\u1EBFn",
  txt_email: "Email",
  txt_sign_in: "\u0110\u0103ng nh\xE2\u0323p",
  txt_sign_in_with_sso: "\u0110\u0103ng nh\u1EADp v\u1EDBi SSO",
  txt_sign_up: "\u0110\u0103ng k\xFD",
  txt_login_text_1: "Ch\xE0o m\u1EEBng \u0111\u1EBFn",
  txt_bi: "BI",
  txt_login_text_2: "\u0110\u0103ng nh\xE2\u0323p \u0111\xEA\u0309 b\u0103\u0301t \u0111\xE2\u0300u",
  txt_do_not_have_an_account: "Ba\u0323n ch\u01B0a c\xF3 t\xE0i kho\u1EA3n? T\u1EA1o t\xE0i kho\u1EA3n mi\u1EC5n ph\xED",
  txt_username: "T\xEAn ng\u01B0\u01A1\u0300i du\u0300ng",
  txt_search_for_something: "T\xECm ki\u1EBFm ...",
  txt_search_all_content: "Ti\u0300m ki\xEA\u0301m t\xE2\u0301t ca\u0309 n\xF4\u0323i dung",
  txt_help_center: "Trung t\xE2m h\xF4\u0303 tr\u01A1\u0323",
  txt_sign_out: "\u0110\u0103ng xu\u1EA5t",
  txt_notifications: "Th\xF4ng b\xE1o",
  txt_client: "Kha\u0301ch ha\u0300ng",
  txt_more: "Th\xEAm",
  txt_menu_digital_assets: "Danh sa\u0301ch Ta\u0300i sa\u0309n Ky\u0303 thu\xE2\u0323t s\xF4\u0301",
  txt_today: "H\xF4m nay",
  txt_general_information: "Th\xF4ng tin t\xF4\u0309ng h\u01A1\u0323p",
  txt_projectpage_password: "M\xE2\u0323t kh\xE2\u0309u",
  txt_profile_picture: "A\u0309nh \u0111a\u0323i di\xEA\u0323n",
  txt_back_to_dashboard: "Quay v\xEA\u0300 trang chu\u0309",
  txt_menu_profile: "H\xF4\u0300 s\u01A1",
  txt_Username: "T\xEAn ng\u01B0\u01A1\u0300i du\u0300ng",
  txt_Email: "Email",
  txt_Status: "Tra\u0323ng tha\u0301i",
  txt_Birthday: "Nga\u0300y sinh",
  txt_Phone: "S\xF4\u0301 \u0111i\xEA\u0323n thoa\u0323i",
  txt_Address_1: "\u0110i\u0323a chi\u0309 1",
  txt_Address_2: "\u0110i\u0323a chi\u0309 2",
  txt_State: "Ti\xEA\u0309u bang",
  txt_Country: "Qu\xF4\u0301c gia",
  txt_Countries: "Qu\xF4\u0301c gia",
  txt_Zipcode: "Zipcode",
  txt_Fullname: "T\xEAn \u0111\xE2\u0300y \u0111u\u0309",
  txt_City: "Tha\u0300nh ph\xF4\u0301",
  txt_Cities: "Th\xE0nh ph\xF3",
  txt_current_password: "M\u1EADt kh\u1EA9u hi\u1EC7n t\u1EA1i",
  txt_confirm_password: "X\xE1c nh\u1EADn m\u1EADt kh\u1EA9u m\u1EDBi",
  txt_new_password: "M\u1EADt kh\u1EA9u m\u1EDBi",
  txt_update: "C\u1EADp nh\u1EADt",
  delete: "Xo\xE1",
  choose_an_action: "Ch\u1ECDn h\xE0nh \u0111\u1ED9ng",
  txt_list: "Danh sa\u0301ch",
  txt_thumb: "Thu nh\u1ECF",
  txt_show_more: "Hi\xEA\u0309n thi\u0323 th\xEAm",
  start_date: "Ng\xE0y b\u1EAFt \u0111\u1EA7u",
  end_date: "Ng\xE0y k\u1EBFt th\xFAc",
  created_date: "Ng\xE0y \u0111\u01B0\u1EE3c t\u1EA1o",
  txt_short_description: "M\xF4 t\u1EA3 ng\u1EAFn",
  txt_short_description_about_project: "M\xF4 t\u1EA3 ng\u1EAFn v\u1EC1 d\u1EF1 \xE1n",
  txt_budget: "Ng\xE2n s\xE1ch",
  txt_status: "Tr\u1EA1ng th\xE1i",
  txt_name_personas: "T\xEAn ch\xE2n dung kha\u0301ch ha\u0300ng",
  updated_date: "Ng\xE0y c\u1EADp nh\u1EADt",
  txt_title: "Ti\xEAu \u0111\u1EC1",
  txt_channels: "K\xEAnh",
  txt_date: "Nga\u0300y",
  txt_edit: "Ch\u1EC9nh s\u1EEDa",
  txt_general: "T\u1ED5ng h\u1EE3p",
  txt_save: "L\u01B0u",
  txt_back: "Tr\u01A1\u0309 v\xEA\u0300",
  txt_next: "Ti\xEA\u0301p theo",
  txt_connecting: "\u0110ang k\xEA\u0301t n\xF4\u0301i",
  txt_connect: "K\xEA\u0301t n\xF4\u0301i",
  txt_enable: "Cho phe\u0301p",
  txt_remove: "Xo\u0301a",
  txt_type: "Ki\xEA\u0309u",
  txt_action: "Hoa\u0323t \u0111\xF4\u0323ng",
  txt_profile: "H\xF4\u0300 s\u01A1",
  txt_apply: "Ga\u0301n",
  txt_video: "Video",
  txt_menu_dashboard: "Trang chu\u0309",
  txt_menu_visitors: "Ng\u01B0\u1EDDi truy c\u1EADp",
  txt_menu_overview: "T\xF4\u0309ng quan",
  txt_menu_behavior: "Ha\u0300nh vi",
  txt_menu_revenue: "Doanh thu",
  txt_menu_subscription: "\u0110\u0103ng ky\u0301",
  txt_menu_member_roles: "Vai tro\u0300 tha\u0300nh vi\xEAn",
  txt_menu_data_stream: "Do\u0300ng d\u01B0\u0303 li\xEA\u0323u",
  txt_menu_region: "Khu v\u01B0\u0323c / Qu\xF4\u0301c gia",
  txt_menu_click_anchor: "Nh\xE2\u0301n \u0111\xEA\u0309 xem",
  txt_menu_utm_tracking: "UTM Tracking",
  txt_menu_events: "Event",
  txt_menu_member: "Tha\u0300nh vi\xEAn",
  txt_menu_import_export: "Danh sa\u0301ch nh\xE2\u0323p va\u0300 xu\xE2\u0301t",
  txt_menu_colection_transfer: "Collection Transfer",
  txt_menu_setting: "Ca\u0300i \u0111\u0103\u0323t",
  txt_menu_help_center: "Trung t\xE2m h\xF4\u0303 tr\u01A1\u0323",
  txt_dashboard: "Trang chu\u0309",
  txt_dashboard_below: "Chi ti\xEA\u0301t ph\xE2n ti\u0301ch cu\u0309a ba\u0323n",
  txt_select_date: "Cho\u0323n nga\u0300y",
  txt_visitors: "Ng\u01B0\u01A1\u0300i xem trang",
  txt_total_revenue: "T\xF4\u0309ng doanh thu",
  txt_sessions: "Phi\xEAn",
  txt_conversion_rate: "Ti\u0309 l\xEA\u0323 chuy\xEA\u0309n \u0111\xF4\u0309i",
  txt_revenue_by_subscribers: "Doanh thu t\u01B0\u0300 ng\u01B0\u01A1\u0300i \u0111\u0103ng ki\u0301",
  txt_new_registered_users: "Ng\u01B0\u01A1\u0300i \u0111\u0103ng ki\u0301 m\u01A1\u0301i",
  txt_continent: "Ch\xE2u lu\u0323c",
  txt_view_more: "Xem th\xEAm",
  txt_view_detail: "Xem chi ti\xEA\u0301t",
  txt_views: "S\xF4\u0301 l\u01B0\u01A1\u0323ng xem",
  txt_behavior: "Ha\u0300nh vi",
  txt_behavior_clicking: "S\xF4\u0301 l\u01B0\u01A1\u0323ng nh\xE2\u0301p chu\xF4\u0323t",
  txt_most_clingking_pages: "Trang \u0111\u01B0\u01A1\u0323c nh\xE2\u0301p chu\xF4\u0323t nhi\xEA\u0300u nh\xE2\u0301t",
  txt_gender: "Gi\u01A1\u0301i ti\u0301nh",
  txt_name: "T\xEAn",
  txt_size: "Ki\u0301ch th\u01B0\u01A1\u0301c",
  txt_owner: "Chu\u0309 s\u01A1\u0309 h\u01B0\u0303u",
  txt_last_modified: "S\u01B0\u0309a \u0111\xF4\u0309i l\xE2\u0300n cu\xF4\u0301i",
  txt_image: "Hi\u0300nh a\u0309nh",
  txt_document: "Ta\u0300i li\xEA\u0323u",
  txt_audio: "Audio",
  txt_preview: "Xem tr\u01B0\u01A1\u0301c",
  txt_move_to_folder: "Chuy\xEA\u0309n \u0111\xEA\u0301n th\u01B0 mu\u0323c",
  txt_download: "Ta\u0309i v\xEA\u0300",
  txt_delete: "Xo\u0301a",
  txt_sort_by: "S\u0103\u0301p x\xEA\u0301p",
  txt_date_create: "Nga\u0300y ta\u0323o",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_trash: "Thu\u0300ng ra\u0301c",
  txt_title_profilfe_setting: "Ca\u0300i \u0111\u0103\u0323t h\xF4\u0300 s\u01A1",
  txt_title_set_information_name: "Ca\u0300i \u0111\u0103\u0323t t\xEAn v\xE0 c\xE1c th\xF4ng tin kh\xE1c",
  txt_cancel: "Hu\u0309y",
  txt_your_avatar: "A\u0309nh cu\u0309a ba\u0323n",
  txt_upload_file: "Ta\u0309i t\xEA\u0323p",
  txt_create_folder: "Ta\u0323o th\u01B0 mu\u0323c",
  txt_main_menu: "Danh sa\u0301ch chi\u0301nh",
  txt_set_up: "Thi\xEA\u0301t l\xE2\u0323p",
  txt_my_assets: "Ta\u0300i sa\u0309n cu\u0309a t\xF4i",
  txt_download_folder: "Ta\u0309i th\u01B0 mu\u0323c",
  txt_delete_folder: "Xo\u0301a th\u01B0 mu\u0323c",
  txt_are_you_sure: "Ba\u0323n ch\u0103\u0301c ch\u0103\u0301n mu\xF4\u0301n xo\u0301a?",
  txt_cancle: "Hu\u0309y",
  txt_yes_delete: "\u0110\xF4\u0300ng y\u0301, xo\u0301a",
  txt_file: "T\xEA\u0323p",
  txt_storage: "Kho l\u01B0u tr\u01B0\u0303",
  txt_upgrade: "N\xE2ng c\xE2\u0301p",
  txt_configuration_storage: "L\u01B0u tr\u01B0\u0303 c\xE2\u0301u hi\u0300nh",
  txt_configuration_desc: "Danh sa\u0301ch ki\u0301ch th\u01B0\u01A1\u0301c b\xEAn d\u01B0\u01A1\u0301i xa\u0301c \u0111i\u0323nh b\u0103\u0300ng \u0111\u01A1n vi\u0323 pixels t\xF4\u0301i \u0111a \u0111\u01B0\u01A1\u0323c s\u01B0\u0309 du\u0323ng khi \u0111\u0103ng ta\u0309i hi\u0300nh a\u0309nh va\u0300o b\xF4\u0323 s\u01B0u t\xE2\u0323p",
  txt_save_setting: "L\u01B0u ca\u0300i \u0111\u0103\u0323t",
  txt_client_id: "Ma\u0303 kha\u0301ch ha\u0300ng",
  txt_client_secret: "Ba\u0309o m\xE2\u0323t kha\u0301ch ha\u0300ng",
  txt_region: "Khu v\u01B0\u0323c",
  txt_bucket: "Bucket",
  txt_drop_files_anywhere_to_upload: "Th\u1EA3 t\u1EC7p \u1EDF b\u1EA5t c\u1EE9 \u0111\xE2u \u0111\u1EC3 t\u1EA3i l\xEAn",
  txt_or: "Ho\u0103\u0323c",
  txt_select_file: "Cho\u0323n t\xEA\u0323p",
  txt_save_update: "L\u01B0u & C\xE2\u0323p nh\xE2\u0323t",
  txt_delete_assets_popup_desc: "B\u1EA1n c\xF3 ch\u1EAFc ch\u1EAFn mu\xF4\u0301n xo\u0301a m\u1EE5c n\xE0y?",
  txt_delete_collections_popup_desc: "B\u1EA1n c\xF3 ch\u1EAFc ch\u1EAFn mu\xF4\u0301n xo\u0301a m\u1EE5c n\xE0y (bao g\xF4\u0300m ca\u0309 th\u01B0 mu\u0323c con va\u0300 n\xF4\u0323i dung)?",
  tx_forgot_password: "Ba\u0323n qu\xEAn m\xE2\u0323t kh\xE2\u0309u?",
  txt_file_size: "Ki\u0301ch th\u01B0\u01A1\u0301c",
  txt_url: "URL",
  txt_file_type: "Ki\xEA\u0309u",
  txt_sign_in_to_getting_started: "\u0110\u0103ng nh\u1EADp \u0111\u1EC3 b\u1EAFt \u0111\u1EA7u",
  txt_folders: "Th\u01B0 mu\u0323c",
  txt_of: "Cu\u0309a",
  txt_used: "\u0110a\u0303 s\u01B0\u0309 du\u0323ng",
  txt_audience: "Kha\u0301ch ha\u0300ng",
  txt_analytic_details: "Chi ti\xEA\u0301t ph\xE2n ti\u0301ch cu\u0309a ba\u0323n",
  txt_value: "Gia\u0301 tri\u0323",
  txt_hours: "Gi\u01A1\u0300",
  txt_days: "Nga\u0300y",
  txt_weeks: "Tu\xE2\u0300n",
  txt_months: "Tha\u0301ng",
  txt_page_views: "L\u01B0\u1EE3t xem trang",
  txt_unique_page_views: "L\u01B0\u1EE3t xem trang duy nh\u1EA5t",
  txt_acg_session_duration: "Trung bi\u0300nh th\u01A1\u0300i l\u01B0\u01A1\u0323ng phi\xEAn",
  txt_bounce_rate: "Ty\u0309 l\xEA\u0323 thoa\u0301t trang",
  txt_page_session: "Trang/Phi\xEAn",
  txt_in_total: "T\xF4\u0309ng c\xF4\u0323ng",
  txt_description: "Mi\xEAu ta\u0309",
  txt_search: "Ti\u0300m ki\xEA\u0301m",
  txt_date_range: "Pha\u0323m vi nga\u0300y",
  txt_devices: "Thi\xEA\u0301t bi\u0323",
  txt_choose_file: "Cho\u0323n t\xEA\u0323p",
  "txt_select...": "L\u01B0\u0323a cho\u0323n...",
  txt_all_users: "T\u1EA5t c\u1EA3 ng\u01B0\u1EDDi d\xF9ng",
  txt_all: "T\u1EA5t c\u1EA3",
  txt_unique: "Duy nh\u1EA5t",
  txt_no_data: "Kh\xF4ng c\xF3 d\u1EEF li\u1EC7u",
  txt_something_went_wrong_from_server_response: "\u0110\xE3 x\u1EA3y ra s\u1EF1 c\u1ED1 t\u1EEB ph\u1EA3n h\u1ED3i c\u1EE7a M\xE1y ch\u1EE7",
  txt_warning: "C\u1EA3nh b\xE1o",
  txt_successful: "Th\xE0nh c\xF4ng",
  txt_error: "L\u1ED7i",
  txt_number: "S\u1ED1 l\u01B0\u1EE3ng",
  txt_visitor_flow: "Lu\u1ED3ng kh\xE1ch truy c\u1EADp",
  txt_domain: "T\xEAn mi\u1EC1n",
  txt_location: "V\u1ECB tr\xED",
  txt_duration: "Th\u1EDDi gian",
  txt_ip: "IP",
  txt_device: "Thi\u1EBFt b\u1ECB",
  txt_browser: "Tr\xECnh duy\u1EC7t",
  txt_language: "Ng\xF4n ng\u1EEF",
  txt_page: "Trang",
  txt_page_title: "Ti\xEAu \u0111\u1EC1 trang",
  txt_time_on_page: "Th\u1EDDi gian tr\xEAn trang",
  txt_menu_generator: "Tr\xECnh t\u1EA1o m\xE3",
  txt_utm_tracking_generator: "Tr\xECnh t\u1EA1o UTM Tracking",
  txt_events_generator: "Tr\xECnh t\u1EA1o s\u1EF1 ki\u1EC7n",
  txt_export_pdf: "Xu\u1EA5t file PDF",
  txt_tooltip_visitors: "S\u1ED1 l\u01B0\u1EE3ng ng\u01B0\u1EDDi xem trang",
  txt_tooltip_page_views: "L\u01B0\u1EE3t xem trang l\xE0 t\u1ED5ng s\u1ED1 trang \u0111\xE3 \u0111\u01B0\u1EE3c xem. S\u1ED1 l\u1EA7n xem l\u1EB7p l\u1EA1i c\u1EE7a m\u1ED9t trang v\u1EABn s\u1EBD \u0111\u01B0\u1EE3c t\xEDnh.",
  txt_tooltip_unique_page_views: "L\u01B0\u1EE3t xem trang duy nh\u1EA5t l\xE0 t\u1ED5ng h\u1EE3p s\u1ED1 l\u1EA7n xem trang \u0111\u01B0\u1EE3c t\u1EA1o b\u1EDFi c\xF9ng m\u1ED9t ng\u01B0\u1EDDi d\xF9ng trong c\xF9ng m\u1ED9t phi\xEAn (t\u1EE9c l\xE0 s\u1ED1 phi\xEAn trong \u0111\xF3 trang web \u0111\u01B0\u1EE3c xem m\u1ED9t ho\u1EB7c nhi\u1EC1u l\u1EA7n). S\u1ED1 l\u01B0\u1EE3t xem trang duy nh\u1EA5t \u0111\u01B0\u1EE3c t\xEDnh d\u1EF1a tr\xEAn s\u1EF1 k\u1EBFt h\u1EE3p c\u1EE7a hai y\u1EBFu t\u1ED1 URL trang + Ti\xEAu \u0111\u1EC1 trang",
  txt_tooltip_bounce_rate: "T\u1EF7 l\u1EC7 ph\u1EA7n tr\u0103m s\u1ED1 phi\xEAn truy c\u1EADp ng\u01B0\u1EDDi d\xF9ng ch\u1EC9 v\xE0o m\u1ED9t trang duy nh\u1EA5t v\xE0 sau \u0111\xF3 r\u1EDDi \u0111i ngay m\xE0 kh\xF4ng c\xF3 th\xEAm b\u1EA5t k\u1EF3 t\u01B0\u01A1ng t\xE1c n\xE0o tr\xEAn trang. M\u1ED9t phi\xEAn b\u1ECB tho\xE1t c\xF3 th\u1EDDi l\u01B0\u1EE3ng l\xE0 0 gi\xE2y.",
  txt_tooltip_page_session: "s\u1ED1 trang trung b\xECnh \u0111\u01B0\u1EE3c xem trong m\u1ED9t phi\xEAn. S\u1ED1 l\u1EA7n xem l\u1EB7p l\u1EA1i c\u1EE7a m\u1ED9t trang v\u1EABn s\u1EBD \u0111\u01B0\u1EE3c t\xEDnh.",
  txt_tooltip_avg_session_duration: "Tham s\u1ED1 \u0111o l\u01B0\u1EDDng th\u1EDDi gian trung b\xECnh c\u1EE7a m\u1ED9t phi\xEAn."
};

// src/translations/index.js
var appLanguages = {
  en: common_default2,
  da: common_default,
  vi: common_default7,
  th: common_default5,
  hr: common_default4,
  uk: common_default6,
  es: common_default3
};

// src/integration/lib/SbarLeftIntegration.jsx
import React3, { useEffect, useState as useState2 } from "react";
import { useTranslation as useTranslation2 } from "react-i18next";
import { observer as observer2 } from "mobx-react";
import { env as env3 } from "aesirx-lib";
import { Collapse as Collapse2 } from "react-bootstrap";

// src/routes/menu.js
import { env as env2 } from "aesirx-lib";
var mainMenu = [
  {
    text: "txt_menu_dashboard",
    link: `/`,
    icons: env2.PUBLIC_URL + "/assets/images/dashboard.svg",
    icons_color: env2.PUBLIC_URL + "/assets/images/dashboard.svg",
    page: "dashboard"
  },
  {
    text: "txt_menu_visitors",
    link: `/audience/overview`,
    icons: env2.PUBLIC_URL + "/assets/images/audience.svg",
    icons_color: env2.PUBLIC_URL + "/assets/images/audience.svg",
    submenu: [
      {
        text: "txt_menu_overview",
        mini_text: "txt_menu_visitors",
        link: `/audience/overview`,
        page: "audience-overview"
      },
      {
        text: "txt_menu_behavior",
        mini_text: "txt_menu_behavior",
        link: `/audience/behavior`,
        page: "audience-behavior"
      }
    ]
  },
  {
    text: "txt_menu_utm_tracking",
    link: `/utm-tracking`,
    icons: env2.PUBLIC_URL + "/assets/images/behavior.svg",
    icons_color: env2.PUBLIC_URL + "/assets/images/behavior.svg",
    submenu: [
      {
        text: "txt_menu_overview",
        mini_text: "txt_menu_utm_tracking",
        link: `/utm-tracking`,
        page: "utm-tracking"
      },
      {
        text: "txt_menu_generator",
        mini_text: "txt_menu_generator",
        link: `/utm-tracking/generator`,
        page: "utm-tracking-generator"
      }
    ]
  },
  {
    text: "txt_menu_events",
    link: `/events`,
    icons: env2.PUBLIC_URL + "/assets/images/calendar-line.svg",
    icons_color: env2.PUBLIC_URL + "/assets/images/calendar-line.svg",
    submenu: [
      {
        text: "txt_menu_overview",
        mini_text: "txt_menu_events",
        link: `/events`,
        page: "events"
      },
      {
        text: "txt_menu_generator",
        mini_text: "txt_menu_generator",
        link: `/events/generator`,
        page: "events-generator"
      }
    ]
  },
  // {
  //   text: 'txt_menu_woocommerce',
  //   link: `/woocommerce`,
  //   icons: env.PUBLIC_URL + '/assets/images/calendar-line.svg',
  //   icons_color: env.PUBLIC_URL + '/assets/images/calendar-line.svg',
  // },
  {
    text: "txt_menu_region",
    link: `/region-country`,
    icons: env2.PUBLIC_URL + "/assets/images/region-country.svg",
    icons_color: env2.PUBLIC_URL + "/assets/images/region-country.svg",
    page: "region-country"
  }
];
var integrationMenu = () => {
  return [
    ...mainMenu.map((item) => {
      item.link = "/bi" + item.link;
      return item;
    }),
    {
      text: "txt_menu_behavior",
      link: `/bi/audience/behavior`,
      page: "audience-behavior"
    }
  ];
};

// src/integration/lib/SbarLeftIntegration.jsx
var SbarLeftIntegration = observer2(() => {
  const [isOpenCollapse, setIsOpenCollapse] = useState2("default");
  const [dataStreamActive, setDataStreamActive] = useState2(
    env3.REACT_APP_DATA_STREAM && JSON.parse(env3.REACT_APP_DATA_STREAM)[0].domain
  );
  const biStore = useBiViewModel();
  const { t } = useTranslation2();
  const handleOpen = (clickedIndex, parentIndex) => {
    if (isOpenCollapse === clickedIndex.toString()) {
      if (parentIndex) {
        setIsOpenCollapse(parentIndex.toString());
      } else {
        setIsOpenCollapse(null);
      }
    } else {
      if (isOpenCollapse?.includes(clickedIndex.toString())) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex.toString());
      }
    }
  };
  const handleChangeLink = (e, link) => {
    e.preventDefault();
    if (link) {
      biStore.biListViewModel.setIntegrationLink(link);
    }
  };
  useEffect(() => {
    let fetchData = async () => {
      if (biStore.biListViewModel.activeDomain) {
        biStore.biListViewModel.setActiveDomain(biStore.biListViewModel.activeDomain);
        setDataStreamActive(`${biStore.biListViewModel.activeDomain}`);
      }
    };
    fetchData();
  }, [biStore.biListViewModel.activeDomain, dataStreamActive]);
  return /* @__PURE__ */ React3.createElement(
    "aside",
    {
      className: `sidebar w-248  mt-0 position-relative bg-dark mh-100 h-100 d-flex flex-column z-index-100 justify-content-between`
    },
    /* @__PURE__ */ React3.createElement("nav", { className: "main-menu py-24 mt-0" }, /* @__PURE__ */ React3.createElement("p", { className: "menu_title text-dark-blue fs-14 mb-0 text-uppercase" }, t("txt_main_menu")), /* @__PURE__ */ React3.createElement("ul", { id: "wr_list_menu", className: "list-unstyled mb-0 pt-md-1" }, mainMenu.map((menuList, menuListkey) => {
      return /* @__PURE__ */ React3.createElement(
        "li",
        {
          key: menuListkey,
          className: `item_menu ${menuList.className ? menuList.className : ""} `
        },
        !menuList.submenu ? /* @__PURE__ */ React3.createElement(React3.Fragment, null, menuList.link && /* @__PURE__ */ React3.createElement(
          "a",
          {
            href: "#",
            onClick: (e) => {
              setIsOpenCollapse(null);
              handleChangeLink(e, menuList.page);
            },
            className: `d-block px-24 py-16 link_menu text-white text-decoration-none ${biStore.biListViewModel.integrationLink === menuList.page ? "active" : ""}`
          },
          /* @__PURE__ */ React3.createElement(
            "span",
            {
              className: "icon d-inline-block align-text-bottom",
              style: {
                WebkitMaskImage: `url(${menuList.icons_color})`,
                WebkitMaskRepeat: "no-repeat",
                backgroundColor: "#fff"
              }
            }
          ),
          /* @__PURE__ */ React3.createElement("span", { className: "ms-16 text d-inline-block" }, t(menuList.text))
        )) : /* @__PURE__ */ React3.createElement(React3.Fragment, null, /* @__PURE__ */ React3.createElement(
          "a",
          {
            href: "#",
            onClick: (e) => {
              handleOpen(menuListkey);
              handleChangeLink(e, menuList.submenu[0]?.page);
            },
            className: `d-flex align-items-center justify-content-center rounded-0 link_menu text-decoration-none text-break w-100 px-24 py-16 shadow-none text-white ${isOpenCollapse === menuListkey.toString() || isOpenCollapse?.includes(menuListkey + "-") ? "active" : ""}`,
            "aria-controls": "wr_list_submenu",
            "aria-expanded": isOpenCollapse === menuListkey.toString() || isOpenCollapse?.includes(menuListkey + "-")
          },
          /* @__PURE__ */ React3.createElement(
            "span",
            {
              className: "icon d-inline-block align-text-bottom",
              style: {
                WebkitMaskImage: `url(${menuList.icons_color})`,
                WebkitMaskRepeat: "no-repeat",
                backgroundColor: "#fff"
              }
            }
          ),
          /* @__PURE__ */ React3.createElement("span", { className: "ms-16 text d-inline-block" }, t(menuList.text)),
          /* @__PURE__ */ React3.createElement(
            "span",
            {
              className: "icon arrow d-inline-block align-text-bottom ms-auto",
              style: {
                WebkitMaskImage: `url(${env3.PUBLIC_URL}/assets/images/arrow-right.svg)`,
                WebkitMaskRepeat: "no-repeat",
                backgroundColor: "#fff"
              }
            }
          )
        ), /* @__PURE__ */ React3.createElement(
          Collapse2,
          {
            in: isOpenCollapse === menuListkey.toString() || isOpenCollapse?.includes(menuListkey + "-")
          },
          /* @__PURE__ */ React3.createElement("ul", { id: "wr_list_submenu", className: "list-unstyled" }, menuList.submenu.map((value, menuListSubkey) => {
            return /* @__PURE__ */ React3.createElement("li", { key: menuListSubkey, className: `item_menu` }, value.link && /* @__PURE__ */ React3.createElement(
              "a",
              {
                href: "#",
                onClick: (e) => handleChangeLink(e, value.page),
                className: `d-block px-24 py-16 link_menu text-white text-decoration-none ${biStore.biListViewModel.integrationLink === value.page ? "active" : ""}`
              },
              value?.mini_text ? /* @__PURE__ */ React3.createElement("span", { className: "mini-text-wrapper" }, /* @__PURE__ */ React3.createElement("span", { className: "mini-text" }, t(value?.mini_text)), /* @__PURE__ */ React3.createElement("span", { className: "text" }, t(value.text))) : /* @__PURE__ */ React3.createElement("span", { className: "text d-inline-block" }, t(value.text))
            ));
          }))
        ))
      );
    })))
  );
});
var SbarLeftIntegration_default = SbarLeftIntegration;

// src/integration/lib/MainLayoutIntegration.jsx
var DashboardPage2 = lazy2(() => import("./Dashboard-UC22RCHP.js"));
var UTMTrackingPage2 = lazy2(() => import("./UTMTrackingPage-LHJWADAT.js"));
var EventsPage2 = lazy2(() => import("./EventsPage-22ZQNZD7.js"));
var AudiencePage2 = lazy2(() => import("./AudiencePage-IPZ5LZH2.js"));
var FlowPage2 = lazy2(() => import("./FlowDetailPage-T7CTAVUK.js"));
var RegionCountryPage2 = lazy2(() => import("./RegionCountryPage-DESIGVKM.js"));
var biViewModel = new BiViewModel();
var MainLayoutIntegration = (props) => {
  return /* @__PURE__ */ React4.createElement(BiStoreProvider, { viewModel: biViewModel }, /* @__PURE__ */ React4.createElement(
    AppProvider,
    {
      appLanguages,
      authRoutes,
      mainRoutes,
      isLogin: () => true,
      componentHeader: /* @__PURE__ */ React4.createElement(DataStream, null),
      integration: true,
      leftMenu: /* @__PURE__ */ React4.createElement(SbarLeftIntegration_default, null),
      rootId: "#biapp",
      noavatar: true
    },
    /* @__PURE__ */ React4.createElement("div", { className: "bi-intergration_layout" }, /* @__PURE__ */ React4.createElement(App, { ...props, integration: true }))
  ));
};
String.prototype.startsWith = function(str) {
  return this.indexOf(str) === 0;
};
var RenderComponent = ({ link, ...props }) => {
  switch (link) {
    case "audience-overview":
      return /* @__PURE__ */ React4.createElement(AudiencePage2, { ...props });
    case "audience-behavior":
      return /* @__PURE__ */ React4.createElement(AudiencePage2, { ...props });
    case "utm-tracking":
      return /* @__PURE__ */ React4.createElement(UTMTrackingPage2, { ...props });
    case "utm-tracking-generator":
      return /* @__PURE__ */ React4.createElement(UTMTrackingPage2, { ...props });
    case "events":
      return /* @__PURE__ */ React4.createElement(EventsPage2, { ...props });
    case "events-generator":
      return /* @__PURE__ */ React4.createElement(EventsPage2, { ...props });
    case "region-country":
      return /* @__PURE__ */ React4.createElement(RegionCountryPage2, { ...props });
    case (link.startsWith("flow") ? link : ""):
      return /* @__PURE__ */ React4.createElement(FlowPage2, { ...props });
    default:
      return /* @__PURE__ */ React4.createElement(DashboardPage2, { ...props });
  }
};
var App = observer3((props) => {
  const {
    biListViewModel: { integrationLink, activeDomain }
  } = useBiViewModel();
  return /* @__PURE__ */ React4.createElement(Suspense, { fallback: /* @__PURE__ */ React4.createElement(Spinner, null) }, /* @__PURE__ */ React4.createElement(RenderComponent, { link: integrationLink, activeDomain, ...props }));
});
var MainLayoutIntegration_default = MainLayoutIntegration;
export {
  MainLayoutIntegration_default as BiIntegration,
  BiStoreProvider,
  BiViewModel,
  DataStream,
  appLanguages,
  integrationMenu,
  integrationRoutes
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
