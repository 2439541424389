// src/pages/Permission/store.ts
import { PermissionItemModel, PermissionApiService } from "aesirx-lib";
var PermissionStore = class {
  async getList(filters) {
    try {
      const getListAPIService = new PermissionApiService();
      const respondedData = await getListAPIService.getList(filters);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getListWithoutPagination(filters) {
    try {
      const getListAPIService = new PermissionApiService();
      const respondedData = await getListAPIService.getList({ ...filters, "list[limit]": 9999 });
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getDetail(id) {
    if (!id)
      return { error: false, response: false };
    try {
      const results = true;
      if (results) {
        const getDetailInfoAPIService = new PermissionApiService();
        const respondedData = await getDetailInfoAPIService.getDetail(id);
        return { error: false, response: respondedData };
      }
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async create(createFieldData) {
    try {
      const convertedUpdateGeneralData = PermissionItemModel.__transformItemToApiOfCreation(createFieldData);
      let resultOnSave;
      const createOrganizationApiService = new PermissionApiService();
      resultOnSave = await createOrganizationApiService.create(convertedUpdateGeneralData);
      return { error: false, response: resultOnSave?.result };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async update(updateFieldData) {
    try {
      const convertedUpdateGeneralData = PermissionItemModel.__transformItemToApiOfUpdation(updateFieldData);
      let resultOnSave;
      const updateOrganizationApiService = new PermissionApiService();
      resultOnSave = await updateOrganizationApiService.update(convertedUpdateGeneralData);
      return { error: false, response: resultOnSave?.result };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async delete(arr) {
    try {
      const aesirxOrganizationApiService = new PermissionApiService();
      const respondedData = await aesirxOrganizationApiService.delete(arr);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
};

export {
  PermissionStore
};
