import {
  MemberRoleDetailViewModel_default
} from "./chunk-25ZJCZSK.js";
import {
  MemberRoleListViewModel_default
} from "./chunk-SREFS6LW.js";
import {
  __publicField
} from "./chunk-G2CEMTG6.js";

// src/pages/MemberRole/MemberRoleViewModel/MemberRoleViewModel.ts
var MemberRoleViewModel = class {
  constructor(memberRoleStore) {
    __publicField(this, "memberRoleDetailViewModel", {});
    __publicField(this, "memberRoleListViewModel", {});
    __publicField(this, "getMemberRoleDetailViewModel", () => this.memberRoleDetailViewModel);
    __publicField(this, "getMemberRoleListViewModel", () => this.memberRoleListViewModel);
    if (memberRoleStore) {
      this.memberRoleDetailViewModel = new MemberRoleDetailViewModel_default(memberRoleStore);
      this.memberRoleListViewModel = new MemberRoleListViewModel_default(memberRoleStore);
    }
  }
};
var MemberRoleViewModel_default = MemberRoleViewModel;

export {
  MemberRoleViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
