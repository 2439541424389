// src/components/ComponentVideo/index.tsx
import React from "react";
var ComponentVideo = (props) => {
  return /* @__PURE__ */ React.createElement("div", { className: "w-100 h-100", key: props.src }, /* @__PURE__ */ React.createElement("video", { className: "w-100", muted: true, loop: true, controls: true }, /* @__PURE__ */ React.createElement("source", { src: props.src, type: "video/webm" })));
};
var ComponentVideo_default = ComponentVideo;

export {
  ComponentVideo_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
